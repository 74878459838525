<app-upload-drop-zone>
    <router-outlet></router-outlet>
    <app-toast></app-toast>

    @if (importMediaElementVersion() === 1) {
    <app-gallery-import-media></app-gallery-import-media>
    } @else {
    <app-gallery-import-media-v2></app-gallery-import-media-v2>
    }
    <app-footer-popin></app-footer-popin>
</app-upload-drop-zone>
