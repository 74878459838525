import { Injectable, signal } from '@angular/core';

import { UploadDropZoneComponent } from ':modules/gallery/upload-drop-zone.component';

/**
 * This service shares UploadDropZoneComponent across the whole application
 * (these is one UploadDropZoneComponent for the whole app).
 */
@Injectable({ providedIn: 'root' })
export class UploadDropZoneService {
    public readonly uploadDropZone = signal<UploadDropZoneComponent | null>(null);

    public constructor() {}
}
