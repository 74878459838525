import { computed, inject, Injectable, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { AiCompletionDto, AiPostGenerationAdvancedSettingsDto } from '@malou-io/package-dto';
import {
    AiInteractionRelatedEntityCollection,
    AiInteractionType,
    AiTextToOptimizeType,
    ApiResultV2,
    ApplicationLanguage,
    MediaType,
} from '@malou-io/package-utils';

import { AiInteractionsService } from ':core/services/ai-interactions.service';
import { AiService } from ':core/services/ai.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { PostCaptionDisplayState } from ':shared/components/post-caption-ai-generation/post-caption-ai-generation.component';
import { Media } from ':shared/models';
import { Interaction } from ':shared/models/interaction';
import { OpenaiErrorService } from ':shared/openai-prompt/openai-errors.service';
import { AiOperation } from ':shared/openai-prompt/openai-prompt.component';

import { extractHashtags } from '../utils/utils';
import { NewSocialPostContext } from './new-social-post.context';

@Injectable({
    providedIn: 'any',
})
export class NewSocialPostAiContext {
    readonly _translate = inject(TranslateService);

    readonly aiRateLimitReached = signal(false);
    readonly isHashtagsAiButtonEnabled = signal(false);
    readonly isTextAreaLoadingAnimationEnabled = signal(false);
    readonly isGeneratingPostTextFromAI = signal(false);
    readonly PostCaptionAiGenerationDisplayState = PostCaptionDisplayState;
    readonly postCaptionAiGenerationDisplayState = signal(PostCaptionDisplayState.CLOSED);
    readonly loadingAnimationDefaultText = signal(
        this._translate.instant('social_posts.new_social_post.textarea_loading_text.ai_response_loading')
    );
    readonly loadingAnimationSlideTextList = signal(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
    readonly lastPrompt = signal('');
    readonly aiTextGenerationFormControl = new FormControl<string>('', [Validators.required]);
    readonly interactions = signal<Interaction[]>([]);
    readonly currentInteraction = signal<Interaction | null>(null);
    readonly resetBrowser = signal(false);
    readonly captionProposals = signal<string[]>([]);
    readonly selectedCaption = signal<string>('');
    readonly isPostWithAttachments = computed<boolean>(() => this._newSocialPostContext.postMedias().length > 0);
    readonly usablePhotoForImageAnalysis = computed<Media | undefined>(() =>
        this._newSocialPostContext.postMedias().find((attachment) => attachment.type === MediaType.PHOTO)
    );
    readonly isReel = computed(() => this._newSocialPostContext.isReel());

    private readonly _newSocialPostContext = inject(NewSocialPostContext);
    private readonly _aiService = inject(AiService);
    private readonly _toastService = inject(ToastService);
    private readonly _openaiErrorService = inject(OpenaiErrorService);
    private readonly _aiInteractionsService = inject(AiInteractionsService);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly isPostLambdaAdvancedSettingsEnabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-post-lambda-advanced-settings'),
        { initialValue: false }
    );
    readonly isImageAnalysisEnabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-ai-media-description'),
        { initialValue: false }
    );

    resetAiLoadingText(): void {
        this.loadingAnimationDefaultText.update(() =>
            this._translate.instant('social_posts.new_social_post.textarea_loading_text.ai_response_loading')
        );
        this.loadingAnimationSlideTextList.update(() => this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
    }

    resetAiTranslationLoadingText(): void {
        this.loadingAnimationDefaultText.update(() =>
            this._translate.instant('social_posts.new_social_post.textarea_loading_text.ai_can_translate')
        );
        this.loadingAnimationSlideTextList.update(() => this._getLoadingAnimationSlideTextList(AiOperation.TRANSLATION));
    }

    closePostCaptionAiGeneration(): void {
        this.postCaptionAiGenerationDisplayState.update(() => PostCaptionDisplayState.CLOSED);
    }

    openPostCaptionAiGeneration(): void {
        this.postCaptionAiGenerationDisplayState.update(() => PostCaptionDisplayState.OPEN);
    }

    generatePost({ shouldUseImageAnalysis }: { shouldUseImageAnalysis: boolean }): void {
        this.resetAiLoadingText();
        this.isGeneratingPostTextFromAI.set(true);
        this._generateSocialNetworkPost({ userPrompt: this.aiTextGenerationFormControl.value!, shouldUseImageAnalysis });
    }

    onInteractionChanged(interaction: Interaction): void {
        const currentFormText = this._newSocialPostContext.postForm().get('post.text')?.value.trim();
        this.updateInteractionsList({
            id: uuidv4(),
            text: currentFormText,
            isAiInteraction: false,
            originalInteractionId: this.currentInteraction()?.isAiInteraction
                ? this.currentInteraction()?.id
                : this.currentInteraction()?.originalInteractionId,
        });
        const { textWithoutHashtag } = extractHashtags(interaction.text);
        this._newSocialPostContext.postForm().get('post.text')?.setValue(textWithoutHashtag);
        this.currentInteraction.update(() => interaction);
    }

    updateInteractionsList(interaction: Interaction): void {
        const interactions = this.interactions();
        const update = Interaction.getUpdatedInteractions(interactions, interaction);
        if (!update) {
            return;
        }
        this.interactions.set(update);
    }

    initInteractions(): void {
        this._aiInteractionsService
            .getAiInteractions(this._newSocialPostContext.postInitialData().postId, AiInteractionRelatedEntityCollection.POSTS)
            .subscribe({
                next: (aiInteractions) => {
                    const interactions: Interaction[] = aiInteractions
                        .filter(
                            (interaction) =>
                                interaction.isSuccessful() &&
                                interaction.type !== AiInteractionType.CHOOSE_POST_HASHTAGS &&
                                interaction.type !== AiInteractionType.TRANSLATE &&
                                interaction.type !== AiInteractionType.POST_TRANSLATION
                        )
                        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
                        .map((interaction) => ({
                            id: uuidv4(),
                            text: extractHashtags(interaction.completionText).textWithoutHashtag,
                            isAiInteraction: true,
                        }));

                    this.interactions.set(interactions);
                },
            });
    }

    optimizePostCaption(): void {
        const textToOptimize = this._newSocialPostContext.postForm().get('post.text')?.value;
        this._newSocialPostContext.postForm().get('post.text')?.setValue('');
        this.resetAiLoadingText();
        this.isTextAreaLoadingAnimationEnabled.set(true);
        this._aiService
            .optimizeText({
                relatedEntityId: this._newSocialPostContext.post()._id,
                textToOptimize: textToOptimize,
                textToOptimizeType: AiTextToOptimizeType.SOCIAL_NETWORK_POST,
                restaurantId: this._newSocialPostContext.currentRestaurant()._id,
                lang: LocalStorage.getLang(),
            })
            .subscribe({
                next: (res) => {
                    this.isTextAreaLoadingAnimationEnabled.update(() => false);
                    const { data } = res;
                    this._aiService.handleAiInteraction();
                    const { textWithoutHashtag } = extractHashtags(data.optimizedText);
                    this.updateInteractionsList({ text: textWithoutHashtag, isAiInteraction: true, id: uuidv4() });
                    this._newSocialPostContext.postForm().get('post.text')?.setValue(textWithoutHashtag);
                },
                error: (err) => {
                    console.warn('err >', err);
                    this.isTextAreaLoadingAnimationEnabled.update(() => false);
                    this._newSocialPostContext.postForm().get('post.text')?.setValue(textToOptimize);
                },
            });
    }

    onCaptionProposalSelection(caption: string): void {
        this.selectedCaption.set(caption);

        let currentLegend = this._newSocialPostContext.postForm().get('post.text')?.value;
        if (!currentLegend) {
            this._newSocialPostContext.postForm().get('post.text')?.setValue(caption);
            return;
        }

        const existingCaptionInText = this.captionProposals().find((capt) => currentLegend.includes(capt));
        if (!!existingCaptionInText) {
            currentLegend = currentLegend.replace(`\n${existingCaptionInText}`, '').replace(existingCaptionInText, '');
        }
        const updatedLegend = currentLegend ? currentLegend + '\n' + caption : caption;
        this._newSocialPostContext.postForm().get('post.text')?.setValue(updatedLegend);

        const textarea = document.getElementById('socialPostText') as HTMLTextAreaElement;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }

    translateText(lang: ApplicationLanguage | string): void {
        this.resetAiTranslationLoadingText();
        this.isTextAreaLoadingAnimationEnabled.set(true);
        this.isGeneratingPostTextFromAI.set(true);
        this._translateTextWithAi(lang);
    }

    private _translateTextWithAi(lang: string): void {
        const currentText = this._newSocialPostContext.postForm().get('post.text')?.value;
        this._newSocialPostContext.postForm().get('post.text')?.setValue('');
        const textToTranslate = this._newSocialPostContext.selectedText().length ? this._newSocialPostContext.selectedText() : currentText;
        this._aiService
            .translateText({
                relatedEntityId: this._newSocialPostContext.post()._id,
                relatedEntityCollection: AiInteractionRelatedEntityCollection.POSTS,
                type: AiInteractionType.POST_TRANSLATION,
                restaurantId: this._newSocialPostContext.currentRestaurant()._id,
                text: textToTranslate,
                lang,
            })
            .subscribe({
                next: (res) => {
                    this.isTextAreaLoadingAnimationEnabled.update(() => false);
                    const { data: translatedSelection } = res;
                    const translatedText = currentText.replace(textToTranslate, translatedSelection);
                    this.isGeneratingPostTextFromAI.update(() => false);
                    this._aiService.handleAiInteraction();
                    this.updateInteractionsList({ text: translatedText, isAiInteraction: true, id: uuidv4() });
                    this._newSocialPostContext.postForm().get('post.text')?.setValue(translatedText);
                },
                error: (err) => {
                    this.isTextAreaLoadingAnimationEnabled.update(() => false);
                    this.isGeneratingPostTextFromAI.update(() => false);
                    this._newSocialPostContext.postForm().get('post.text')?.setValue(currentText);
                    console.warn('err >', err);
                    if (err.error?.status === 429) {
                        this._openaiErrorService.openRateLimitErrorDialog();
                    } else {
                        this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                    }
                },
            });
    }

    private _getLoadingAnimationSlideTextList(operation: AiOperation): string[] {
        switch (operation) {
            case AiOperation.COMPLETION:
                return [
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.post_subject'),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.usual_tone'),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.emojis'),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.results_criteria'),
                ];
            case AiOperation.TRANSLATION:
                return [
                    this._translate.instant('common.langs.en').toLowerCase(),
                    this._translate.instant('common.langs.es').toLowerCase(),
                    this._translate.instant('common.langs.it').toLowerCase(),
                    this._translate.instant('social_posts.new_social_post.textarea_loading_text.lang_of_your_choice'),
                ];
            default:
                return [];
        }
    }

    private _generateSocialNetworkPost({
        userPrompt,
        shouldUseImageAnalysis,
    }: {
        userPrompt: string;
        shouldUseImageAnalysis: boolean;
    }): void {
        this.captionProposals.set([]);
        const currentText = this._newSocialPostContext.postForm().get('post.text')?.value;
        if (!this.isPostLambdaAdvancedSettingsEnabled()) {
            this._newSocialPostContext.postForm().get('post.text')?.setValue('');
        }
        this.lastPrompt.update(() => userPrompt);
        const generatePost$: Observable<ApiResultV2> = this.isPostLambdaAdvancedSettingsEnabled()
            ? this._aiService.generateSocialNetworkPostAdvancedSettings({
                  postId: this._newSocialPostContext.post()._id,
                  description: userPrompt,
                  restaurantId: this._newSocialPostContext.currentRestaurant()._id,
                  lang: LocalStorage.getLang(),
                  shouldUseImageAnalysis,
              })
            : this._aiService.generateSocialNetworkPost({
                  postId: this._newSocialPostContext.post()._id,
                  description: userPrompt,
                  restaurantId: this._newSocialPostContext.currentRestaurant()._id,
                  lang: LocalStorage.getLang(),
              });
        generatePost$.subscribe({
            next: (res: { data: AiCompletionDto | AiPostGenerationAdvancedSettingsDto }) => {
                const { data: generatedPostText } = res;
                this.captionProposals.set(
                    !this.isPostLambdaAdvancedSettingsEnabled() && typeof generatedPostText === 'string'
                        ? [generatedPostText]
                        : ((generatedPostText as AiPostGenerationAdvancedSettingsDto) ?? [''])
                );
                const validGeneratedPostText = this.captionProposals()[0];
                const { textWithoutHashtag } = extractHashtags(validGeneratedPostText);
                this.isGeneratingPostTextFromAI.update(() => false);
                this._aiService.handleAiInteraction();
                this.updateInteractionsList({ text: textWithoutHashtag, isAiInteraction: true, id: uuidv4() });
                this._newSocialPostContext
                    .postForm()
                    .get('post.text')
                    ?.setValue(this.isPostLambdaAdvancedSettingsEnabled() ? currentText || '' : textWithoutHashtag);
            },
            error: (err) => {
                this.isGeneratingPostTextFromAI.update(() => false);
                this._newSocialPostContext
                    .postForm()
                    .get('post.text')
                    ?.setValue(currentText || '');
                if (err.error?.status === 429) {
                    this._openaiErrorService.openRateLimitErrorDialog();
                } else {
                    this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                }
                console.warn('err >', err);
            },
        });
    }
}
