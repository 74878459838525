<div class="flex h-full flex-col">
    <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>

    <div class="flex h-full">
        <div class="flex grow flex-col">
            <div class="flex h-full grow">
                <app-duplicate-and-select-platforms class="w-[270px]"></app-duplicate-and-select-platforms>

                <app-social-post-content-form class="max-content-form-height grow"></app-social-post-content-form>
            </div>

            <app-upsert-social-post-modal-footer class="w-full" (cancel)="onCancel()"></app-upsert-social-post-modal-footer>
        </div>

        <app-previews-feed-notes class="w-[400px]"></app-previews-feed-notes>
    </div>
</div>

<ng-template #headerTemplate>
    <div class="flex items-center justify-between border-b border-malou-color-border-primary px-6 py-5">
        <div class="malou-text-18--semibold text-malou-color-text-1">
            {{ 'social_posts.upsert_social_post_modal.title.create' | translate }}
        </div>

        <button class="malou-btn-icon !rounded-full !bg-malou-color-background-dark" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
</ng-template>
