import { AsyncPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    OnDestroy,
    OnInit,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { UploadDropZoneService } from ':modules/gallery/upload-drop-zone.service';
import { CircleProgressComponent } from ':shared/components-v3/circle-progress/circle-progress.component';

import * as GalleryImportMediaActions from './gallery-import-media.actions';
import { selectIsGalleryOpen } from './gallery-import-media.reducer';
import { GalleryImportMediaService } from './gallery-import-media.service';

enum UploadStatus {
    SCHEDULED = 'SCHEDULED',
    UPLOADING = 'UPLOADING',
    UPLOADED = 'UPLOADED',
    FAILED = 'FAILED',
}

enum UploadErrorCode {
    NETWORK_ERROR = 'NETWORK_ERROR',
    INVALID_FILE = 'INVALID_FILE',
}

interface Upload {
    file: File;

    /** a number between 0 and 1 */
    progress: number | null;

    errorCode: UploadErrorCode | null;

    status: UploadStatus;
}

/**
 * This component is supposed to be instanciated once for the whole application.
 *
 * See https://airtable.com/appIqBldyX7wZlWnp/tblbOxMTpexQyxSTV/viwVSdtBlz857nQiA/recdHmIJwdJGtRTaf
 */
@Component({
    selector: 'app-gallery-import-media-v2',
    templateUrl: './gallery-import-media-v2.component.html',
    standalone: true,
    imports: [TranslateModule, CircleProgressComponent, MalouSpinnerComponent, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryImportMediaV2Component implements OnDestroy, OnInit {
    readonly uploads = signal<Upload[]>([]);

    readonly totalFiles = computed(() => this.uploads().length);

    /** between 0 and 100 */
    readonly uploadProgress = computed(() => {
        const uploads = this.uploads().filter((u) => u.status !== UploadStatus.FAILED);
        const sum = uploads.reduce((sum_, u) => sum_ + (u.progress ?? 0), 0);
        return (sum / uploads.length) * 100;
    });

    readonly uploadProgress$ = toObservable(this.uploadProgress);

    private _fileInput = viewChild.required<ElementRef<HTMLInputElement>>('fileInput');

    private readonly _errors: WritableSignal<string[]> = signal([]);

    private readonly _dragAndDropEnabled = this._store.selectSignal(selectIsGalleryOpen);

    constructor(
        private readonly _galleryImportMediaService: GalleryImportMediaService,
        private readonly _store: Store,
        private readonly _uploadDropZoneService: UploadDropZoneService,
        public readonly screenSizeService: ScreenSizeService
    ) {
        effect(
            () =>
                this._store.dispatch({
                    type: GalleryImportMediaActions.setFilesErrors.type,
                    filesErrors: this._errors(),
                }),
            { allowSignalWrites: true }
        );
    }

    ngOnInit(): void {
        this._galleryImportMediaService.elementV2.set(this);
        this._dropZone().addEventListener('drop', this._onDrop);
        this._dropZone().addEventListener('dragover', this._onDragOver);
    }

    ngOnDestroy(): void {
        this._galleryImportMediaService.elementV2.set(null);
        this._dropZone().removeEventListener('drop', this._onDrop);
        this._dropZone().removeEventListener('dragover', this._onDragOver);
    }

    /** Can be called from other components */
    public openFilePicker(): void {
        this._fileInput().nativeElement.value = '';
        this._fileInput().nativeElement.click();
    }

    onFileInputChange(_event: Event) {
        const files: File[] = Array.from(this._fileInput().nativeElement.files ?? []);
        for (const file of files) {
            this._scheduleUpload(file);
        }
    }

    private _onDragOver = (event: DragEvent) => {
        if (this._dragAndDropEnabled()) {
            event.preventDefault();
        }
    };

    private _onDrop = (event: DragEvent) => {
        if (!this._dragAndDropEnabled()) {
            return;
        }

        event.preventDefault();
        const droppedFiles: FileList | null = event.dataTransfer?.files as FileList | null;
        if (droppedFiles) {
            for (const file of Array.from(droppedFiles)) {
                this._scheduleUpload(file);
            }
        }
    };

    /**
     * Appends a file to the list of file to upload. This does not mean that the file
     * will be uploaded immediately.
     */
    private _scheduleUpload(file: File): void {
        const upload = { file, progress: 0, errorCode: null, status: UploadStatus.SCHEDULED };
        this.uploads.update((uploads) => [...uploads, upload]);
        this._maybeStartUpload();
    }

    /** Starts a scheduled upload job if possible. Does nothing otherwise. */
    private _maybeStartUpload() {
        setTimeout(() => this._maybeStartUploadImpl());
    }

    /** Should not be called directly. Call it via _maybeStartUpload(). */
    private _maybeStartUploadImpl(): void {
        this.uploads.update((uploads: Upload[]): Upload[] => {
            const maxParallelUploads = 2;
            if (uploads.filter((u) => u.status === UploadStatus.UPLOADING).length >= maxParallelUploads) {
                // already enough uploads in progress
                return uploads;
            }

            const upload = uploads.find((u) => u.status === UploadStatus.SCHEDULED);
            if (!upload) {
                // nothing to start

                if (!uploads.some((u) => u.status === UploadStatus.UPLOADING)) {
                    // all the uploads are finished: clear the list
                    // TODO: translate error codes
                    this._errors.set(
                        uploads
                            .filter((u) => u.errorCode !== null)
                            .map((u) => 'could not upload ' + JSON.stringify(u.file.name) + ': ' + u.errorCode)
                    );
                    return [];
                }
                return uploads;
            }

            // TODO: This is a fake upload, just to test the frontend
            const totalMs = Math.random() * 3 * 999;
            const startTimestamp = +new Date();
            const progressTimer = setInterval(() => {
                this._updateProgress(upload.file, (+new Date() - startTimestamp) / totalMs);
            }, totalMs / 5);

            setTimeout(() => {
                clearInterval(progressTimer);

                if (Math.random() > 0.6) {
                    this._onUploadEnd(upload.file, null);
                } else {
                    this._onUploadEnd(upload.file, UploadErrorCode.NETWORK_ERROR);
                }
            }, totalMs);

            return [...uploads.filter((u) => u !== upload), { ...upload, status: UploadStatus.UPLOADING }];
        });
    }

    private _onUploadEnd(file: File, errorCode: UploadErrorCode | null) {
        this.uploads.update((uploads: Upload[]): Upload[] => {
            const upload = uploads.find((u) => u.file === file);
            if (!upload) {
                throw new Error();
            }

            this._maybeStartUpload();

            return [
                ...uploads.filter((u) => u !== upload),
                {
                    ...upload,
                    errorCode,
                    status: errorCode === null ? UploadStatus.UPLOADED : UploadStatus.FAILED,
                },
            ];
        });
    }

    /** progress is a number between 0 and 1 */
    private _updateProgress(file: File, progress: number) {
        this.uploads.update((uploads) =>
            uploads.map((upload) => {
                if (upload.file === file) {
                    return { ...upload, progress };
                }
                return upload;
            })
        );
    }

    private _dropZone(): HTMLElement {
        const uploadDropZone = this._uploadDropZoneService.uploadDropZone();
        if (!uploadDropZone) {
            throw new Error('no uploadDropZone'); // should never happen
        }
        return uploadDropZone.nativeElement();
    }
}
