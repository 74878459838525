import { computed, inject, Injectable, Signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

import { Day, isSameDay } from '@malou-io/package-utils';

import {
    BusinessHoursState,
    DEFAULT_HOURS_PERIOD,
    OtherHoursState,
    OtherServiceSchedules,
    ScheduleWithIsClosed,
    SpecialHoursState,
} from ':modules/informations/hours-modal/hours-modal.interface';
import { HoursType, MyDate, OtherPeriod, Period, SpecialDatePeriod, SpecialTimePeriod, TIME_24, TimePeriod } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Injectable({
    providedIn: 'root',
})
export class HoursModalService {
    private readonly _translateService = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);

    /**
     * Business Hours methods
     */

    getRegularHoursFromBusinessHoursState(schedules: ScheduleWithIsClosed[]): TimePeriod[] {
        const regularHours: TimePeriod[] = [];
        const DAYS = Object.values(Day);

        schedules.forEach((schedule) => {
            if (schedule.isClosed) {
                regularHours.push(
                    ...schedule.selectedDays.map(
                        (day) => new TimePeriod({ openDay: day, closeDay: day, isClosed: true, openTime: null, closeTime: null })
                    )
                );
            } else {
                const mergedPeriods = this._mergeIntertwinedPeriods(schedule.periods);
                mergedPeriods.forEach((period) => {
                    regularHours.push(
                        ...schedule.selectedDays.map((day) => {
                            if (period.isFullDay()) {
                                period.cleanFullDay();
                            }
                            const dayIndex = DAYS.indexOf(day);
                            const closeDay = period.openTime! < period.closeTime! ? day : DAYS[(dayIndex + 1) % DAYS.length];
                            return new TimePeriod({
                                openDay: day,
                                closeDay,
                                isClosed: false,
                                openTime: period.openTime,
                                closeTime: period.closeTime,
                            });
                        })
                    );
                });
            }
        });

        return regularHours;
    }

    getAvailableDaysForBusinessHours(scheduleDays: Day[], schedules: ScheduleWithIsClosed[]): Day[] {
        const allSelectedDays = schedules.map((s) => s.selectedDays).flat();
        return Object.values(Day).filter((d) => !allSelectedDays.includes(d) || scheduleDays.includes(d));
    }

    mapRegularHoursToBusinessHoursState(regularHours: TimePeriod[]): BusinessHoursState {
        // Create schedules from regular hours grouped by opening days with the same periods
        const schedules: ScheduleWithIsClosed[] = [];

        const openDaysRegularHours = regularHours.filter((period) => !period.isClosed);
        const regularHoursByDay = openDaysRegularHours.reduce<Record<Day, TimePeriod[]>>(
            (acc, period) => {
                const day = period.openDay;
                acc[day] = [...(acc[day] ?? []), period];
                return acc;
            },
            {} as Record<Day, TimePeriod[]>
        );

        for (const day of Object.keys(regularHoursByDay)) {
            const periods: TimePeriod[] = regularHoursByDay[day];
            const existingSchedule = schedules.find((schedule) => {
                const existingPeriods = schedule.periods;
                return (
                    existingPeriods.length === periods.length &&
                    existingPeriods.every((existingPeriod) =>
                        periods.some(
                            (period) => period.openTime === existingPeriod.openTime && period.closeTime === existingPeriod.closeTime
                        )
                    )
                );
            });

            if (existingSchedule) {
                existingSchedule.selectedDays.push(day as Day);
            } else {
                const cleanPeriods = periods.map((period) => new Period(period));
                cleanPeriods.forEach((period) => {
                    if (period.openTime === '00:00' && period.closeTime === '24:00') {
                        period.openTime = TIME_24;
                        period.closeTime = null;
                    }
                });
                schedules.push({
                    selectedDays: [day as Day],
                    periods: cleanPeriods,
                    isClosed: false,
                    availableDays: Object.values(Day),
                });
            }
        }

        // Add closed days schedule if any
        const closedDays = Array.from(new Set(regularHours.filter((period) => period.isClosed).map((period) => period.openDay)));
        if (closedDays.length > 0) {
            const closedDaysSchedule = {
                selectedDays: closedDays,
                periods: [cloneDeep(DEFAULT_HOURS_PERIOD)],
                isClosed: true,
                availableDays: Object.values(Day),
            };
            schedules.push(closedDaysSchedule);
        }

        // Sort schedules by the first day of the week, and periods by open time
        schedules.sort((scheduleA, scheduleB) => {
            const minDayScheduleA = Math.min(...scheduleA.selectedDays.map((day) => Object.values(Day).indexOf(day)));
            const minDayScheduleB = Math.min(...scheduleB.selectedDays.map((day) => Object.values(Day).indexOf(day)));
            return minDayScheduleA - minDayScheduleB;
        });
        schedules.forEach((schedule) => {
            schedule.periods.sort((periodA, periodB) => (periodA.openTime ?? '').localeCompare(periodB.openTime ?? ''));
        });

        return { schedules, hasBeenTouched: false };
    }

    getBusinessHoursErrors(schedules: ScheduleWithIsClosed[]): string[] {
        const errors: string[] = [];

        const missingDays = this.getAvailableDaysForBusinessHours([], schedules);
        if (missingDays.length > 0) {
            const missingDaysString = missingDays.map((day) => this._enumTranslatePipe.transform(day, 'days')).join(', ');
            errors.push(this._translateService.instant('information.business_hours.missing_days', { days: missingDaysString }));
        }

        const allPeriodsAreValid = schedules.every((schedule) => {
            if (schedule.isClosed) {
                return true;
            }
            return schedule.periods.every(
                (period) => period.openTime !== null && (period.openTime === TIME_24 || period.closeTime !== null)
            );
        });
        if (!allPeriodsAreValid) {
            errors.push(this._translateService.instant('information.hours.invalid_form_regular'));
        }

        return errors;
    }

    /**
     * Other Hours methods
     */

    getOtherHoursFromOtherHoursState(services: OtherServiceSchedules[]): OtherPeriod[] {
        return services.map((service) => {
            const periods = service.schedules
                .map((schedule) =>
                    schedule.selectedDays
                        .map((selectedDay) => {
                            const mergedPeriods = this._mergeIntertwinedPeriods(schedule.periods);
                            return mergedPeriods.map((period) => {
                                if (period.isFullDay()) {
                                    period.cleanFullDay();
                                }
                                return new TimePeriod({
                                    openDay: selectedDay,
                                    closeDay:
                                        period.openTime! < period.closeTime!
                                            ? selectedDay
                                            : Day[Object.values(Day).indexOf(selectedDay) + 1],
                                    openTime: period.openTime,
                                    closeTime: period.closeTime,
                                    isClosed: false,
                                });
                            });
                        })
                        .flat()
                )
                .flat();

            return {
                hoursType: service.type,
                periods,
            };
        });
    }

    mapOtherHoursToOtherHoursState(otherHours: OtherPeriod[], availableHoursTypes: HoursType[]): OtherHoursState {
        const otherHoursWithOnlyOpenPeriods = otherHours
            .map((otherHour) => {
                const periods = otherHour.periods.filter((period) => !period.isClosed);
                return { ...otherHour, periods };
            })
            .filter((otherHour) => otherHour.periods.length > 0);

        const services = otherHoursWithOnlyOpenPeriods.map((otherHour) => {
            const { schedules } = this.mapRegularHoursToBusinessHoursState(otherHour.periods);
            const schedulesWithoutIsClosed = schedules.map((schedule) => {
                const { isClosed: _, ...rest } = schedule;
                return rest;
            });

            return {
                type: otherHour.hoursType,
                schedules: schedulesWithoutIsClosed,
            };
        });

        return {
            services,
            availableHoursTypes,
            hasBeenTouched: false,
        };
    }

    getAvailableDaysForOtherHoursService(
        serviceIndex: number,
        scheduleDays: Day[],
        services: Signal<OtherServiceSchedules[]>
    ): Signal<Day[]> {
        return computed(() => {
            const allSelectedDays = services()[serviceIndex].schedules.flatMap((s) => s.selectedDays);
            return Object.values(Day).filter((d) => !allSelectedDays.includes(d) || scheduleDays.includes(d));
        });
    }

    getOtherHoursErrors(services: OtherServiceSchedules[]): string[] {
        const errors: string[] = [];

        const allPeriodsAreValid = services.every((service) =>
            service.schedules.every((schedule) =>
                schedule.periods.every((period) => period.openTime !== null && (period.openTime === TIME_24 || period.closeTime !== null))
            )
        );
        if (!allPeriodsAreValid) {
            errors.push(this._translateService.instant('information.hours.invalid_form_other'));
        }

        return errors;
    }

    /**
     * Special Hours methods
     */

    getSpecialHoursFromSpecialHoursState(specialDatePeriods: SpecialDatePeriod[]): SpecialTimePeriod[] {
        const specialTimePeriods = specialDatePeriods.flatMap((specialPeriod) => {
            const periodDaysList = specialPeriod.getDaysList();
            return periodDaysList.flatMap((date) =>
                specialPeriod.isClosed
                    ? [
                          new SpecialTimePeriod({
                              startDate: date,
                              endDate: date,
                              isClosed: true,
                              name: specialPeriod.name,
                              openTime: null,
                              closeTime: null,
                              isFromCalendarEvent: specialPeriod.isFromCalendarEvent,
                          }),
                      ]
                    : this._mergeIntertwinedPeriods(specialPeriod.periods).map(
                          (period) =>
                              new SpecialTimePeriod({
                                  startDate: date,
                                  endDate:
                                      !period.closeTime || !period.openTime || period.openTime < period.closeTime
                                          ? date
                                          : date.getNextDay(),
                                  isClosed: specialPeriod.isClosed,
                                  openTime: period.openTime,
                                  closeTime: period.closeTime,
                                  name: specialPeriod.name,
                                  isFromCalendarEvent: specialPeriod.isFromCalendarEvent,
                              })
                      )
            );
        });
        specialTimePeriods.forEach((specialPeriod) => {
            if (specialPeriod.isFullDay()) {
                specialPeriod.cleanFullDay();
            }
        });
        return specialTimePeriods;
    }

    mapSpecialHoursToSpecialHoursState(specialHours: SpecialTimePeriod[], prefilledStartDate?: Date): SpecialHoursState {
        const specialPeriods = this._getSpecialDatePeriodsfromSpecialTimePeriods(specialHours);
        return { specialPeriods, calendarEvents: [], prefilledStartDate, hasBeenTouched: false };
    }

    getSpecialHoursErrors(allSpecialDatePeriods: SpecialDatePeriod[]): string[] {
        const errors: string[] = [];
        const specialDatePeriods = allSpecialDatePeriods.filter((specialPeriod) => specialPeriod.isNotPastYet());

        const allDatesAreValid = specialDatePeriods.every((specialPeriod) => specialPeriod.areDatesValid());
        if (!allDatesAreValid) {
            errors.push(this._translateService.instant('information.hours.invalid_dates_form_special'));
        }

        const allPeriodsAreValid = specialDatePeriods.every((specialPeriod) => specialPeriod.arePeriodsValid());
        if (!allPeriodsAreValid) {
            errors.push(this._translateService.instant('information.hours.invalid_period_form_special'));
        }

        const noDatePeriodOverlap = specialDatePeriods.every((specialPeriod, i) => {
            const currentPeriodStart = specialPeriod.startDate?.getDate();
            const currentPeriodEnd = specialPeriod.endDate?.getDate();
            if (!currentPeriodStart || !currentPeriodEnd) {
                return true;
            }
            return specialDatePeriods.every((otherSpecialPeriod, j) => {
                if (i === j) {
                    return true;
                }
                const otherPeriodStart = otherSpecialPeriod.startDate?.getDate();
                const otherPeriodEnd = otherSpecialPeriod.endDate?.getDate();
                return !otherPeriodStart || !otherPeriodEnd || currentPeriodEnd < otherPeriodStart || currentPeriodStart > otherPeriodEnd;
            });
        });
        if (!noDatePeriodOverlap) {
            errors.push(this._translateService.instant('information.hours.invalid_overlap_form_special'));
        }

        return errors;
    }

    noSpecialHoursForThisDate(date: Date, specialDatePeriods: SpecialDatePeriod[]): boolean {
        return !specialDatePeriods.some((specialPeriod) => {
            const specialPeriodStart = specialPeriod.startDate?.getDate();
            return specialPeriodStart && isSameDay(specialPeriodStart, date);
        });
    }

    sortSpecialDatePeriods = (a: SpecialDatePeriod, b: SpecialDatePeriod): number =>
        (b.startDate?.getDate().getTime() ?? Number.MAX_SAFE_INTEGER) - (a.startDate?.getDate().getTime() ?? Number.MAX_SAFE_INTEGER);

    private _getSpecialDatePeriodsfromSpecialTimePeriods(specialTimePeriods: SpecialTimePeriod[]): SpecialDatePeriod[] {
        const specialTimePeriodsGroupedByName = specialTimePeriods.reduce(
            (acc, s) => {
                const name = s.name ?? '';
                if (!acc[name]) {
                    acc[name] = [];
                }
                acc[name].push(s);
                return acc;
            },
            {} as Record<string, SpecialTimePeriod[]>
        );

        const specialDatePeriods = Object.values(specialTimePeriodsGroupedByName).flatMap((s) => {
            // Merge consecutive special time periods
            const mergedSpecialTimePeriods = this._mergeConsecutiveSpecialTimePeriods(s);

            // Merge special time periods with same startDate and endDate into a special date period
            const mergedSpecialDatePeriods = this._mergeSpecialTimePeriodsWithSameDateIntoSpecialDatePeriods(mergedSpecialTimePeriods);

            return mergedSpecialDatePeriods;
        });

        // Init full day periods
        specialDatePeriods.forEach((specialDatePeriod) => {
            specialDatePeriod.periods.forEach((period) => {
                if (period.openTime === '00:00' && period.closeTime === '24:00') {
                    period.openTime = TIME_24;
                    period.closeTime = null;
                }
            });
        });

        // Sort special date periods by start date
        specialDatePeriods.sort(this.sortSpecialDatePeriods);

        // Sort each period by open time
        specialDatePeriods.forEach((specialDatePeriod) => {
            specialDatePeriod.periods.sort((a, b) => {
                if (a.openTime === TIME_24) {
                    return -1;
                }
                if (b.openTime === TIME_24) {
                    return 1;
                }
                return (a.openTime ?? '').localeCompare(b.openTime ?? '');
            });
        });

        return specialDatePeriods;
    }

    private _mergeConsecutiveSpecialTimePeriods(specialTimePeriods: SpecialTimePeriod[]): SpecialTimePeriod[] {
        const mergedSpecialTimePeriods: SpecialTimePeriod[] = [];
        if (specialTimePeriods.length === 0) {
            return mergedSpecialTimePeriods;
        }

        // Sort special time periods by start date
        specialTimePeriods.sort((a, b) => a.startDate.getDate().getTime() - b.startDate.getDate().getTime());

        specialTimePeriods.forEach((specialTimePeriod) => {
            // Match special time period with same isClosed status and same open/close times, that are consecutive.
            const existingSpecialTimePeriodIndex = mergedSpecialTimePeriods.findIndex((s) => {
                if (!s.startDate || !specialTimePeriod.startDate) {
                    return false;
                }
                const nextDay = s.startDate.getNextDay();
                return (
                    nextDay.equals(specialTimePeriod.startDate) &&
                    s.isClosed === specialTimePeriod.isClosed &&
                    (s.isClosed || (s.openTime === specialTimePeriod.openTime && s.closeTime === specialTimePeriod.closeTime))
                );
            });

            if (existingSpecialTimePeriodIndex > -1) {
                const existingSpecialTimePeriod = mergedSpecialTimePeriods[existingSpecialTimePeriodIndex];
                mergedSpecialTimePeriods[existingSpecialTimePeriodIndex] = new SpecialTimePeriod({
                    ...existingSpecialTimePeriod,
                    endDate: new MyDate(specialTimePeriod.startDate), // startDate is on purpose here to handle the case when the closeTime is after midnight
                });
            } else {
                mergedSpecialTimePeriods.push(specialTimePeriod);
            }
        });

        return mergedSpecialTimePeriods;
    }

    private _mergeSpecialTimePeriodsWithSameDateIntoSpecialDatePeriods(specialTimePeriods: SpecialTimePeriod[]): SpecialDatePeriod[] {
        return specialTimePeriods.reduce((acc, currentSpecialTimePeriod) => {
            const specialDatePeriodWithSameDates = acc.find((specialDatePeriod) =>
                specialDatePeriod.hasSameDates(currentSpecialTimePeriod)
            );
            if (!specialDatePeriodWithSameDates) {
                acc.push(
                    new SpecialDatePeriod({
                        name: currentSpecialTimePeriod.name,
                        startDate: currentSpecialTimePeriod.startDate,
                        endDate: currentSpecialTimePeriod.endDate,
                        periods: [new Period(currentSpecialTimePeriod)],
                        isClosed: currentSpecialTimePeriod.isClosed,
                        isFromCalendarEvent: currentSpecialTimePeriod.isFromCalendarEvent,
                    })
                );
            } else {
                specialDatePeriodWithSameDates.periods.push(new Period(currentSpecialTimePeriod));
                specialDatePeriodWithSameDates.isClosed = specialDatePeriodWithSameDates.isClosed || currentSpecialTimePeriod.isClosed;
                specialDatePeriodWithSameDates.isFromCalendarEvent =
                    specialDatePeriodWithSameDates.isFromCalendarEvent || currentSpecialTimePeriod.isFromCalendarEvent;
            }

            return acc;
        }, [] as SpecialDatePeriod[]);
    }

    /**
     * Common methods
     */

    private _mergeIntertwinedPeriods(periods: Period[]): Period[] {
        // If a period is full day, then the merge result is a single full day period
        const fullDayPeriod = periods.find((period) => period.isFullDay());
        if (fullDayPeriod) {
            return [fullDayPeriod];
        }

        // Sort periods by open time, then close time
        const sortedPeriods = periods.sort(
            ({ openTime: openTimeA, closeTime: closeTimeA }, { openTime: openTimeB, closeTime: closeTimeB }) => {
                if (!openTimeA) {
                    return openTimeB ? -1 : 0;
                }
                if (!openTimeB) {
                    return 1;
                }
                if (openTimeA < openTimeB) {
                    return -1;
                }
                if (openTimeA > openTimeB) {
                    return 1;
                }
                if (!closeTimeA) {
                    return closeTimeB ? -1 : 0;
                }
                if (!closeTimeB) {
                    return 1;
                }
                return closeTimeA < closeTimeB ? -1 : closeTimeA > closeTimeB ? 1 : 0;
            }
        );

        // Merge periods that are intertwined
        const mergedPeriods = sortedPeriods.reduce<Period[]>((acc, currentPeriod) => {
            const lastMergedPeriod = acc[acc.length - 1];
            if (
                lastMergedPeriod &&
                lastMergedPeriod.closeTime &&
                currentPeriod.openTime &&
                lastMergedPeriod.closeTime >= currentPeriod.openTime
            ) {
                const maxCloseTime =
                    !currentPeriod.closeTime || lastMergedPeriod.closeTime > currentPeriod.closeTime
                        ? lastMergedPeriod.closeTime
                        : currentPeriod.closeTime;
                lastMergedPeriod.closeTime = maxCloseTime;
            } else {
                acc.push(currentPeriod);
            }
            return acc;
        }, []);

        return mergedPeriods;
    }
}
