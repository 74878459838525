import { effect, Injectable, signal } from '@angular/core';

import { GalleryImportMediaV2Component } from './gallery-import-media-v2.component';
import { GalleryImportMediaV1Component } from './gallery-import-media.component';

/**
 * This service shares GalleryImportMediaComponent across the whole application
 * (these is one GalleryImportMediaComponent for the whole app).
 *
 * See https://airtable.com/appIqBldyX7wZlWnp/tblbOxMTpexQyxSTV/viwVSdtBlz857nQiA/recdHmIJwdJGtRTaf
 */
@Injectable({ providedIn: 'root' })
export class GalleryImportMediaService {
    public readonly version = signal<1 | 2>(1);
    public readonly elementV1 = signal<GalleryImportMediaV1Component | null>(null);
    public readonly elementV2 = signal<GalleryImportMediaV2Component | null>(null);

    public constructor() {
        effect(() => {
            console.log('GalleryImportMediaService', {
                version: this.version(),
                elementV1: this.elementV1(),
                elementV2: this.elementV2(),
            });
        });
    }
}
