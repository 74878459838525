import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map, Observable } from 'rxjs';

import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { ReviewsContext } from ':modules/reviews/reviews.context';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { SelectRestaurantsComponent } from ':shared/components/select-restaurants/select-restaurants.component';
import { MAX_SELECTABLE_RESTAURANTS } from ':shared/constants/filters';
import { Restaurant } from ':shared/models';

@Component({
    selector: 'app-reviews-header-restaurants',
    templateUrl: './reviews-header-restaurants.component.html',
    styleUrls: ['./reviews-header-restaurants.component.scss'],
    standalone: true,
    imports: [AsyncPipe, SelectRestaurantsComponent, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsHeaderRestaurantsComponent {
    private readonly _store = inject(Store);
    private readonly _reviewsContext = inject(ReviewsContext);
    private readonly _destroyRef = inject(DestroyRef);

    readonly MAX_SELECTABLE_RESTAURANTS = MAX_SELECTABLE_RESTAURANTS;
    readonly selectableRestaurants$: Observable<Restaurant[]> = this._store
        .select(selectOwnRestaurants)
        .pipe(map((restaurants) => restaurants.filter((restaurant) => !restaurant.isBrandBusiness())));

    readonly storedRestaurants$: Observable<Restaurant[]> = combineLatest([
        this._reviewsContext.selectedRestaurants$,
        this.selectableRestaurants$,
    ]).pipe(
        map(([selectedRestaurants, selectableRestaurants]) => {
            if (!selectedRestaurants.length) {
                this.onRestaurantsChange(selectableRestaurants);
                return selectableRestaurants;
            } else {
                return selectedRestaurants;
            }
        }),

        takeUntilDestroyed(this._destroyRef)
    );

    onRestaurantsChange(restaurants: Restaurant[]): void {
        this._store.dispatch(ReviewsActions.editRestaurants({ restaurantIds: restaurants.map((restaurant) => restaurant._id) }));
    }

    compareByRestaurantId(restaurant: Restaurant): string {
        return restaurant._id;
    }
}
