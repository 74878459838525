import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Inject, Signal, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationExtras } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, differenceBy, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { combineLatest, filter, forkJoin, map, Observable, of, switchMap, take } from 'rxjs';

import { NfcWithRestaurantDto, RestaurantsWithActiveWheels, UserPermissionsByPlatformDto, WheelOfFortuneDto } from '@malou-io/package-dto';
import {
    DEFAULT_WHEEL_OF_FORTUNE_GIFT_CLAIM_DURATION_IN_DAYS,
    DEFAULT_WHEEL_OF_FORTUNE_GIFT_CLAIM_START_DATE_OPTION,
    DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR,
    DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR,
    GIFT_NAME_MAX_LENGTH,
    isNotNil,
    isSameDay,
    NextDrawEnabledDelay,
    PlatformDefinitions,
    PlatformKey,
    Role,
    WHEEL_OF_FORTUNE_NEEDED_ROLES,
    WheelOfFortuneRedirectionPlatformKey,
} from '@malou-io/package-utils';

import { CredentialsService } from ':core/services/credentials.service';
import { DialogService } from ':core/services/dialog.service';
import { NfcService } from ':core/services/nfc.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import * as JimoActions from ':modules/jimo/jimo.actions';
import { PlayWheelOfFortuneRootComponent } from ':modules/play-wheel-of-fortune/play-wheel-of-fortune-root.component';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import * as userActions from ':modules/user/store/user.actions';
import { selectUserInfos, selectUserPlatformsPermissions, selectUserRestaurants } from ':modules/user/store/user.selectors';
import { UserRestaurant } from ':modules/user/user';
import { AppearanceSettingsWheelOfFortuneComponent } from ':modules/wheels-of-fortune/upsert-wheel-of-fortune-modal/appearance-settings-wheel-of-fortune/appearance-settings-wheel-of-fortune.component';
import { GiftsStocksSettingsComponent } from ':modules/wheels-of-fortune/upsert-wheel-of-fortune-modal/gifts-stocks-settings/gifts-stocks-settings.component';
import { RestaurantSelectionWheelOfFortuneComponent } from ':modules/wheels-of-fortune/upsert-wheel-of-fortune-modal/restaurant-selection-wheel-of-fortune/restaurant-selection-wheel-of-fortune.component';
import { WheelOfFortuneGlobalSettingsComponent } from ':modules/wheels-of-fortune/upsert-wheel-of-fortune-modal/wheel-of-fortune-global-settings/gifts-stocks-settings/wheel-of-fortune-global-settings.component';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { formatHours } from ':shared/helpers';
import {
    LightRestaurant,
    mapRestaurantWithTotemsAndWheelsToLightRestaurant,
    Media,
    Restaurant,
    RestaurantWithTotemsAndWheels,
} from ':shared/models';
import { Gift } from ':shared/models/gift';
import { WheelOfFortune, WheelOfFortuneGlobalSettings } from ':shared/models/wheel-of-fortune';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

interface WheelOfFortuneTab {
    label: string;
    icon: string;
    key: TabKeys;
}

enum TabKeys {
    RESTAURANTS = 'restaurants',
    GIFTS = 'gifts',
    SETTINGS = 'settings',
    APPEARANCE = 'appearance',
}

export enum AppRedirection {
    TOTEMS = 'totems',
    SETTINGS = 'settings',
}

export interface CloseModalSettings {
    shouldCheckBeforeClose?: boolean;
    restaurantId?: string;
    restaurantIdInAggregatedWheelOfFortune?: string;
    redirection?: AppRedirection;
    shouldReload?: boolean;
}

export interface CloseModalProps {
    redirectUrl?: { path: string[]; extras?: NavigationExtras };
    shouldReload?: boolean;
}

@Component({
    selector: 'app-upsert-wheel-of-fortune-modal',
    templateUrl: './upsert-wheel-of-fortune-modal.component.html',
    styleUrls: ['./upsert-wheel-of-fortune-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatTooltipModule,
        TranslateModule,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        RestaurantSelectionWheelOfFortuneComponent,
        AppearanceSettingsWheelOfFortuneComponent,
        GiftsStocksSettingsComponent,
        SkeletonComponent,
        WheelOfFortuneGlobalSettingsComponent,
        PlayWheelOfFortuneRootComponent,
        ImagePathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertWheelOfFortuneModalComponent {
    readonly SvgIcon = SvgIcon;
    readonly isInitialLoading: WritableSignal<boolean> = signal(true);
    readonly showCloseModal: WritableSignal<boolean> = signal(false);
    readonly saveTooltipError: WritableSignal<string | null> = signal(null);
    readonly isPreview: WritableSignal<boolean> = signal(true);

    readonly allRestaurants: WritableSignal<RestaurantWithTotemsAndWheels[]> = signal([]);
    readonly selectedRestaurants: WritableSignal<RestaurantWithTotemsAndWheels[]> = signal([]);
    readonly selectedTotems: WritableSignal<NfcWithRestaurantDto[]> = signal([]);
    readonly nonOwnedSelectedTotems: WritableSignal<NfcWithRestaurantDto[]> = signal([]);
    readonly nonOwnedRestaurants: WritableSignal<LightRestaurant[]> = signal([]);
    readonly userRestaurants: WritableSignal<UserRestaurant[]> = signal([]);

    readonly primaryColor: WritableSignal<string> = signal(DEFAULT_WHEEL_OF_FORTUNE_PRIMARY_COLOR);
    readonly secondaryColor: WritableSignal<string> = signal(DEFAULT_WHEEL_OF_FORTUNE_SECONDARY_COLOR);
    readonly media: WritableSignal<Media | null> = signal(null);

    readonly gifts: WritableSignal<Gift[]> = signal([
        new Gift({
            name: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.default_values.appetizer'),
            weight: 20,
            stocks: [],
        }),
        new Gift({
            name: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.default_values.dessert'),
            weight: 10,
            stocks: [],
        }),
        new Gift({
            name: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.default_values.lunch_menu'),
            weight: 5,
            stocks: [],
        }),
        new Gift({
            name: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.default_values.coffee'),
            weight: 20,
            stocks: [],
        }),
        new Gift({
            name: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.default_values.glass_of_wine'),
            weight: 10,
            stocks: [],
        }),
        new Gift({
            name: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.default_values.5_percent_discount'),
            weight: 20,
            stocks: [],
        }),
        new Gift({
            name: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.default_values.t_shirt'),
            weight: 15,
            stocks: [],
        }),
    ]);

    readonly globalSettings: WritableSignal<WheelOfFortuneGlobalSettings> = signal({
        giftClaimStartDateOption: DEFAULT_WHEEL_OF_FORTUNE_GIFT_CLAIM_START_DATE_OPTION,
        giftClaimDurationInDays: DEFAULT_WHEEL_OF_FORTUNE_GIFT_CLAIM_DURATION_IN_DAYS,
        startDate: new Date(),
        endDate: null,
        isEndDateMandatory: false,
        redirectionSettings: {
            nextDrawEnabledDelay: NextDrawEnabledDelay.NEVER,
            shouldRedirect: true,
            platforms: [{ order: 0, platformKey: PlatformKey.GMB }],
        },
    });

    readonly isUpdate: WritableSignal<boolean> = signal(false);

    readonly shouldGoToNextTab: Signal<boolean> = computed(() => !this.isUpdate() && this.selectedTab() !== this._APPEARANCE_TAB);

    readonly selectedRedirectionPlatforms = computed((): WheelOfFortuneRedirectionPlatformKey[] =>
        this.globalSettings().redirectionSettings.shouldRedirect
            ? this.globalSettings().redirectionSettings.platforms.map((platform) => platform.platformKey)
            : []
    );
    readonly TabKeys = TabKeys;

    private readonly _RESTAURANTS_TAB: WheelOfFortuneTab = {
        key: TabKeys.RESTAURANTS,
        label: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.restaurants.title'),
        icon: 'localisation',
    };
    private readonly _GIFTS_TAB: WheelOfFortuneTab = {
        key: TabKeys.GIFTS,
        label: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.gifts.title'),
        icon: 'gift',
    };
    private readonly _PARAMETERS_TAB: WheelOfFortuneTab = {
        key: TabKeys.SETTINGS,
        label: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.parameters'),
        icon: 'parameter',
    };
    private readonly _APPEARANCE_TAB: WheelOfFortuneTab = {
        key: TabKeys.APPEARANCE,
        label: this._translateService.instant('wheel_of_fortune.new_wheel_modal.tabs.appearance.title'),
        icon: 'paint',
    };

    readonly TABS: WritableSignal<WheelOfFortuneTab[]> = signal([this._GIFTS_TAB, this._PARAMETERS_TAB, this._APPEARANCE_TAB]);

    readonly selectedTabIndex: WritableSignal<number> = signal(0);
    readonly selectedTab: Signal<WheelOfFortuneTab> = computed(() => this.TABS()[this.selectedTabIndex()]);

    readonly restaurantsTabSaveErrorTooltip: Signal<string | null> = computed(() =>
        this.selectedRestaurants().length === 0 && this.nonOwnedRestaurants().length === 0
            ? this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.need_at_least_one')
            : null
    );
    readonly giftsTabSaveErrorTooltip: Signal<string | null> = computed(() => {
        const hasGiftWithoutName = this.gifts().some((gift) => !gift.name);
        if (hasGiftWithoutName) {
            return this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.gift_without_name');
        }

        const hasGiftWithoutWeight = this.gifts().some((gift) => gift.weight === null);
        if (hasGiftWithoutWeight) {
            return this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.gift_without_probability');
        }

        const hasGiftWithTooLongName = this.gifts().some((gift) => gift.name.length > GIFT_NAME_MAX_LENGTH);
        if (hasGiftWithTooLongName) {
            return this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.gift_with_too_long_name');
        }

        const giftWeightSumIsNot100 = this.gifts().reduce((acc, gift) => acc + gift.weight, 0) !== 100;
        if (giftWeightSumIsNot100) {
            return this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.gift_weight_sum_not_100');
        }

        return null;
    });
    readonly settingsTabSaveErrorTooltip: Signal<string | null> = computed(() => {
        const isMissingStartDate = !this.globalSettings().startDate;
        if (isMissingStartDate) {
            return this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.missing_start_date');
        }

        const isMissingEndDate = !this.globalSettings().endDate && this.globalSettings().isEndDateMandatory;
        if (isMissingEndDate) {
            return this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.missing_end_date');
        }

        const isMissingRedirectionPlatform =
            this.globalSettings().redirectionSettings.shouldRedirect &&
            ((this.globalSettings().redirectionSettings.nextDrawEnabledDelay !== NextDrawEnabledDelay.NEVER &&
                this.globalSettings().redirectionSettings.platforms.some((platformWithOrder) => !platformWithOrder.platformKey)) ||
                (this.globalSettings().redirectionSettings.nextDrawEnabledDelay === NextDrawEnabledDelay.NEVER &&
                    !this.globalSettings().redirectionSettings.platforms[0]?.platformKey));
        if (isMissingRedirectionPlatform) {
            return this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.missing_redirection_platform');
        }

        const hasMultipleTimesTheSameRedirectionPlatform =
            this.globalSettings().redirectionSettings.shouldRedirect &&
            this.globalSettings().redirectionSettings.nextDrawEnabledDelay !== NextDrawEnabledDelay.NEVER &&
            this.globalSettings().redirectionSettings.platforms.length !==
                new Set(this.globalSettings().redirectionSettings.platforms.map((platform) => platform.platformKey)).size;
        if (hasMultipleTimesTheSameRedirectionPlatform) {
            return this._translateService.instant(
                'wheel_of_fortune.new_wheel_modal.errors.has_multiple_times_the_same_redirection_platform'
            );
        }

        return null;
    });
    readonly appearanceTabSaveErrorTooltip: Signal<string | null> = computed(() => {
        const hasMissingColors = !this.primaryColor() || !this.secondaryColor();
        if (hasMissingColors) {
            return this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.missing_colors');
        }

        return null;
    });

    readonly saveErrorByTab: { [key: string]: Signal<string | null> } = {
        [TabKeys.RESTAURANTS]: this.restaurantsTabSaveErrorTooltip,
        [TabKeys.GIFTS]: this.giftsTabSaveErrorTooltip,
        [TabKeys.SETTINGS]: this.settingsTabSaveErrorTooltip,
        [TabKeys.APPEARANCE]: this.appearanceTabSaveErrorTooltip,
    };

    readonly saveErrorTooltip: Signal<string | null> = computed(() => {
        const selectedTabKey = this.selectedTab().key;
        const selectedTabErrorKey = this.saveErrorByTab[selectedTabKey];
        const selectedTabError = selectedTabErrorKey();
        if (selectedTabError) {
            return selectedTabError;
        }
        const tabLabelsWithError = this.TABS()
            .map((tab) => (this.saveErrorByTab[tab.key]() ? tab.label : null))
            .filter(Boolean);
        if (tabLabelsWithError.length) {
            return this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.has_error', {
                tabs: tabLabelsWithError.join(', '),
            });
        }
        return null;
    });

    readonly builtWheelOfFortuneData: Signal<WheelOfFortune> = computed(() => {
        const startDate = this.globalSettings().startDate;
        const endDate = this.globalSettings().endDate;
        const selectedRestaurants = [
            ...this.selectedRestaurants().map((restaurant) => mapRestaurantWithTotemsAndWheelsToLightRestaurant(restaurant)),
            ...this.nonOwnedRestaurants(),
        ];
        return new WheelOfFortune({
            restaurants: selectedRestaurants,
            totemIds: this.selectedTotems().map((totem) => totem.id),
            gifts: this.gifts().map((gift) => this._buildGiftWithStock(gift, selectedRestaurants)),
            parameters: {
                primaryColor: this.primaryColor(),
                secondaryColor: this.secondaryColor(),
                media: this.media(),
                giftClaimStartDateOption: this.globalSettings().giftClaimStartDateOption,
                giftClaimDurationInDays: this.globalSettings().giftClaimDurationInDays,
                redirectionSettings: this.globalSettings().redirectionSettings,
            },
            startDate: startDate ? DateTime.fromJSDate(startDate).startOf('day').toJSDate() : null,
            endDate: this.globalSettings().isEndDateMandatory && endDate ? DateTime.fromJSDate(endDate).endOf('day').toJSDate() : null,
        });
    });

    readonly closeModalSettings: WritableSignal<CloseModalSettings> = signal({});

    readonly currentTime = formatHours(new Date());
    readonly giftIdToOpen: WritableSignal<string | null> = signal(null);

    readonly isUpserting = signal<boolean>(false);

    isMalouAdmin: boolean;

    private readonly _isAdmin$ = this._store.select(selectUserInfos).pipe(
        filter(isNotNil),
        map((infos) => infos.role === Role.ADMIN)
    );
    private readonly _restaurants$: Observable<Restaurant[]> = this._store.select(selectOwnRestaurants);
    private _wheelOfFortuneSnapshot: WheelOfFortune;
    private readonly _currentRestaurantId: WritableSignal<string | null> = signal(null);

    constructor(
        private readonly _dialogRef: MatDialogRef<UpsertWheelOfFortuneModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            isAggregatedView: boolean;
            isAggregatedWheelOfFortune: boolean;
            wheelOfFortune?: WheelOfFortune;
            giftId?: string;
            restaurantId?: string;
        },
        private readonly _store: Store,
        private readonly _translateService: TranslateService,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        private readonly _nfcService: NfcService,
        private readonly _toastService: ToastService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _credentialsService: CredentialsService,
        private readonly _dialogService: DialogService
    ) {
        this._isAdmin$.subscribe((isAdmin) => (this.isMalouAdmin = isAdmin));
        this._initializeAppearance();
        this._initializeGifts();
        this._initializeGlobalSettings();

        this.isUpdate.set(!!this.data.wheelOfFortune?.id);

        const currentRestaurant = this._restaurantsService.restaurantSelected$.getValue();
        this._currentRestaurantId.set(currentRestaurant?._id ?? null);

        if (this.data.isAggregatedWheelOfFortune) {
            this.TABS.update((tabs) => [this._RESTAURANTS_TAB, ...tabs]);
            this._initializeRestaurants();
        } else {
            if (!currentRestaurant) {
                this._toastService.openErrorToast(
                    this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.no_restaurant_selected')
                );
                return;
            }

            const searchNfcs$ = this._nfcService.search({
                restaurantId: currentRestaurant._id,
            });

            const getPreviousWheelOfFortune$ = this.data.wheelOfFortune?.id
                ? of({ data: null })
                : this._wheelsOfFortuneService.getPreviousWheelOfFortune(currentRestaurant._id);

            forkJoin([searchNfcs$, getPreviousWheelOfFortune$]).subscribe({
                next: ([nfcsResult, previousWheelOfFortuneResult]) => {
                    const totems: NfcWithRestaurantDto[] = nfcsResult.data?.filter((totem) => totem.active) || [];
                    const selectedRestaurant = new RestaurantWithTotemsAndWheels({ ...currentRestaurant, totems });
                    this.selectedRestaurants.set([selectedRestaurant]);
                    this.selectedTotems.set(
                        this.data.wheelOfFortune ? totems.filter((totem) => this.data.wheelOfFortune?.totemIds.includes(totem.id)) : []
                    );
                    if (!this.data.wheelOfFortune) {
                        this.media.set(selectedRestaurant.logo);
                    }
                    if (previousWheelOfFortuneResult.data) {
                        this._initializeWheelOfFortuneWithPreviousData(previousWheelOfFortuneResult.data);
                    }

                    this.isInitialLoading.set(false);
                    this._saveWheelOfFortuneSnapshot();
                },
                error: () => {
                    this.selectedRestaurants.set([new RestaurantWithTotemsAndWheels(currentRestaurant)]);
                    this.selectedTotems.set([]);
                    this.isInitialLoading.set(false);
                    this._saveWheelOfFortuneSnapshot();
                    this._toastService.openErrorToast(
                        this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.error_getting_totems')
                    );
                },
            });
        }

        if (this.data.giftId) {
            this.selectedTabIndex.set(this.TABS().findIndex((tab) => tab.key === TabKeys.GIFTS));
            this.giftIdToOpen.set(this.data.giftId);
        }
    }

    handleTabChange(index: number): void {
        this.selectedTabIndex.set(index);
    }

    changeTab(): void {
        if (!this.shouldGoToNextTab()) {
            return;
        }
        switch (this.selectedTab()) {
            case this._RESTAURANTS_TAB:
                this.selectedTabIndex.set(this.TABS().findIndex((tab) => tab.key === TabKeys.GIFTS));
                return;
            case this._GIFTS_TAB:
                this.selectedTabIndex.set(this.TABS().findIndex((tab) => tab.key === TabKeys.SETTINGS));
                return;
            case this._PARAMETERS_TAB:
            default:
                this.selectedTabIndex.set(this.TABS().findIndex((tab) => tab.key === TabKeys.APPEARANCE));
                return;
        }
    }

    save(): void {
        if (this.saveErrorTooltip()) {
            return;
        }

        const wheelOfFortuneData = this.builtWheelOfFortuneData();

        const isAggregatedWheelInNonAggregatedView = this.data.isAggregatedWheelOfFortune && !this.data.isAggregatedView;
        const currentRestaurantId = this._currentRestaurantId();
        const isCurrentRestaurantStillInWheelOfFortune =
            currentRestaurantId && wheelOfFortuneData.restaurants.find((restaurant) => restaurant.id.toString() === currentRestaurantId);
        const willRemoveCurrentLocationFromWheel = isAggregatedWheelInNonAggregatedView && !isCurrentRestaurantStillInWheelOfFortune;

        if (willRemoveCurrentLocationFromWheel) {
            this._openAreYouSureModalBeforeSave(wheelOfFortuneData);
            return;
        }

        this._saveWheelOfFortune(wheelOfFortuneData);
    }

    cancelClose(): void {
        this.showCloseModal.set(false);
    }

    close({
        shouldCheckBeforeClose = false,
        restaurantId,
        restaurantIdInAggregatedWheelOfFortune,
        redirection,
        shouldReload = false,
    }: CloseModalSettings): void {
        if (shouldCheckBeforeClose && this._hasModifications()) {
            this.closeModalSettings.set({
                shouldCheckBeforeClose: false,
                restaurantId,
                restaurantIdInAggregatedWheelOfFortune,
                redirection,
                shouldReload,
            });
            this.showCloseModal.set(true);
        } else {
            const path = this._getRedirectionPath(redirection);
            const redirectUrl = restaurantId
                ? { path: [`./restaurants/${restaurantId}${path}`], extras: {} }
                : restaurantIdInAggregatedWheelOfFortune
                  ? {
                        path: ['./groups/boosters/wheels-of-fortune'],
                        extras: { queryParams: { restaurantId: restaurantIdInAggregatedWheelOfFortune } },
                    }
                  : undefined;
            this._dialogRef.close({ redirectUrl, shouldReload });
        }
    }

    private _openAreYouSureModalBeforeSave(wheelOfFortuneData: WheelOfFortune): void {
        this._dialogService.open({
            illustration: Illustration.Goggles,
            variant: DialogVariant.INFO,
            title: this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.are_you_sure'),
            message: this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.wont_be_part_of_aggregated'),
            primaryButton: {
                label: this._translateService.instant('common.delete'),
                action: () => {
                    this._saveWheelOfFortune(wheelOfFortuneData);
                },
            },
            secondaryButton: {
                label: this._translateService.instant('common.cancel'),
            },
        });
    }

    private _saveWheelOfFortune(wheelOfFortuneData: WheelOfFortune): void {
        const giftStockWithQuantity: string[] = wheelOfFortuneData.gifts
            .map((gift) => gift.stocks.filter((stock) => stock.quantity !== 0).map((stock) => stock.id))
            .flat();
        this._removeNotEmptyGiftStocksFromLocalStorage(giftStockWithQuantity);

        const upsertWheelOfFortune$ = this.data.wheelOfFortune?.id
            ? this._wheelsOfFortuneService.updateWheelOfFortuneById(
                  this.data.wheelOfFortune.id,
                  wheelOfFortuneData,
                  this.data.isAggregatedWheelOfFortune
              )
            : this._wheelsOfFortuneService.createWheelOfFortune(wheelOfFortuneData, this.data.isAggregatedWheelOfFortune);

        this.isUpserting.set(true);

        upsertWheelOfFortune$.pipe(switchMap(() => this._wheelsOfFortuneService.getUserWheelOfFortuneInformations())).subscribe({
            next: (res) => {
                this.isUpserting.set(false);
                this.close({ shouldReload: true });
                this._store.dispatch(
                    JimoActions.changeWheelOfFortuneStatus({
                        hasCreatedAWheel: res.data.hasCreatedAWheel,
                        firstWheelCreatedAt: res.data.firstWheelCreatedAt,
                        hasAtLeastOneActiveOrProgrammedWheel: res.data.hasAtLeastOneActiveOrProgrammedWheel,
                        latestEndDateForActiveWheels: res.data.latestEndDateForActiveWheels,
                    })
                );
            },
            error: (err) => {
                this.isUpserting.set(false);
                if (this.data.wheelOfFortune?.id) {
                    this._toastService.openErrorToast(
                        this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.update_error')
                    );
                } else {
                    this._toastService.openErrorToast(
                        this._translateService.instant('wheel_of_fortune.new_wheel_modal.errors.create_error')
                    );
                }
                console.error(`Error getting user wof informations for Jimo: ${JSON.stringify(err)}`);
            },
        });
    }

    private _removeNotEmptyGiftStocksFromLocalStorage(notEmptyGiftStockIds: string[]): void {
        const stockIds: string[] = JSON.parse(localStorage.getItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_EMPTY_STOCK_MODAL) || '[]');
        const emptyStocksIds = stockIds.filter((stockId) => !notEmptyGiftStockIds.includes(stockId));
        localStorage.setItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_EMPTY_STOCK_MODAL, JSON.stringify(emptyStocksIds));
    }

    private _getRedirectionPath(redirection: AppRedirection | undefined): string {
        switch (redirection) {
            case AppRedirection.SETTINGS:
                return '/settings/platforms/connection';
            case AppRedirection.TOTEMS:
                return '/boosters/wheels-of-fortune';
            default:
                return '';
        }
    }

    private _initializeRestaurants(): void {
        combineLatest([this._restaurants$, this._store.select(selectUserRestaurants), this._getPlatformsPermissions$()])
            .pipe(
                take(1),
                switchMap(
                    ([restaurants, userRestaurants, restaurantWithInvalidPermissionsByPlatform]: [
                        Restaurant[],
                        UserRestaurant[],
                        Record<PlatformKey, string[]>,
                    ]) =>
                        forkJoin([
                            this._wheelsOfFortuneService.getRestaurantIdsWithActiveWheel(),
                            of(restaurants),
                            of(userRestaurants),
                            of(restaurantWithInvalidPermissionsByPlatform),
                        ])
                ),
                switchMap(
                    ([res, restaurants, userRestaurants, restaurantWithInvalidPermissionsByPlatform]: [
                        RestaurantsWithActiveWheels,
                        Restaurant[],
                        UserRestaurant[],
                        Record<PlatformKey, string[]>,
                    ]) =>
                        forkJoin([
                            of(restaurants),
                            of(res.restaurantsWithActiveWheel),
                            of(res.restaurantsWithActiveAggregatedWheel),
                            of(userRestaurants),
                            of(restaurantWithInvalidPermissionsByPlatform),
                            this._nfcService
                                .search(
                                    {},
                                    restaurants.map((restaurant) => restaurant._id)
                                )
                                .pipe(map((totemsRes) => totemsRes?.data)),
                            this._nfcService
                                .getNfcByIds(this.data.wheelOfFortune?.totemIds ?? [])
                                .pipe(map((totemsRes) => totemsRes?.data)),
                        ])
                )
            )
            .subscribe({
                next: ([
                    restaurants,
                    restaurantsWithActiveWheel,
                    restaurantsWithActiveAggregatedWheel,
                    userRestaurants,
                    restaurantWithInvalidPermissionsByPlatform,
                    allTotems,
                    existingTotemsInWheel,
                ]: [
                    Restaurant[],
                    string[],
                    string[],
                    UserRestaurant[],
                    Record<PlatformKey, string[]>,
                    NfcWithRestaurantDto[],
                    NfcWithRestaurantDto[],
                ]) => {
                    this.isInitialLoading.set(false);
                    this.allRestaurants.set(
                        restaurants
                            .filter((restaurant) => !restaurant.isBrandBusiness())
                            .map((restaurant) => {
                                const totems = allTotems?.filter((totem) => totem.restaurantId === restaurant._id);
                                return new RestaurantWithTotemsAndWheels({
                                    ...restaurant,
                                    hasAggregatedWheelOfFortune: restaurantsWithActiveAggregatedWheel.includes(restaurant._id),
                                    hasWheelOfFortune: restaurantsWithActiveWheel.includes(restaurant._id),
                                    totems: totems.filter((totem) => totem.active),
                                    missingPermissionKeys: Object.entries(restaurantWithInvalidPermissionsByPlatform)
                                        .filter(([_, restaurantIdsWithoutPermissions]) =>
                                            restaurantIdsWithoutPermissions.includes(restaurant.id)
                                        )
                                        .map(([platformKey, _]) => platformKey as WheelOfFortuneRedirectionPlatformKey),
                                });
                            })
                    );
                    const previouslySelectedRestaurants = this.data.wheelOfFortune?.restaurants.map(({ id }) => id) || [];
                    const restaurantIdsWithoutNeededRole = this.allRestaurants()
                        .filter((restaurant) => {
                            const userRestaurant = userRestaurants.find((ur) => ur.restaurantId === restaurant._id);
                            return userRestaurant?.caslRole && !WHEEL_OF_FORTUNE_NEEDED_ROLES.includes(userRestaurant?.caslRole);
                        })
                        .map((restaurant) => restaurant._id);
                    this.selectedRestaurants.set(
                        this.data.wheelOfFortune
                            ? this.allRestaurants().filter((rest) => previouslySelectedRestaurants.includes(rest._id))
                            : this.allRestaurants().filter(
                                  (rest) =>
                                      rest.hasNoWheel() &&
                                      this.globalSettings().redirectionSettings.platforms.some(
                                          (platform) => !rest.missingPermissionKeys.includes(platform.platformKey)
                                      ) &&
                                      !restaurantIdsWithoutNeededRole.includes(rest._id) &&
                                      rest.boosterPack?.activated
                              )
                    );
                    const nonOwnedRestaurants = differenceBy(this.data.wheelOfFortune?.restaurants, this.allRestaurants(), 'id');
                    this.nonOwnedRestaurants.set([...nonOwnedRestaurants]);
                    this.userRestaurants.set(userRestaurants);

                    const previouslySelectedTotems = uniqBy(
                        [
                            ...allTotems.filter((totem) => this.data.wheelOfFortune?.totemIds?.includes(totem.id) && totem.active),
                            ...existingTotemsInWheel,
                        ],
                        'id'
                    );
                    const nonOwnedRestaurantIds = nonOwnedRestaurants.map((restaurant) => restaurant.id);
                    this.nonOwnedSelectedTotems.set(
                        previouslySelectedTotems.filter((totem) => nonOwnedRestaurantIds.includes(totem.restaurantId))
                    );
                    this.selectedTotems.set(this.data.wheelOfFortune ? previouslySelectedTotems : []);

                    this._saveWheelOfFortuneSnapshot();
                },
                error: () => {
                    this.isInitialLoading.set(false);
                },
            });
    }

    private _getPlatformsPermissions$(): Observable<Record<PlatformKey, string[]>> {
        return combineLatest([this._restaurants$, this._store.select(selectUserPlatformsPermissions)]).pipe(
            switchMap(([restaurants, userPlatformPermissions]: [Restaurant[], UserPermissionsByPlatformDto[]]) => {
                const platformsWithPermissions = PlatformDefinitions.getPlatformKeysForWheelOfFortuneRedirection().filter(
                    (key) => key !== PlatformKey.INSTAGRAM
                );

                const existingPlatformsPermissions: UserPermissionsByPlatformDto[] = [];
                const platformKeysToUpdateInStore: PlatformKey[] = [];
                for (const platformKey of platformsWithPermissions) {
                    const platformPermissions =
                        userPlatformPermissions.find((permissions) => permissions.key === platformKey)?.permissions || [];
                    const restaurantIdsForPermissions = platformPermissions?.map(
                        (restaurantPermission) => restaurantPermission.restaurantId
                    );
                    const isStoreUpToDate = restaurants.every((restaurant) => restaurantIdsForPermissions.includes(restaurant._id));
                    if (!isStoreUpToDate && !this.isMalouAdmin) {
                        this._store.dispatch({
                            type: userActions.loadUserPlatformPermissions.type,
                            key: platformKey,
                        });
                        platformKeysToUpdateInStore.push(platformKey);
                    } else {
                        existingPlatformsPermissions.push({ key: platformKey, permissions: platformPermissions });
                    }
                }

                return forkJoin(
                    platformsWithPermissions.map((platformKey) => {
                        const shouldGetPermissions = platformKeysToUpdateInStore.includes(platformKey);
                        if (shouldGetPermissions) {
                            return this._credentialsService.getPermissionsForAllPlatformConnectionTypes$(
                                platformKey,
                                restaurants.map((rest) => rest._id)
                            );
                        }
                        return of(
                            existingPlatformsPermissions.find(({ key }) => key === platformKey) ??
                                ({ key: platformKey, permissions: [] } as UserPermissionsByPlatformDto)
                        );
                    })
                );
            }),
            map((restaurantPermissionsByPlatforms: UserPermissionsByPlatformDto[]) =>
                restaurantPermissionsByPlatforms.reduce(
                    (acc, item) => {
                        const invalidRestaurantIds = item.permissions
                            .filter((permission) => !permission.isValid)
                            .map((permission) => permission.restaurantId);
                        acc[item.key] = invalidRestaurantIds;
                        return acc;
                    },
                    {} as Record<PlatformKey, string[]>
                )
            )
        );
    }

    private _initializeGifts(): void {
        if (!this.data.wheelOfFortune) {
            return;
        }
        this.gifts.set(cloneDeep(this.data.wheelOfFortune.gifts));
    }

    private _initializeAppearance(): void {
        if (!this.data.wheelOfFortune) {
            return;
        }
        this.primaryColor.set(this.data.wheelOfFortune.parameters.primaryColor);
        this.secondaryColor.set(this.data.wheelOfFortune.parameters.secondaryColor);
        this.media.set(this.data.wheelOfFortune.parameters.media);
    }

    private _initializeGlobalSettings(): void {
        if (!this.data.wheelOfFortune) {
            return;
        }
        this.globalSettings.set({
            giftClaimDurationInDays: this.data.wheelOfFortune.parameters.giftClaimDurationInDays,
            giftClaimStartDateOption: this.data.wheelOfFortune.parameters.giftClaimStartDateOption,
            startDate: this.data.wheelOfFortune.startDate,
            endDate: this.data.wheelOfFortune.endDate,
            isEndDateMandatory: !!this.data.wheelOfFortune.endDate,
            redirectionSettings: this.data.wheelOfFortune.parameters.redirectionSettings,
        });
    }

    private _buildGiftWithStock(gift: Gift, restaurants: LightRestaurant[]): Gift {
        gift.stocks = restaurants.map((restaurant) => {
            const existingStock = gift.stocks.find((stock) => stock.restaurant.id === restaurant.id);
            return existingStock ?? { id: '', quantity: null, restaurant };
        });

        return gift;
    }

    private _saveWheelOfFortuneSnapshot(): void {
        this._wheelOfFortuneSnapshot = cloneDeep(this.builtWheelOfFortuneData());
    }

    private _hasModifications(): boolean {
        const currentWheelOfFortune = this.builtWheelOfFortuneData();

        return (
            this._hasModificationsInStartDate(currentWheelOfFortune) ||
            this._hasModificationsInEndDate(currentWheelOfFortune) ||
            this._hasModificationsInRestaurants(currentWheelOfFortune) ||
            this._hasModificationsInTotems(currentWheelOfFortune) ||
            this._hasModificationsInGifts(currentWheelOfFortune) ||
            this._hasModificationsInParameters(currentWheelOfFortune)
        );
    }

    private _hasModificationsInStartDate(currentWheelOfFortune: WheelOfFortune): boolean {
        return this._hasModificationsInDate(currentWheelOfFortune.startDate, this._wheelOfFortuneSnapshot.startDate);
    }

    private _hasModificationsInEndDate(currentWheelOfFortune: WheelOfFortune): boolean {
        return this._hasModificationsInDate(currentWheelOfFortune.endDate, this._wheelOfFortuneSnapshot.endDate);
    }

    private _hasModificationsInDate(date1: Date | null, date2: Date | null): boolean {
        return (!date1 && !!date2) || (!!date1 && !date2) || (!!date1 && !!date2 && !isSameDay(date1, date2));
    }

    private _hasModificationsInGifts(currentWheelOfFortune: WheelOfFortune): boolean {
        const allCurrentGiftsAreInSnapshot = currentWheelOfFortune.gifts.every((gift) =>
            this._wheelOfFortuneSnapshot.gifts.some(
                (giftSnapshot) =>
                    gift.id === giftSnapshot.id &&
                    gift.name === giftSnapshot.name &&
                    gift.weight === giftSnapshot.weight &&
                    !this._hasModificationsInGiftStocks(gift, giftSnapshot)
            )
        );

        const allSnapshotGiftsAreInCurrentWheel = this._wheelOfFortuneSnapshot.gifts.every((gift) =>
            currentWheelOfFortune.gifts.some(
                (giftSnapshot) =>
                    gift.id === giftSnapshot.id &&
                    gift.name === giftSnapshot.name &&
                    gift.weight === giftSnapshot.weight &&
                    !this._hasModificationsInGiftStocks(gift, giftSnapshot)
            )
        );

        return !allCurrentGiftsAreInSnapshot || !allSnapshotGiftsAreInCurrentWheel;
    }

    private _hasModificationsInGiftStocks(currentGift: Gift, snapshotGift: Gift): boolean {
        return !currentGift.stocks.every((stock) =>
            snapshotGift.stocks.some(
                (stockSnapshot) =>
                    stock.restaurant.id === stockSnapshot.restaurant.id &&
                    stock.quantity === stockSnapshot.quantity &&
                    stock.restaurant.name === stockSnapshot.restaurant.name
            )
        );
    }

    private _hasModificationsInRestaurants(currentWheelOfFortune: WheelOfFortune): boolean {
        const currentRestaurantIds = currentWheelOfFortune.restaurants.map((restaurant) => restaurant.id);
        const snapshotRestaurantIds = this._wheelOfFortuneSnapshot.restaurants.map((restaurant) => restaurant.id);

        return (
            !currentRestaurantIds.every((restaurantId) => snapshotRestaurantIds.includes(restaurantId)) &&
            !snapshotRestaurantIds.every((restaurantId) => currentRestaurantIds.includes(restaurantId))
        );
    }

    private _hasModificationsInTotems(currentWheelOfFortune: WheelOfFortune): boolean {
        return (
            !currentWheelOfFortune.totemIds.every((totemId) => this._wheelOfFortuneSnapshot.totemIds.includes(totemId)) &&
            !this._wheelOfFortuneSnapshot.totemIds.every((totemId) => currentWheelOfFortune.totemIds.includes(totemId))
        );
    }

    private _hasModificationsInParameters(currentWheelOfFortune: WheelOfFortune): boolean {
        return (
            currentWheelOfFortune.parameters.primaryColor !== this._wheelOfFortuneSnapshot.parameters.primaryColor ||
            currentWheelOfFortune.parameters.secondaryColor !== this._wheelOfFortuneSnapshot.parameters.secondaryColor ||
            currentWheelOfFortune.getLogoUrl() !== this._wheelOfFortuneSnapshot.getLogoUrl() ||
            currentWheelOfFortune.parameters.giftClaimStartDateOption !==
                this._wheelOfFortuneSnapshot.parameters.giftClaimStartDateOption ||
            currentWheelOfFortune.parameters.giftClaimDurationInDays !== this._wheelOfFortuneSnapshot.parameters.giftClaimDurationInDays ||
            currentWheelOfFortune.parameters.redirectionSettings.nextDrawEnabledDelay !==
                this._wheelOfFortuneSnapshot.parameters.redirectionSettings.nextDrawEnabledDelay ||
            currentWheelOfFortune.parameters.redirectionSettings.shouldRedirect !==
                this._wheelOfFortuneSnapshot.parameters.redirectionSettings.shouldRedirect ||
            currentWheelOfFortune.parameters.redirectionSettings.platforms.some(
                (platform) =>
                    !this._wheelOfFortuneSnapshot.parameters.redirectionSettings.platforms.some(
                        (platformSnapshot) =>
                            platform.platformKey === platformSnapshot.platformKey && platform.order === platformSnapshot.order
                    )
            )
        );
    }

    private _initializeWheelOfFortuneWithPreviousData(wheelOfFortuneDto: WheelOfFortuneDto): void {
        this.globalSettings.update((globalSettings) => ({
            ...globalSettings,
            giftClaimStartDateOption: wheelOfFortuneDto.parameters.giftClaimStartDateOption || globalSettings.giftClaimStartDateOption,
            giftClaimDurationInDays: wheelOfFortuneDto.parameters.giftClaimDurationInDays || globalSettings.giftClaimDurationInDays,
            redirectionSettings: wheelOfFortuneDto.parameters.redirectionSettings || globalSettings.redirectionSettings,
        }));

        this.primaryColor.set(wheelOfFortuneDto.parameters.primaryColor);
        this.secondaryColor.set(wheelOfFortuneDto.parameters.secondaryColor);
        if (wheelOfFortuneDto.parameters.media) {
            this.media.set(new Media(wheelOfFortuneDto.parameters.media));
        }

        if (wheelOfFortuneDto.gifts.length > 0) {
            this.gifts.set(wheelOfFortuneDto.gifts.map((gift) => new Gift(gift)));
        }
    }
}
