import { ChangeDetectionStrategy, Component, Inject, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

export enum CloseWithoutSavingAction {
    CANCELLED = 'cancelled',
    CONFIRMED = 'confirmed',
}

@Component({
    selector: 'app-close-without-saving-modal',
    templateUrl: './close-without-saving-modal.component.html',
    styleUrls: ['./close-without-saving-modal.component.scss'],
    standalone: true,
    imports: [MatButtonModule, TranslateModule, IllustrationPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseWithoutSavingModalComponent {
    readonly onCancel = output<void>();
    readonly onConfirm = output<void>();

    readonly isOpenedAsModal = signal<boolean>(false);

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            isOpenedAsModal: boolean;
        },
        private readonly _dialogRef: MatDialogRef<CloseWithoutSavingModalComponent>
    ) {
        this.isOpenedAsModal.set(data.isOpenedAsModal);
    }

    onCancelFn(): void {
        if (this.isOpenedAsModal()) {
            return this.close({ action: CloseWithoutSavingAction.CANCELLED });
        }
        this.onCancel.emit();
    }

    onConfirmFn(): void {
        if (this.isOpenedAsModal()) {
            return this.close({ action: CloseWithoutSavingAction.CONFIRMED });
        }
        this.onConfirm.emit();
    }

    close(data): void {
        this._dialogRef.close(data);
    }
}
