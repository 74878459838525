import { InsightsChart, PlatformKey } from '@malou-io/package-utils';

import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';

export interface DownloadInsightsParams {
    displayedCharts: InsightsChart[];
    chartOptions?: ChartOptions;
    platformKeys?: PlatformKey[];
    restaurantIds?: string[];
    nfcIds?: string[];
    startDate: Date;
    endDate: Date;
    timeZone: string;
}

export function parseInsightsRouteParams(): DownloadInsightsParams {
    const queryParams = localStorage.getItem('params');
    if (!queryParams) {
        throw new Error('No pdf params found in local storage');
    }

    const parsedQueryParams = JSON.parse(queryParams);
    const { startDate, endDate } = parsedQueryParams;

    return {
        ...parsedQueryParams,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
    };
}
