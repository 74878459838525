import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, signal, TemplateRef } from '@angular/core';

import { TooltipPosition } from '@malou-io/package-utils';

const showHideTooltipAnimation = trigger('tooltipState', [
    state('hidden', style({ opacity: 0 })),
    state('visible', style({ opacity: 1 })),
    transition('hidden <=> visible', animate('200ms ease-in-out')),
]);

@Component({
    selector: 'app-custom-tooltip',
    standalone: true,
    imports: [NgClass, NgStyle, NgTemplateOutlet],
    templateUrl: './custom-tooltip.component.html',
    styleUrl: './custom-tooltip.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [showHideTooltipAnimation],
})
export class CustomTooltipComponent {
    // can't be readonly inputs signals because we need to assign them in the directive
    @Input() content: TemplateRef<any> | string;
    @Input() position: TooltipPosition = TooltipPosition.TOP;

    readonly isVisible = signal<boolean>(false);
    readonly top = signal<number>(0);
    readonly left = signal<number>(0);

    readonly animationState = computed(() => (this.isVisible() ? 'visible' : 'hidden'));

    show(): void {
        this.isVisible.set(true);
    }

    hide(): void {
        this.isVisible.set(false);
    }

    setPosition(target: HTMLElement): void {
        const rect = target.getBoundingClientRect();
        const tooltipRect = this._calculateTooltipSize();

        switch (this.position) {
            case TooltipPosition.TOP:
                this.top.set(rect.top - tooltipRect.height - 10);
                this.left.set(rect.left + rect.width / 2 - tooltipRect.width / 2);
                break;
            case TooltipPosition.BOTTOM:
                this.top.set(rect.bottom + 10);
                this.left.set(rect.left + rect.width / 2 - tooltipRect.width / 2);
                break;
            case TooltipPosition.LEFT:
                this.top.set(rect.top + rect.height / 2 - tooltipRect.height / 2);
                this.left.set(rect.left - tooltipRect.width - 10);
                break;
            case TooltipPosition.RIGHT:
                this.top.set(rect.top + rect.height / 2 - tooltipRect.height / 2);
                this.left.set(rect.right + 10);
                break;
        }
    }

    private _calculateTooltipSize(): DOMRect {
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.pointerEvents = 'none';
        tempDiv.innerText = typeof this.content === 'string' ? this.content : '';
        document.body.appendChild(tempDiv);
        const rect = tempDiv.getBoundingClientRect();
        document.body.removeChild(tempDiv);
        return rect;
    }

    isTemplate(value: any): value is TemplateRef<any> {
        return value instanceof TemplateRef;
    }
}
