import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    runInInjectionContext,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { intersection } from 'lodash';
import { map } from 'rxjs';

import { WHEEL_OF_FORTUNE_NEEDED_ROLES } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { HowWheelOfFortuneWorksComponent } from ':shared/components/how-wheel-of-fortune-works/how-wheel-of-fortune-works.component';
import { DisableIfMissingCaslRoleDirective } from ':shared/directives/disable-if-missing-casl-role.directive';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { Restaurant } from ':shared/models';
import { EmptyGiftData, WheelOfFortune, WheelOfFortuneType } from ':shared/models/wheel-of-fortune';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { selectOwnRestaurants } from '../../restaurant-list/restaurant-list.reducer';
import { selectUserRestaurants } from '../../user/store/user.selectors';
import { GiftOutOfStockModalComponent } from '../gift-out-of-stock-modal/gift-out-of-stock-modal.component';
import {
    CloseModalProps,
    UpsertWheelOfFortuneModalComponent,
} from '../upsert-wheel-of-fortune-modal/upsert-wheel-of-fortune-modal.component';
import { WheelsOfFortuneService } from '../wheels-of-fortune.service';
import {
    CreateAggregatedOrRestaurantWheelOfFortuneModalComponent,
    CreateAggregatedOrRestaurantWheelOfFortuneOutput,
} from './create-aggregated-or-restaurant-wheel-of-fortune-modal/create-aggregated-or-restaurant-wheel-of-fortune-modal.component';

@Component({
    selector: 'app-create-wheel-of-fortune-card',
    templateUrl: './create-wheel-of-fortune-card.component.html',
    styleUrls: ['./create-wheel-of-fortune-card.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        HowWheelOfFortuneWorksComponent,
        DisableIfMissingCaslRoleDirective,
        ImagePathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateWheelOfFortuneCardComponent implements OnInit {
    @Input() restaurantsIdsWithoutWheels: Signal<string[]> = signal([]);
    @Input() isAggregatedView: boolean;
    @Input() activeAggregatedWheelOfFortune: WritableSignal<WheelOfFortune | null> = signal(null);
    @Input() hasBoosterPackActivated: Signal<boolean>;

    @Output() reloadWheelsOfFortune: EventEmitter<void> = new EventEmitter();

    readonly WHEEL_OF_FORTUNE_NEEDED_ROLES = WHEEL_OF_FORTUNE_NEEDED_ROLES;

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });
    readonly localBusinessRestaurantIdsWithoutWheel = computed(() => {
        const restaurantIds = this._ownRestaurants()
            .filter((restaurant) => !restaurant.isBrandBusiness())
            .map((restaurant) => restaurant._id);
        return intersection(restaurantIds, this.restaurantsIdsWithoutWheels());
    });
    readonly canCreateWheelOfFortune = computed(() => (isDisabled: boolean): boolean => {
        if (isDisabled) {
            return false;
        }
        if (this.isAggregatedView) {
            return this.localBusinessRestaurantIdsWithoutWheel().length > 0;
        }
        if (this.restaurantsIdsWithoutWheels().length === 0) {
            return false;
        }
        return true;
    });
    readonly currentRestaurant = toSignal(this._restaurantsService.restaurantSelected$, { initialValue: null });
    readonly createWheelOfFortuneTooltip = computed(() => (isDisabled: boolean): string => {
        if (this.canCreateWheelOfFortune()(isDisabled)) {
            return '';
        }
        if (isDisabled) {
            return this._translateService.instant('casl.wrong_role');
        }
        if (this.isAggregatedView) {
            return this._translateService.instant('wheel_of_fortune.create_wheel_card.all_already_have_wof');
        }
        return '';
    });

    readonly requestSent = signal(false);
    readonly sendingRequest = signal(false);
    private readonly _ownRestaurants: Signal<Restaurant[]> = toSignal(this._store.select(selectOwnRestaurants), { initialValue: [] });
    private readonly _restaurants: Signal<Restaurant[]> = computed(() =>
        this._ownRestaurants() && this.isAggregatedView
            ? this._ownRestaurants().filter((restaurant) => !restaurant.boosterPack?.activated)
            : [this._restaurantsService.currentRestaurant]
    );
    private readonly _restaurantCount = toSignal(this._store.select(selectUserRestaurants).pipe(map((restaurants) => restaurants.length)), {
        initialValue: 0,
    });

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translateService: TranslateService,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        private readonly _toastService: ToastService,
        private readonly _injector: Injector,
        private readonly _router: Router,
        private readonly _store: Store
    ) {
        const subscriptionRequestSentRestaurantIds = JSON.parse(
            localStorage.getItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS) || '[]'
        );
        this.requestSent.set(this._restaurants().every((restaurant) => subscriptionRequestSentRestaurantIds.includes(restaurant.id)));
    }

    ngOnInit(): void {
        runInInjectionContext(this._injector, () => {
            effect(() => {
                const activeAggregatedWheelOfFortune = this.activeAggregatedWheelOfFortune && this.activeAggregatedWheelOfFortune();
                if (activeAggregatedWheelOfFortune) {
                    const emptyGiftData: EmptyGiftData | null = activeAggregatedWheelOfFortune.getFirstGiftWithEmptyStock();
                    if (emptyGiftData) {
                        this._openEmptyGiftStockModal({ ...emptyGiftData });
                    }
                }
            });
        });
    }

    openWheelOfFortuneModal(forceOpen: boolean): void {
        if (!forceOpen && !this.isAggregatedView && this._restaurantCount() > 1) {
            const doNotShowAgainRestaurantIds = JSON.parse(
                localStorage.getItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_CREATE_RESTAURANT_WHEEL_CONFIRMATION) || '[]'
            );
            if (
                !this._restaurantsService.currentRestaurant.isBrandBusiness() &&
                !doNotShowAgainRestaurantIds.includes(this._restaurantsService.currentRestaurant._id)
            ) {
                return this._openCreateAggregatedOrRestaurantWheelOfFortune();
            }
        }
        this._customDialogService
            .open<UpsertWheelOfFortuneModalComponent, { isAggregatedView: boolean; isAggregatedWheelOfFortune: boolean }, CloseModalProps>(
                UpsertWheelOfFortuneModalComponent,
                {
                    width: '100%',
                    panelClass: 'malou-dialog-panel--full',
                    height: undefined,
                    data: {
                        isAggregatedView: this.isAggregatedView,
                        isAggregatedWheelOfFortune: this.isAggregatedView,
                    },
                }
            )
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result?.redirectUrl) {
                        this._router.navigate(result.redirectUrl.path, result.redirectUrl.extras);
                    }
                    if (result?.shouldReload) {
                        this.reloadWheelsOfFortune.emit();
                    }
                },
            });
    }

    sendRequest(): void {
        this.sendingRequest.set(true);

        this._wheelsOfFortuneService.sendSubscriptionRequest(this._restaurants()).subscribe(() => {
            this._toastService.openSuccessToast(
                this._translateService.instant('wheel_of_fortune.subscription_request_modal.request_successfully_sent')
            );
            this.setRequestSent();
        });
    }

    setRequestSent(): void {
        const subscriptionRequestSentRestaurantIds = JSON.parse(
            localStorage.getItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS) || '[]'
        );
        this._restaurants().forEach((restaurant) => {
            subscriptionRequestSentRestaurantIds.push(restaurant.id);
        });
        const uniqSubscriptionRequestSentRestaurantIds = Array.from(new Set(subscriptionRequestSentRestaurantIds));
        const uniqSubscriptionRequestSentRestaurantIdsString = JSON.stringify(uniqSubscriptionRequestSentRestaurantIds);
        localStorage.setItem(LocalStorageKey.SUBSCRIPTION_REQUEST_SENT_RESTAURANT_IDS, uniqSubscriptionRequestSentRestaurantIdsString);
    }

    private _openEmptyGiftStockModal({ stockId, gift }: EmptyGiftData): void {
        this._pushGiftStockZeroInLocalStorage(stockId);
        this._customDialogService
            .open(GiftOutOfStockModalComponent, {
                height: 'fit-content',
                width: '550px',
                data: {
                    giftName: gift.name,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (!result) {
                        return;
                    }
                    if (result.shouldRedirect) {
                        this._router.navigate(['/groups/boosters/wheels-of-fortune'], {
                            queryParams: { giftId: gift.id },
                        });
                    }
                },
            });
    }

    private _pushGiftStockZeroInLocalStorage(stockId: string): void {
        const stockIds: string[] = JSON.parse(localStorage.getItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_EMPTY_STOCK_MODAL) || '[]');
        stockIds.push(stockId);
        localStorage.setItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_EMPTY_STOCK_MODAL, JSON.stringify(stockIds));
    }

    private _openCreateAggregatedOrRestaurantWheelOfFortune(): void {
        this._customDialogService
            .open<
                CreateAggregatedOrRestaurantWheelOfFortuneModalComponent,
                undefined,
                CreateAggregatedOrRestaurantWheelOfFortuneOutput | undefined
            >(CreateAggregatedOrRestaurantWheelOfFortuneModalComponent, {
                height: 'fit-content',
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (!result) {
                        return;
                    }
                    if (result.doNotShowAgain) {
                        this._pushRestaurantIdToDoNotShowAgainInLocalStorage();
                    }
                    switch (result.wheelOfFortuneType) {
                        case WheelOfFortuneType.AGGREGATED:
                            this._router.navigate(['/groups/boosters/wheels-of-fortune']);
                            break;
                        case WheelOfFortuneType.RESTAURANT:
                            this.openWheelOfFortuneModal(true);
                            break;
                    }
                },
            });
    }

    private _pushRestaurantIdToDoNotShowAgainInLocalStorage(): void {
        const restaurantIds = JSON.parse(
            localStorage.getItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_CREATE_RESTAURANT_WHEEL_CONFIRMATION) || '[]'
        );
        restaurantIds.push(this._restaurantsService.currentRestaurant._id);
        localStorage.setItem(LocalStorageKey.DO_NOT_SHOW_AGAIN_CREATE_RESTAURANT_WHEEL_CONFIRMATION, JSON.stringify(restaurantIds));
    }
}
