@if (medias().length === 0) {
    <ng-container [ngTemplateOutlet]="addFirstMediaTemplate"></ng-container>
} @else {
    TODO
}

<ng-template #addFirstMediaTemplate>
    <div class="rounded-[5px] border border-malou-color-border-primary bg-malou-color-background-light p-3">
        <div class="flex items-center gap-5 rounded-[5px] bg-malou-color-background-white p-4">
            <mat-icon class="!h-14 !w-14 !fill-malou-color-background-dark" [svgIcon]="SvgIcon.IMAGES"></mat-icon>
            <div class="flex flex-col gap-1">
                <div class="malou-text-11--semibold text-malou-color-text-1">
                    <span class="cursor-pointer text-malou-color-primary">{{ 'upload_and_edit_medias.add_media' | translate }}</span>
                    {{ 'upload_and_edit_medias.or_drag_and_drop' | translate }}
                </div>
                <div class="malou-text-10--regular italic text-malou-color-text-2">
                    {{ mediaRequirementsText() }}
                </div>
            </div>
        </div>
    </div>
</ng-template>
