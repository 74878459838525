import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import Uppy from '@uppy/core';
import { concat, isEqual, partition } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DragToSelectModule, SelectContainerComponent } from 'ngx-drag-to-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { asapScheduler, BehaviorSubject, combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { isNotNil } from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { FoldersService } from ':core/services/folders.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { RestaurantsSelectionModalComponent } from ':shared/components/restaurants-selection-modal/restaurants-selection-modal.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { SelectionModel } from ':shared/helpers/selection-model';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { GalleryFilters, IFolder, Media, Pagination, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { MediaService } from '../media/media.service';
import { GalleryActionsHeaderComponent } from './gallery-actions-header/gallery-actions-header.component';
import { GalleryFolderComponent } from './gallery-folder/gallery-folder.component';
import * as GalleryImportMediaActions from './gallery-import-media/gallery-import-media.actions';
import { selectCreatedMedia, selectFilesErrors } from './gallery-import-media/gallery-import-media.reducer';
import { GalleryImportMediaService } from './gallery-import-media/gallery-import-media.service';
import { GalleryMediaComponent } from './gallery-media/gallery-media.component';
import { selectGalleryFilters } from './gallery.reducer';
import { MoveMediaModalComponent } from './modals/move-media-modal/move-media-modal.component';
import { SharedFolderModalComponent } from './modals/shared-folder-modal/shared-folder-modal.component';

const DEFAULT_PAGINATION = { pageSize: 24, pageNumber: 0, total: 0 };
const DEFAULT_PAGE_EVENT = { pageSize: 24, pageIndex: 0, length: 0 };

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    standalone: true,
    imports: [
        ApplyPurePipe,
        ApplySelfPurePipe,
        AsyncPipe,
        DragToSelectModule,
        GalleryActionsHeaderComponent,
        GalleryFolderComponent,
        GalleryMediaComponent,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
        InfiniteScrollModule,
        LazyLoadImageModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatRippleModule,
        MatTooltipModule,
        NgClass,
        NgTemplateOutlet,
        ScrollToTopComponent,
        SkeletonComponent,
        SlideToggleComponent,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent implements OnInit, OnDestroy {
    @ViewChild(SelectContainerComponent) dtsContainer: SelectContainerComponent;
    readonly restaurant = signal<Restaurant | null>(null);
    readonly folders = signal<IFolder[]>([]);
    readonly medias = signal<Media[]>([]);
    completedFiles = 0;
    readonly totalFiles = signal(0);
    readonly hasFetchedMedia = signal(false);
    isFilterUp = true;

    pagination: Pagination = DEFAULT_PAGINATION;
    readonly pageEvent$: BehaviorSubject<PageEvent> = new BehaviorSubject(DEFAULT_PAGE_EVENT);
    readonly filters$: Observable<GalleryFilters> = this._store.select(selectGalleryFilters);
    readonly filters: WritableSignal<GalleryFilters | null> = signal(null);
    readonly isThereATitleFilter = computed(() => (this.filters()?.title?.length ?? 0) > 0);

    readonly uploadProgress$ = new BehaviorSubject(0);
    isDragging = false;
    loaderHeaderText = this._translate.instant('gallery.adding_media');
    currentUppyInstance: Uppy;

    filesErrors$ = this._store.select(selectFilesErrors);
    readonly filesErrors = toSignal(this.filesErrors$);

    readonly currentFolder: WritableSignal<IFolder | null> = signal(null);

    readonly DuplicationDestination = DuplicationDestination;

    readonly illustration = Illustration;
    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    readonly folderSelectionModel: SelectionModel<IFolder> = new SelectionModel((value) => value.id);
    readonly mediaSelectionModel: SelectionModel<Media> = new SelectionModel((value) => value.id);

    readonly hasSelectedMedias$: Observable<boolean> = this._hasSelectedMedias$();
    readonly hasSelectedFolders$: Observable<boolean> = this._hasSelectedFolders$();

    readonly isImportV2Enabled = computed(() => this._galleryImportMediaService.version() === 2);

    readonly SvgIcon = SvgIcon;

    private readonly _folderIdChanged$: BehaviorSubject<null | string> = new BehaviorSubject(null);

    private readonly _createdMedia$ = this._store.select(selectCreatedMedia);

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _customDialogService: CustomDialogService,
        private readonly _dialogService: DialogService,
        private readonly _galleryImportMediaService: GalleryImportMediaService,
        private readonly _foldersService: FoldersService,
        private readonly _mediaService: MediaService,
        private readonly _postsService: PostsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _router: Router,
        private readonly _spinnerService: SpinnerService,
        private readonly _store: Store,
        private readonly _toastService: ToastService,
        private readonly _translate: TranslateService,
        public readonly screenSizeService: ScreenSizeService,
        readonly experimentationService: ExperimentationService
    ) {
        this._activatedRoute.queryParams
            .pipe(
                filter((params) => params.sharedLink === 'true'),
                switchMap((params) => {
                    const currentFolderId = this.currentFolder()?.id || null;
                    const folderId = params.folderId || null;

                    return currentFolderId === folderId
                        ? of(this.currentFolder())
                        : this._foldersService.getFolderById(folderId).pipe(map((result) => result.data));
                }),
                switchMap((folder) =>
                    this._customDialogService
                        .open(SharedFolderModalComponent, {
                            height: 'unset',
                            data: { folder },
                        })
                        .afterClosed()
                )
            )
            .subscribe((result: { folder: IFolder | null }) => {
                if (result.folder) {
                    this._downloadAsZip({ medias: [], folders: [result.folder] });
                }
            });

        effect(() => {
            const currentFolderId = this.currentFolder()?.id || null;
            asapScheduler.schedule(() =>
                this._store.dispatch({ type: GalleryImportMediaActions.setCurrentFolderId.type, currentFolderId })
            );
        });
    }

    ngOnInit(): void {
        combineLatest([this._restaurantsService.restaurantSelected$, this._activatedRoute.queryParams])
            .pipe(
                filter(([restaurant]) => isNotNil(restaurant)),
                tap(([restaurant, queryParams]: [Restaurant, Params]) => {
                    this.hasFetchedMedia.set(false);
                    this.medias.set([]);
                    this.restaurant.set(restaurant);
                    this.pageEvent$.next(DEFAULT_PAGE_EVENT);
                    this._folderIdChanged$.next(queryParams.folderId ?? null);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe();

        combineLatest([this.pageEvent$, this.filters$, this._folderIdChanged$, this._restaurantsService.restaurantSelected$])
            .pipe(
                debounceTime(100),
                distinctUntilChanged(
                    (
                        [previousPageEvent, previousFilters, previousFolderId, previousRestaurant],
                        [currentPageEvent, currentFilters, currentFolderId, currentRestaurant]
                    ) =>
                        isEqual(previousPageEvent, currentPageEvent) &&
                        isEqual(previousFilters, currentFilters) &&
                        previousFolderId === currentFolderId &&
                        isEqual(previousRestaurant, currentRestaurant)
                ),
                switchMap(([pageEvent, filters, folderId]) => {
                    if (!isEqual(filters, this.filters())) {
                        this.medias.set([]);
                    }
                    this.filters.set(filters);
                    this.pagination.pageNumber = pageEvent.pageIndex;
                    this.pagination.pageSize = pageEvent.pageSize;
                    if (this.medias().length === 0) {
                        this.pagination = DEFAULT_PAGINATION;
                    }

                    const currentFolderId = this.currentFolder()?.id ?? null;
                    const restaurantId = this.restaurant()?._id;

                    if (!restaurantId) {
                        return throwError(() => 'Restaurant undefined');
                    }

                    const folders$ = this._foldersService
                        .getRestaurantFolders(restaurantId, folderId, filters.isNeverUsed, this.filters()?.title)
                        .pipe(map((res) => res.data));

                    const currentFolder$ = this._getCurrentFolder(folderId, currentFolderId);

                    const mediaWithPagination$ = this._mediaService
                        .getRestaurantMediasPaginated(restaurantId, this.pagination, filters, folderId)
                        .pipe(map((res) => res.data));

                    return forkJoin([folders$, mediaWithPagination$, currentFolder$]);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ([folders, { medias, pagination }, currentFolder]) => {
                    this.hasFetchedMedia.set(true);
                    this.medias.update((currentMedia) => [...currentMedia, ...medias]);
                    this.pagination = pagination;
                    this.folders.set(this._sortFolders(folders));
                    this.currentFolder.set(currentFolder);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });

        this._createdMedia$.subscribe((createdMedias) => {
            const currentFolderId = this.currentFolder()?.id || null;
            const mediaToAddToCurrentFolder = createdMedias.filter((media) => media.folderId === currentFolderId);

            this.medias.update((currentMedia) => [...currentMedia, ...mediaToAddToCurrentFolder]);
            this.mediaSelectionModel.select(mediaToAddToCurrentFolder);
            this.pagination.total = this.pagination.total + mediaToAddToCurrentFolder.length;
            this.sortMedias();
        });

        this._store.dispatch({ type: GalleryImportMediaActions.setIsGalleryOpen.type, isGalleryOpen: true });
    }

    toggleImportV2(enabled: boolean) {
        this._galleryImportMediaService.version.set(enabled ? 1 : 2);
    }

    ngOnDestroy(): void {
        this._store.dispatch({ type: GalleryImportMediaActions.setIsGalleryOpen.type, isGalleryOpen: false });
    }

    onScrollDown(): void {
        this.onPageEvent({
            pageIndex: this.pagination.pageNumber + 1,
            pageSize: this.pagination.pageSize,
            length: this.pagination.total,
        });
    }

    onPageEvent($event: PageEvent): void {
        this.pageEvent$.next($event);
    }

    sortOrderChanged(): void {
        this.isFilterUp = !this.isFilterUp;
        this.sortMedias();
    }

    sortMedias(): void {
        if (this.isFilterUp) {
            this.medias.update((currentMedia) => {
                currentMedia.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                return [...currentMedia];
            });
        } else {
            this.medias.update((currentMedia) => {
                currentMedia.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
                return [...currentMedia];
            });
        }
    }

    onOpenFolder(folderId: string | null = null): void {
        this.mediaSelectionModel.unselectAll();
        this.folderSelectionModel.unselectAll();

        const queryParams = folderId ? { folderId } : {};
        this._router.navigate(['./'], {
            relativeTo: this._activatedRoute,
            queryParams,
        });
    }

    onDownloadFolder(folder: IFolder): void {
        this._downloadAsZip({ medias: [], folders: [folder] });
    }

    onDeleteFolder(folder: IFolder): void {
        this._prepareDeleteMediaAndFolders({ folders: [folder] });
    }

    onDeleteMedia(media: Media): void {
        this._prepareDeleteMediaAndFolders({ medias: [media] });
    }

    onDuplicateFolder({ to, folder }: { to: DuplicationDestination; folder: IFolder }): void {
        const restaurantId = this.restaurant()?._id;
        if (!restaurantId) {
            return;
        }
        if (to === DuplicationDestination.HERE) {
            return this._duplicateMediasAndFolders({ foldersToDuplicate: [folder] }, [restaurantId], true);
        }
        return this.duplicateSelectedMediasAndFolders({ medias: [], folders: [folder] });
    }

    deleteSelectedMediaAndFolders(): void {
        this._prepareDeleteMediaAndFolders({
            medias: this.mediaSelectionModel.getSelection(),
            folders: this.folderSelectionModel.getSelection(),
        });
    }

    isMediaSelected = (media: Media): boolean => this.mediaSelectionModel.isSelected(media);

    isFolderSelected = (folder: IFolder): boolean => this.folderSelectionModel.isSelected(folder);

    onDragAndDropEnd(values: Media[] | IFolder): void {
        const [medias, folders] = partition(values, (value) => this._isMedia(value));
        this.mediaSelectionModel.select(medias);
        this.folderSelectionModel.select(folders);
    }

    onBackgroundContainerPointerDown(): void {
        this.folderSelectionModel.unselectAll();
        this.mediaSelectionModel.unselectAll();
    }

    onToggleSelectAllFolders(shouldSelect: boolean): void {
        if (shouldSelect) {
            this.folderSelectionModel.select(this.folders() ?? []);
        } else {
            this.folderSelectionModel.unselectAll();
        }
    }

    onToggleFolderSelection(folder: IFolder): void {
        this.folderSelectionModel.toggle(folder);
    }

    onToggleSelectAllMedia(shouldSelect: boolean): void {
        if (shouldSelect) {
            this.mediaSelectionModel.select(this.medias());
        } else {
            this.mediaSelectionModel.unselectAll();
        }
    }

    onMediaSelected({ event, media }: { event: MatCheckboxChange; media: Media }): void {
        event.checked ? this.mediaSelectionModel.select(media) : this.mediaSelectionModel.unselect(media);
    }

    onMediaContainerClick(media: Media): void {
        this.mediaSelectionModel.toggle(media);
    }

    onEditedMedia(media: Media): void {
        this.medias.update((currentMedia) => {
            const mediaIndex = currentMedia.findIndex((m) => m.id === media.id);
            currentMedia.splice(mediaIndex, 1, media);
            return [...currentMedia];
        });
    }

    onDuplicateMedia({ to, media }: { to: DuplicationDestination; media?: Media }): void {
        if (!media) {
            return;
        }
        const restaurantId = this.restaurant()?._id;
        if (!restaurantId) {
            return;
        }
        if (to === DuplicationDestination.HERE) {
            return this._duplicateMediasAndFolders({ mediasToDuplicate: [media] }, [restaurantId], true);
        }
        return this.duplicateSelectedMediasAndFolders({ medias: [media], folders: [] });
    }

    duplicateMultipleMediasAndFolders(destination: DuplicationDestination): void {
        const restaurantId = this.restaurant()?._id;
        if (!restaurantId) {
            return;
        }
        if (destination === DuplicationDestination.HERE) {
            return this._duplicateMediasAndFolders(
                {
                    mediasToDuplicate: this.mediaSelectionModel.getSelection(),
                    foldersToDuplicate: this.folderSelectionModel.getSelection(),
                },
                [restaurantId],
                true
            );
        }
        return this.duplicateSelectedMediasAndFolders({
            medias: this.mediaSelectionModel.getSelection(),
            folders: this.folderSelectionModel.getSelection(),
        });
    }

    duplicateSelectedMediasAndFolders({ medias, folders }: { medias: Media[]; folders: IFolder[] }): void {
        this._customDialogService
            .open(RestaurantsSelectionModalComponent, {
                width: '600px',
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result?.ids) {
                        const isOwnRestaurantIncluded = result.ids.includes(this.restaurant()?._id);
                        return this._duplicateMediasAndFolders(
                            { mediasToDuplicate: medias, foldersToDuplicate: folders },
                            result.ids,
                            isOwnRestaurantIncluded
                        );
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                },
            });
    }

    downloadMediasAndFoldersAsZip(): void {
        const selectedMedias = this.mediaSelectionModel.getSelection();
        const selectedFolders = this.folderSelectionModel.getSelection();

        this._downloadAsZip({ medias: selectedMedias, folders: selectedFolders });

        this.mediaSelectionModel.unselectAll();
        this.folderSelectionModel.unselectAll();
    }

    openFilePicker(): void {
        if (this.isImportV2Enabled()) {
            this._galleryImportMediaService.elementV2()?.openFilePicker();
        } else {
            this._galleryImportMediaService.elementV1()?.openFilePicker();
        }
    }

    onCreateFolder(folder: IFolder): void {
        this.folders.update((currentFolders) => {
            const newFolders = [...(currentFolders ?? []), folder];
            return this._sortFolders(newFolders);
        });
        this.folderSelectionModel.select(folder);
    }

    onFolderRenamed(folder: IFolder): void {
        this.folders.update((currentFolders) => {
            const folderIndex = currentFolders?.findIndex((currentFolder) => currentFolder.id === folder.id);
            if (folderIndex >= 0) {
                currentFolders[folderIndex].name = folder.name;
            }
            return [...currentFolders];
        });
    }

    onMediaRenamed(media: Media): void {
        this.medias.update((currentMedia) => {
            const mediumIndex = currentMedia.findIndex((currentMedium) => currentMedium.id === media.id);
            if (mediumIndex >= 0) {
                currentMedia[mediumIndex].name = media.name;
            }
            return [...currentMedia];
        });
    }

    onMoveSelectedMedia(): void {
        this.onMoveMedia(this.mediaSelectionModel.getSelection());
    }

    onMoveMedia(media: Media[]): void {
        const restaurantId = this.restaurant()?._id;
        if (!restaurantId) {
            return;
        }
        const folders$ = this.currentFolder()
            ? this._foldersService.getRestaurantFolders(restaurantId, null, false)
            : of({ data: this.folders() });
        folders$.subscribe((result) => {
            this._customDialogService
                .open(MoveMediaModalComponent, {
                    width: this.screenSizeService.isPhoneScreen ? '100%' : '600px',
                    height: 'unset',
                    data: { initSelectedFolder: this.currentFolder(), folders: result.data },
                })
                .afterClosed()
                .subscribe({
                    next: (folderId) => {
                        if (folderId !== undefined) {
                            this._moveMediaTowardsFolder(media, folderId);
                        }
                    },
                    error: (err) => {
                        console.warn('err :>>', err);
                    },
                });
        });
    }

    setEmptyFilesErrors(): void {
        this.filesErrors$ = of([]);
    }

    private _moveMediaTowardsFolder(medias: Media[], folderId: string | null): void {
        const mediaIds = medias.map((media) => media.id);
        this._mediaService.moveMediaTowardsFolder(mediaIds, folderId).subscribe({
            next: () => {
                const currentFolderId = this.currentFolder()?.id ?? null;

                if (folderId !== currentFolderId) {
                    this.medias.update((currentMedia) => currentMedia.filter((media) => !mediaIds.includes(media.id)));
                    this._incrementFolderMediaCount(folderId, medias.length);
                }
                this.mediaSelectionModel.unselectAll();
                this.folderSelectionModel.unselectAll();
                this._toastService.openSuccessToast(this._translate.instant('gallery.media_moved'));
            },
            error: (err) => {
                console.warn('err :>>', err);
                this.mediaSelectionModel.unselectAll();
                this.folderSelectionModel.unselectAll();
                this._toastService.openErrorToast(this._translate.instant('gallery.media_move_failed'));
            },
        });
    }

    private _incrementFolderMediaCount(folderId: string | null, incrementation: number): void {
        this.folders.update((folders) => {
            if (!folders) {
                return [];
            }
            const destinationFolderIndex = folders.findIndex((folder) => folder.id === folderId);
            if (destinationFolderIndex >= 0) {
                const destinationFolder = folders[destinationFolderIndex];
                folders[destinationFolderIndex] = {
                    ...destinationFolder,
                    mediaCount: destinationFolder.mediaCount + incrementation,
                };
            }
            return [...folders];
        });
    }

    private _prepareDeleteMediaAndFolders({
        medias,
        folders,
    }: { medias: Media[]; folders?: IFolder[] } | { medias?: Media[]; folders: IFolder[] }): void {
        const restaurantId = this.restaurant()?._id;
        if (!restaurantId) {
            return;
        }

        this._spinnerService.show();

        const mediaIds = medias?.map((media) => media.id);
        const folderIds = folders?.map((folder) => folder.id);

        const postsByMedia$ = mediaIds?.length ? this._postsService.findPostsByMedia(restaurantId, mediaIds) : of({ data: [] });
        const foldersPublishedMediaCount$ = folderIds?.length
            ? this._foldersService.getPublishedMediaCountInFolders(folderIds, restaurantId)
            : of(0);

        forkJoin([postsByMedia$, foldersPublishedMediaCount$]).subscribe({
            next: ([res, foldersPublishedMediaCount]) => {
                const relatedPosts = res.data;
                const atLeastOneMediumRelatedToAPost = relatedPosts.length + foldersPublishedMediaCount > 0;

                this._spinnerService.hide();

                this._dialogService.open({
                    illustration: Illustration.Cook,
                    variant: DialogVariant.INFO,
                    title: this._translate.instant('gallery.are_you_sure'),
                    message: this._buildWarningMessageForMediaAndFoldersDeletion(
                        !!mediaIds?.length,
                        !!folderIds?.length,
                        atLeastOneMediumRelatedToAPost
                    ),
                    primaryButton: {
                        label: this._translate.instant('common.delete'),
                        action: () => {
                            this._spinnerService.show();
                            this._deleteFoldersAndMedia(folderIds, mediaIds).subscribe(() => {
                                this._customDialogService.closeAll();
                                this._spinnerService.hide();
                            });
                        },
                    },
                    secondaryButton: {
                        label: this._translate.instant('common.cancel'),
                    },
                });
            },
            error: (err) => {
                console.warn('err :>>', err);
                this._spinnerService.hide();
            },
        });
    }

    private _sortFolders(folders: IFolder[]): IFolder[] {
        return folders.sort((folderA, folderB) => folderA.name.localeCompare(folderB.name));
    }

    private _duplicateMediasAndFolders(
        { mediasToDuplicate, foldersToDuplicate }: { mediasToDuplicate?: Media[]; foldersToDuplicate?: IFolder[] },
        restaurantsIds: string[],
        isOwnRestaurantIncluded = false
    ): void {
        const restaurantId = this.restaurant()?._id;
        if (!restaurantId) {
            return;
        }

        const mediasToDuplicateWithNewName: Media[] =
            mediasToDuplicate?.map(
                (media) =>
                    new Media({
                        ...media,
                        name: this._translate.instant('gallery.copy_of') + ' ' + (media?.name ?? `${media.title}.${media.format}`),
                    })
            ) ?? [];

        const duplicatedMedias = mediasToDuplicateWithNewName.length
            ? this._mediaService.duplicateMediaForRestaurants(restaurantId, mediasToDuplicateWithNewName, restaurantsIds)
            : of({ data: [] });

        const duplicatedFolders = foldersToDuplicate?.length
            ? this._foldersService.duplicateFoldersForRestaurants(restaurantId, foldersToDuplicate, restaurantsIds)
            : of({ data: [] });

        this._spinnerService.show();

        forkJoin([duplicatedMedias, duplicatedFolders]).subscribe({
            next: ([mediasResult, foldersResult]) => {
                this.folderSelectionModel.unselectAll();

                if (isOwnRestaurantIncluded) {
                    if (mediasResult.data.length) {
                        this.medias.update((currentMedia) => [...mediasResult.data, ...currentMedia]);
                        this.mediaSelectionModel.select(mediasResult.data);
                    }
                    if (foldersResult.data.length) {
                        this.folders.update((currentFolders) => {
                            const newFolders = currentFolders ? concat(foldersResult.data, currentFolders) : foldersResult.data;
                            return this._sortFolders(newFolders);
                        });
                        this.folderSelectionModel.select(foldersResult.data);
                    }
                }
                this._spinnerService.hide();
                this._toastService.openSuccessToast(
                    restaurantsIds.length > 1 && foldersResult.data.length === 0
                        ? this._translate.instant('gallery.duplication_succeeded_for_restaurants')
                        : this._translate.instant('gallery.duplication_succeeded')
                );
            },
            error: (err) => {
                this._spinnerService.hide();
                if (err.status === 403) {
                    return;
                }
                this.folderSelectionModel.unselectAll();
                this._toastService.openErrorToast(this._translate.instant('gallery.duplication_failed'));
            },
        });
    }

    private _deleteFoldersAndMedia(folderIds?: string[], mediaIds?: string[]): Observable<boolean> {
        const deleteFolders$ = folderIds?.length ? this._foldersService.deleteFolders(folderIds) : of(true);
        const deleteMedia$ = mediaIds?.length ? this._mediaService.deleteMedia(mediaIds) : of(true);

        return forkJoin([deleteFolders$, deleteMedia$]).pipe(
            map(() => {
                if (mediaIds?.length) {
                    this.medias.update((currentMedia) => currentMedia.filter((m) => !mediaIds.includes(m.id)));
                }
                this.pagination.total = this.medias().length;
                this.mediaSelectionModel.unselectAll();
                this.folderSelectionModel.unselectAll();

                if (folderIds?.length) {
                    this.folders.update((currentFolders) => currentFolders.filter((folder) => !folderIds.includes(folder.id)));
                }

                const toastMessage = this._buildToastMessageAfterDeletionSuccess(mediaIds?.length ?? 0, folderIds?.length ?? 0);

                this._toastService.openSuccessToast(toastMessage);

                return true;
            }),
            catchError((err) => {
                if (err.status === 403) {
                    return of(false);
                }
                this.mediaSelectionModel.unselectAll();
                this.folderSelectionModel.unselectAll();
                const toastMessage = this._buildToastMessageAfterDeletionError(mediaIds?.length ?? 0, folderIds?.length ?? 0);
                this._toastService.openErrorToast(toastMessage);

                return of(false);
            })
        );
    }

    private _getCurrentFolder(folderId: string | null, currentFolderId: string | null): Observable<IFolder | null> {
        if (folderId === currentFolderId) {
            return of(this.currentFolder());
        }
        return folderId ? this._foldersService.getFolderById(folderId).pipe(map((res) => res.data)) : of(null);
    }

    private _buildWarningMessageForMediaAndFoldersDeletion(
        atLeastOneMediumToDelete: boolean,
        atLeastOneFolderToDelete: boolean,
        atLeastOneMediumRelatedToAPost: boolean
    ): string {
        if (atLeastOneMediumToDelete) {
            if (atLeastOneFolderToDelete) {
                return atLeastOneMediumRelatedToAPost
                    ? this._translate.instant('gallery.folders_with_media_related_to_posts')
                    : this._translate.instant('gallery.folders_and_media_will_be_deleted');
            } else {
                return atLeastOneMediumRelatedToAPost
                    ? this._translate.instant('gallery.media_related_to_posts')
                    : this._translate.instant('gallery.media_will_be_deleted');
            }
        } else {
            return atLeastOneMediumRelatedToAPost
                ? this._translate.instant('gallery.folders_with_media_related_to_posts')
                : this._translate.instant('gallery.folders_will_be_deleted');
        }
    }

    private _buildToastMessageAfterDeletionSuccess(mediaCount: number, folderCount: number): string {
        if (folderCount) {
            return mediaCount > 0
                ? this._translate.instant('gallery.media_and_folders_deleted')
                : this._translate.instant('gallery.folder_deleted');
        } else {
            return mediaCount > 1
                ? this._translate.instant('gallery.many_media_deleted')
                : this._translate.instant('gallery.media_deleted');
        }
    }

    private _buildToastMessageAfterDeletionError(mediaCount: number, folderCount: number): string {
        if (folderCount) {
            return folderCount + mediaCount > 1
                ? this._translate.instant('gallery.many_media_or_folders_not_deleted')
                : this._translate.instant('gallery.folder_not_deleted');
        } else {
            return mediaCount > 1
                ? this._translate.instant('gallery.many_media_not_deleted')
                : this._translate.instant('gallery.media_not_deleted');
        }
    }

    private _hasSelectedMedias$(): Observable<boolean> {
        return this.mediaSelectionModel.getCount$().pipe(
            map((selectedMediaCount) => selectedMediaCount > 0),
            takeUntilDestroyed(this._destroyRef)
        );
    }

    private _hasSelectedFolders$(): Observable<boolean> {
        return this.folderSelectionModel.getCount$().pipe(
            map((selectedFoldersCount) => selectedFoldersCount > 0),
            takeUntilDestroyed(this._destroyRef)
        );
    }

    private _downloadAsZip({ medias, folders }: { medias: Media[]; folders: IFolder[] }): void {
        const url = this._router.serializeUrl(
            this._router.createUrlTree(['download'], {
                queryParams: {
                    restaurant: this.restaurant()?.name,
                    restaurantId: this.restaurant()?._id,
                    mediaNames: medias.length > 0 ? medias.map((media) => media.getFullnameWithFormat()).join(',') : undefined,
                    mediaUrls: medias.length > 0 ? medias.map((media) => media.getMediaUrl()).join(',') : undefined,
                    folderIds: folders.length > 0 ? folders.map((folder) => folder.id).join(',') : undefined,
                },
            })
        );

        window.open(url, '_blank');
    }

    private _isMedia(value: Media | IFolder): value is Media {
        return value instanceof Media;
    }
}
