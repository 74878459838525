import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, signal, viewChild } from '@angular/core';

import { UploadDropZoneService } from ':modules/gallery/upload-drop-zone.service';

@Component({
    selector: 'app-upload-drop-zone',
    templateUrl: './upload-drop-zone.component.html',
    standalone: true,
    imports: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDropZoneComponent implements OnInit, OnDestroy {
    public readonly onFileDropped = signal<((file: File) => void) | null>(null);

    private readonly _div = viewChild<ElementRef<HTMLElement>>('div');

    constructor(private readonly _uploadDropZoneService: UploadDropZoneService) {}

    ngOnInit(): void {
        this._uploadDropZoneService.uploadDropZone.set(this);
    }

    ngOnDestroy(): void {
        this._uploadDropZoneService.uploadDropZone.set(null);
    }

    /**
     * @deprecated This function exists in order to be easily compatible with <app-uppy>
     * and <app-gallery-import-media> but I don’t think this is a great API. I think the
     * `onFileDropped` signal should be preferred.
     */
    nativeElement(): HTMLElement {
        const div = this._div();
        if (!div) {
            throw new Error('UploadDropZoneComponent: no <div>');
        }
        return div.nativeElement;
    }

    onDrop(event: DragEvent) {
        const onFileDropped = this.onFileDropped();
        if (!onFileDropped) {
            return;
        }

        event.preventDefault();
        const files: FileList = event.dataTransfer?.files as FileList;
        for (const file of Array.from(files)) {
            onFileDropped(file);
        }
    }
}
