import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { FileFormat } from '@malou-io/package-utils';

import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-upload-and-edit-medias',
    templateUrl: './upload-and-edit-medias.component.html',
    styleUrls: ['./upload-and-edit-medias.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, MatIconModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadAndEditMediasComponent {
    readonly medias = input.required<Media[]>();
    readonly maxMediaSizeInMo = input<number | undefined>();
    readonly mediaFormatAccepted = input<FileFormat[]>([]);

    private readonly _translateService = inject(TranslateService);

    readonly SvgIcon = SvgIcon;

    readonly mediaRequirementsText = computed((): string => {
        const requirements: string[] = [];

        const maxMediaSize = this.maxMediaSizeInMo();
        if (maxMediaSize) {
            const maxMediaSizeRequirement = this._translateService.instant('upload_and_edit_medias.requirements.max_media_size', {
                maxMediaSize,
            });
            requirements.push(maxMediaSizeRequirement);
        }

        const mediaFormatAccepted = this.mediaFormatAccepted();
        if (mediaFormatAccepted.length > 0) {
            const mediaFormatAcceptedRequirement = mediaFormatAccepted.join(', ');
            requirements.push(mediaFormatAcceptedRequirement);
        }

        return requirements.join(', ');
    });
}
