export enum ApplicationLanguage {
    FR = 'fr',
    EN = 'en',
    ES = 'es',
    IT = 'it',
}

export enum CountriesWithEvents {
    FR = 'FR',
    GB = 'GB',
    US = 'US',
    CA = 'CA',
    AE = 'AE',
    MA = 'MA',
    LU = 'LU',
    BE = 'BE',
    CH = 'CH',
}

export const APP_DEFAULT_LANGUAGE = ApplicationLanguage.FR;

export const mapApplicationLanguageToLocale = (lang: ApplicationLanguage): Locale => {
    switch (lang) {
        case ApplicationLanguage.EN:
            return Locale.EN;
        case ApplicationLanguage.FR:
            return Locale.FR;
        case ApplicationLanguage.IT:
            return Locale.IT;
        case ApplicationLanguage.ES:
            return Locale.ES;
        default:
            return Locale.FR;
    }
};

export const applicationLanguageDisplayName: Record<ApplicationLanguage | string, { en: string }> = {
    [ApplicationLanguage.FR]: {
        en: 'French',
    },
    [ApplicationLanguage.EN]: {
        en: 'English',
    },
    [ApplicationLanguage.ES]: {
        en: 'Spanish',
    },
    [ApplicationLanguage.IT]: {
        en: 'Italian',
    },
    af: { en: 'Afrikaans' },
    am: { en: 'Amharic' },
    ar: { en: 'Arabic' },
    az: { en: 'Azerbaijani' },
    be: { en: 'Belarusian' },
    bg: { en: 'Bulgarian' },
    bn: { en: 'Bengali' },
    bs: { en: 'Bosnian' },
    ca: { en: 'Catalan' },
    ceb: { en: 'Cebuano' },
    co: { en: 'Corsican' },
    cs: { en: 'Czech' },
    cy: { en: 'Welsh' },
    da: { en: 'Danish' },
    de: { en: 'German' },
    el: { en: 'Greek' },
    eo: { en: 'Esperanto' },
    et: { en: 'Estonian' },
    eu: { en: 'Basque' },
    fa: { en: 'Persian' },
    fi: { en: 'Finnish' },
    fy: { en: 'Frisian' },
    ga: { en: 'Irish' },
    gd: { en: 'Scots gaelic' },
    gl: { en: 'Galician' },
    gu: { en: 'Gujarati' },
    ha: { en: 'Hausa' },
    haw: { en: 'Hawaiian' },
    he: { en: 'Hebrew' },
    hi: { en: 'Hindi' },
    hmn: { en: 'Hmong' },
    hr: { en: 'Croatian' },
    ht: { en: 'Haitian creole' },
    hu: { en: 'Hungarian' },
    hy: { en: 'Armenian' },
    id: { en: 'Indonesian' },
    ig: { en: 'Igbo' },
    is: { en: 'Icelandic' },
    iw: { en: 'Hebrew' },
    ja: { en: 'Japanese' },
    jw: { en: 'Javanese' },
    ka: { en: 'Georgian' },
    kk: { en: 'Kazakh' },
    km: { en: 'Khmer' },
    kn: { en: 'Kannada' },
    ko: { en: 'Korean' },
    ku: { en: 'Kurdish (kurmanji)' },
    ky: { en: 'Kyrgyz' },
    la: { en: 'Latin' },
    lb: { en: 'Luxembourgish' },
    lo: { en: 'Lao' },
    lt: { en: 'Lithuanian' },
    lv: { en: 'Latvian' },
    mg: { en: 'Malagasy' },
    mi: { en: 'Maori' },
    mk: { en: 'Macedonian' },
    ml: { en: 'Malayalam' },
    mn: { en: 'Mongolian' },
    mr: { en: 'Marathi' },
    ms: { en: 'Malay' },
    mt: { en: 'Maltese' },
    my: { en: 'Myanmar (burmese)' },
    ne: { en: 'Nepali' },
    nl: { en: 'Dutch' },
    no: { en: 'Norwegian' },
    ny: { en: 'Chichewa' },
    or: { en: 'Odia' },
    pa: { en: 'Punjabi' },
    pl: { en: 'Polish' },
    ps: { en: 'Pashto' },
    pt: { en: 'Portuguese' },
    ro: { en: 'Romanian' },
    ru: { en: 'Russian' },
    sd: { en: 'Sindhi' },
    si: { en: 'Sinhala' },
    sk: { en: 'Slovak' },
    sl: { en: 'Slovenian' },
    sm: { en: 'Samoan' },
    sn: { en: 'Shona' },
    so: { en: 'Somali' },
    sq: { en: 'Albanian' },
    sr: { en: 'Serbian' },
    st: { en: 'Sesotho' },
    su: { en: 'Sundanese' },
    sv: { en: 'Swedish' },
    sw: { en: 'Swahili' },
    ta: { en: 'Tamil' },
    te: { en: 'Telugu' },
    tg: { en: 'Tajik' },
    th: { en: 'Thai' },
    tl: { en: 'Filipino' },
    tr: { en: 'Turkish' },
    ug: { en: 'Uyghur' },
    uk: { en: 'Ukrainian' },
    undetermined: { en: 'Undetermined' },
    ur: { en: 'Urdu' },
    uz: { en: 'Uzbek' },
    vi: { en: 'Vietnamese' },
    xh: { en: 'Xhosa' },
    yi: { en: 'Yiddish' },
    yo: { en: 'Yoruba' },
    'zh-CN': { en: 'Chinese (simplified)' },
    'zh-TW': { en: 'Chinese (traditional)' },
    zu: { en: 'Zulu' },
};

export enum Locale {
    EN = 'en',
    ES = 'es',
    FR = 'fr',
    IT = 'it',
}

export enum MaloupeLocale {
    EN = 'en',
    FR = 'fr',
}

export const mapMaloupeLocaleToLocale = (lang: MaloupeLocale): Locale => {
    switch (lang) {
        case MaloupeLocale.EN:
            return Locale.EN;
        case MaloupeLocale.FR:
        default:
            return Locale.FR;
    }
};

export const CGU_LINK = 'https://welcomehomemalou.notion.site/Consulter-les-CGU-dd62041f02ab459cb101d60c8546a62e';
export const MALOU_PRIVACY_POLICY_FR = 'https://welcomehomemalou.notion.site/FR-4b1d0788b1c848c78ca5bfa20eadbf48';
export const MALOU_PRIVACY_POLICY_EN = 'https://welcomehomemalou.notion.site/EN-a466f0380f604a78a0c46ba20e561612';

export enum MessageStatus {
    RECEIVED = 'RECEIVED',
    DELIVERED = 'DELIVERED',
    READ = 'READ',
    PENDING = 'PENDING',
    ERROR = 'ERROR',
}

export enum ConversationStatus {
    READ = 'READ',
    UNREAD = 'UNREAD',
}

export enum PushNotificationSubject {
    FEEDBACK = 'feedback',
    MESSAGE = 'message',
    REVIEW = 'review',
    POST = 'post',
    GLOBAL = 'global',
    REMINDER = 'reminder',
}

export enum Role {
    ADMIN = 'admin',
    MALOU_BASIC = 'malou-basic',
    MALOU_GUEST = 'malou-guest',
    MALOU_FREE = 'malou-free',
}

export enum CaslRole {
    ADMIN = 'admin',
    OWNER = 'owner',
    MODERATOR = 'moderator',
    EDITOR = 'editor',
    GUEST = 'guest',
}

export enum BusinessCategory {
    LOCAL_BUSINESS = 'local_business',
    BRAND = 'brand',
}

// https://developers.facebook.com/docs/graph-api/guides/error-handling/
export const facebookPageAccessTokenErrorCodes = Object.freeze([190, 460, 463]);

export enum FacebookPageCategory {
    DARK_KITCHEN = 'dark_kitchen', // Fb page with no location locations and parent_page
    BRAND = 'brand', // Fb page with stores (has a locations param)
    STORE = 'store', // Fb page linked to a brand parent page (has a parent_page param)
    INDEPENDANT_LOCATION = 'independant_location', // Fb page with location and no parent_page
}

export enum MaintenanceMode {
    UP = 'up',
    MAINTENANCE = 'maintenance',
}

export const trespassUsers = [
    'victor@malou.io',
    'waad@malou.io',
    'mathilde@malou.io',
    'fantin@malou.io',
    'solene@malou.io',
    'rafik@malou.io',
];

export enum BricksCategory {
    VENUE_TYPE = 'venueType',
    VENUE_CATEGORY = 'venueCategory',
    VENUE_SPECIAL = 'venueSpecial',
    VENUE_LOCATION = 'venueLocation',
    VENUE_LABEL = 'venueLabel',
    TOURISTIC_AREA = 'touristicArea',
    STATION = 'station',
    VENUE_OFFER = 'venueOffer',
    VENUE_EQUIPMENT = 'venueEquipment',
    VENUE_ATTRIBUTE = 'venueAttribute',
    VENUE_AUDIENCE = 'venueAudience',
    STANDARD = 'standard',
    RESTAURANT_NAME = 'restaurantName',
    REVIEWER_NAME = 'reviewerName',
}

export enum BricksAiCategory {
    VENUE_TYPE = 'venueType',
    VENUE_CATEGORY = 'venueCategory',
    VENUE_DISH = 'venueDish',
    VENUE_LOCATION = 'venueLocation',
    VENUE_SERVICE = 'venueService',
    VENUE_EQUIPMENT = 'venueEquipment',
    VENUE_CHARACTERISTICS = 'venueCharacteristics',
    VENUE_AUDIENCE = 'venueAudience',
    STANDARD = 'standard',
}

export const defaultHashtagCategories = Object.freeze({
    GENERAL: {
        fr: 'Général',
        en: 'General',
    },
    LOCATION: {
        fr: 'Localisation',
        en: 'Location',
    },
});

export enum CreatedBy {
    FORM_CUSTOMER = 'form_customer',
    FORM_MANAGER = 'form_manager',
}

export enum GeoSamplePlatform {
    GMAPS = 'gmaps',
}

export enum NfcType {
    STICKER = 'sticker',
    TOTEM = 'totem',
}

export enum PostedStatus {
    POSTED = 'posted',
    PENDING = 'pending',
    RETRY = 'retry',
    REJECTED = 'rejected',
}

export enum FilterType {
    REVIEWS = 'reviews',
    COMMENTS = 'comments',
    INSIGHTS = 'insights',
    PLATFORMS_INSIGHTS = 'platforms_insights',
    POSTS = 'posts',
    MEDIA = 'media',
    MENTIONS = 'mentions',
}

export const postsUpdateTexts = Object.freeze({
    GMB: ['Les horaires ont été mises à jour.', 'Hours were updated.'],
    FACEBOOK: [/a changé sa photo de/, /a actualisé son statut/, /updated their profile picture/, /updated their cover photo/],
});

export const facebookNewPhotoLegend = [
    /a une nouvelle photo/,
    /ha añadido una foto nueva/,
    /added a new photo/,
    /さんが写真を追加しました/,
];

export const Langs = Object.freeze({
    FR: { short: 'fr', full: 'french' },
    EN: { short: 'en', full: 'english' },
    ES: { short: 'es', full: 'spanish' },
    IT: { short: 'it', full: 'italian' },
});

export enum MediaCategory {
    PROFILE = 'profile',
    COVER = 'cover',
    ADDITIONAL = 'additional',
}

export enum FileFormat {
    PNG = 'png',
    JPEG = 'jpeg',
    JPG = 'jpg',
    MOV = 'mov',
    QUICKTIME = 'quicktime',
    MP4 = 'mp4',
}

export enum Civility {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other',
}

export enum ContactMode {
    EMAIL = 'email',
    SMS = 'sms',
}

export enum ClientSource {
    MANUAL = 'manual',
    MALOU = 'malou',
    ZENCHEF = 'zenchef',
    ZELTY = 'zelty',
    LAFOURCHETTE = 'lafourchette',
    PULP = 'pulp',
    WHEEL_OF_FORTUNE = 'wheel_of_fortune',
}

export enum CampaignType {
    REVIEW_BOOSTER = 'review_booster',
    PROMOTION = 'promotion',
}

export enum RedirectStar {
    FOUR_STARS = 'four_stars',
    FIVE_STARS = 'five_stars',
}

export enum ClientVariable {
    FIRSTNAME = '#CLIENT_FIRSTNAME#',
    LASTNAME = '#CLIENT_LASTNAME#',
}

// TODO = join with MediaType and use same word for photo/image
export enum SocialAttachmentsMediaTypes {
    IMAGE = 'image',
    VIDEO = 'video',
}

export enum MediaType {
    PHOTO = 'photo',
    VIDEO = 'video',
    FILE = 'file',
}

export enum InputMediaType {
    IMAGE = 'image',
    VIDEO = 'video',
}

export const coverDimensions = Object.freeze({
    cover: [1920, 1080],
    smallCover: [480, 270],
});

export enum DescriptionSize {
    LONG = 'long',
    SHORT = 'short',
}

export const descriptionSize = Object.freeze({
    LONG: {
        key: 'long',
        maxlength: 750,
    },
    SHORT: {
        key: 'short',
        maxlength: 100,
    },
});

export enum EmailType {
    ACCESS_UPDATE = 'access_update',
    AI_API_HARD_LIMIT_REACHED = 'ai_api_hard_limit_reached',
    AI_API_SOFT_LIMIT_REACHED = 'ai_api_soft_limit_reached',
    BOOSTER_PACK_SUBSCRIPTION_REQUEST = 'booster_pack_subscription_request',
    CLOSED_FEEDBACK = 'closed_feedback',
    CONFIRM_CREATE_ACCOUNT = 'confirm_create_account',
    CREATE_GUEST_ACCOUNT = 'create_guest_account',
    CREATE_PAGE = 'create_page',
    CREATE_RESTAURANT = 'create_restaurant',
    DOWNLOAD_MOBILE_APP = 'download_mobile_app',
    EMAIL_VERIFICATION = 'email_verification',
    EMPTY_STOCK = 'empty_stock',
    GIFT_EXPIRES_SOON = 'gift_expires_soon',
    NEW_FEEDBACK_MESSAGE = 'new_feedback_message',
    NEW_MESSAGE_RECEIVED = 'new_message_received',
    OPENED_FEEDBACK = 'opened_feedback',
    POST_LOCATION_EXPIRED = 'post_location_expired',
    RESET_PASSWORD = 'reset_password',
    REVIEW_BOOSTER = 'review_booster',
    REVIEW_BOOSTER_TEST = 'review_booster_test',
    REVIEW_REPLY = 'review_reply',
    RETRIEVE_GIFT = 'retrieve_gift',
    UPDATE = 'update',
    USER_REVOKED_FB_CONNECTION = 'user_revoked_fb_connection',
    WOF_LIVE_TOMORROW = 'wof_live_tomorrow',
    WRONG_PLATFORM_ACCESS = 'wrong_platform_access',
}

export enum EmailCategory {
    REVIEW_BOOSTER = 'review_booster',
    ADMIN_NOTIF = 'admin_notif',
    USER_NOTIF = 'user_notif',
    REVIEW_REPLY = 'review_reply',
    REVIEWS_REPORT = 'reviews_report',
    FEEDBACK_NOTIFICATION = 'feedback_notification',
    MESSAGES_NOTIFICATION = 'messages_notification',
    PERMISSIONS = 'permissions',
    POST_NOTIFICATION = 'post_notification',
    AI_NOTIFICATION = 'ai_notification',
    MOBILE_APP_NOTIFICATION = 'mobile_app_notification',
    WHEEL_OF_FORTUNE_NOTIFICATION = 'wheel_of_fortune_notification',
}

export enum PostPublicationStatus {
    PENDING = 'pending',
    PUBLISHED = 'published',
    REJECTED = 'rejected',
    DRAFT = 'draft',
    ERROR = 'error',
}

export enum PostType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    CAROUSEL = 'CAROUSEL',
    REEL = 'REEL',
}

export enum VerificationState {
    VERIFICATION_STATE_UNSPECIFIED = 'VERIFICATION_STATE_UNSPECIFIED',
    VERIFICATION_STATE_VERIFIED = 'VERIFICATION_STATE_VERIFIED',
    VERIFICATION_STATE_UNVERIFIED = 'VERIFICATION_STATE_UNVERIFIED',
    VERIFICATION_STATE_PENDING = 'VERIFICATION_STATE_PENDING',
    VERIFICATION_STATE_SUSPENDED_IN_GMB = 'VERIFICATION_STATE_SUSPENDED_IN_GMB',
}

export enum LaunchState {
    LAUNCH_STATE_UNSPECIFIED = 'LAUNCH_STATE_UNSPECIFIED',
    LAUNCH_STATE_LAUNCHED = 'LAUNCH_STATE_LAUNCHED',
    LAUNCH_STATE_UNLAUNCHED = 'LAUNCH_STATE_UNLAUNCHED',
    LAUNCH_STATE_PENDING = 'LAUNCH_STATE_PENDING',
    LAUNCH_STATE_REJECTED = 'LAUNCH_STATE_REJECTED',
    LAUNCH_STATE_SUSPENDED = 'LAUNCH_STATE_SUSPENDED',
    LAUNCH_STATE_PENDING_UNLAUNCH = 'LAUNCH_STATE_PENDING_UNLAUNCH',
    LAUNCH_STATE_INVALID_IN_GMB = 'LAUNCH_STATE_INVALID_IN_GMB',
}

export enum StoredInDBInsightsMetric {
    FOLLOWERS = 'followers',
    PLATFORM_RATING = 'platform_rating',
    EMAIL_CONTACTS = 'email_contacts',
    TEXT_MESSAGE_CLICKS = 'text_message_clicks',
    WEBSITE_CLICKS = 'website_clicks',
    IMPRESSIONS = 'impressions',
    POST_COUNT = 'post_count',
    PHONE_CALL_CLICKS = 'phone_call_clicks',
    BUSINESS_BOOKINGS = 'business_bookings',
    BUSINESS_FOOD_MENU_CLICKS = 'business_food_menu_clicks',
    DIRECTION_REQUESTS = 'direction_requests',
    BUSINESS_IMPRESSIONS_DESKTOP_MAPS = 'business_impressions_desktop_maps',
    BUSINESS_IMPRESSIONS_DESKTOP_SEARCH = 'business_impressions_desktop_search',
    BUSINESS_IMPRESSIONS_MOBILE_MAPS = 'business_impressions_mobile_maps',
    BUSINESS_IMPRESSIONS_MOBILE_SEARCH = 'business_impressions_mobile_search',
    BUSINESS_FOOD_ORDERS = 'business_food_orders',
    SHARES = 'shares',
    SAVES = 'saves',
}

export enum IgWebhookStoryInsightsMetric {
    TAPS_BACK = 'taps_back',
    EXITS = 'exits',
    REACH = 'reach',
    IMPRESSIONS = 'impressions',
    TAPS_FORWARD = 'taps_forward',
    REPLIES = 'replies',
}

export enum IgLiveStoryInsightsMetric {
    TAP_BACK = 'tap_back',
    TAP_EXIT = 'tap_exit',
    TAP_FORWARD = 'tap_forward',
    SWIPE_FORWARD = 'swipe_forward',
}

export enum MalouMetric {
    IMPRESSIONS = 'impressions',
    ENGAGEMENTS = 'engagements',
    POSTS = 'posts',
    FOLLOWERS = 'followers',
    PLATFORM_RATING = 'platform_rating',
    QUERIES_DIRECT = 'queries_direct',
    QUERIES_INDIRECT = 'queries_indirect',
    ACTIONS_WEBSITE = 'actions_website',
    ACTIONS_PHONE = 'actions_phone',
    ACTIONS_DRIVING_DIRECTIONS = 'actions_driving_directions',
    BUSINESS_IMPRESSIONS_DESKTOP_MAPS = 'business_impressions_desktop_maps',
    BUSINESS_IMPRESSIONS_DESKTOP_SEARCH = 'business_impressions_desktop_search',
    BUSINESS_IMPRESSIONS_MOBILE_MAPS = 'business_impressions_mobile_maps',
    BUSINESS_IMPRESSIONS_MOBILE_SEARCH = 'business_impressions_mobile_search',
    BUSINESS_FOOD_ORDERS = 'business_food_orders',
    REACH = 'reach',
    TAPS_FORWARD = 'taps_forward',
    TAPS_BACK = 'taps_back',
    TAPS_EXITS = 'taps_exits',
    REPLIES = 'replies',
    ACTIONS_BOOKING_CLICK = 'actions_booking',
    ACTIONS_MENU_CLICK = 'actions_menu',
}

export enum GmbPostsMetric {
    LOCAL_POST_VIEWS_SEARCH = 'LOCAL_POST_VIEWS_SEARCH',
    LOCAL_POST_ACTIONS_CALL_TO_ACTION = 'LOCAL_POST_ACTIONS_CALL_TO_ACTION',
}

export enum AggregationTimeScale {
    TOTAL = 'total',
    BY_DAY = 'by_day',
    BY_WEEK = 'by_week',
    BY_MONTH = 'by_month',
}

export const months = Object.freeze({
    fr: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    en: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
});

export const scopes = Object.freeze({
    FACEBOOK: [
        'pages_read_engagement',
        'pages_show_list',
        'pages_read_user_content',
        'pages_manage_metadata',
        'pages_manage_engagement',
        'read_insights',
        'pages_manage_posts',
        'pages_messaging',
        'business_management',
    ],
    INSTAGRAM: [
        'instagram_basic',
        'pages_show_list',
        'instagram_manage_comments',
        'pages_manage_metadata',
        'pages_read_engagement',
        'instagram_manage_insights',
        'instagram_content_publish',
        'instagram_manage_messages',
        'pages_messaging',
        'business_management',
    ],
    FACEBOOK_PAGE_SCOPE: [
        'pages_read_engagement',
        'pages_show_list',
        'pages_read_user_content',
        'pages_manage_metadata',
        'pages_manage_engagement',
        'pages_manage_posts',
        'pages_messaging',
    ],
    INSTAGRAM_PAGE_SCOPE: [
        'instagram_basic',
        'pages_show_list',
        'instagram_manage_comments',
        'pages_manage_metadata',
        'pages_read_engagement',
        'instagram_manage_insights',
        'instagram_content_publish',
        'instagram_manage_messages',
        'pages_messaging',
    ],
    UBEREATS: ['eats.report', 'eats.store', 'eats.store.status.write'],
    GMB: [
        'https://www.googleapis.com/auth/business.manage',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/businesscommunications',
    ],
});

export enum PermissionStatus {
    NOT_CONNECTED = 'not_connected',
    NO_ACCESS = 'no_access',
    AVAILABLE = 'available',
}

export const defaultLabels = Object.freeze([
    'moderation.answer_comment_modal.good_luck',
    'moderation.answer_comment_modal.come_back_soon',
    'moderation.answer_comment_modal.thank_you',
    'moderation.answer_comment_modal.thanks',
    'moderation.answer_comment_modal.your_welcome',
]);

export const eventsPermissions = Object.freeze([
    {
        key: 'canCreatePostInstagram',
        permission: {
            type: 'USER',
            platformKey: 'instagram',
            list: [
                ['instagram_basic', 'instagram_content_publish', 'public_profile', 'pages_read_engagement'],
                ['instagram_basic', 'instagram_content_publish', 'public_profile', 'pages_show_list'],
            ],
        },
    },
    {
        key: 'canCreatePostFacebook',
        permission: {
            type: 'PAGE',
            platformKey: 'facebook',
            list: [['pages_read_engagement', 'pages_manage_posts', 'pages_show_list', 'public_profile']],
        },
    },
    {
        key: 'canAnswerCommentInstagram',
        permission: {
            type: 'USER',
            platformKey: 'instagram',
            list: [['instagram_basic', 'instagram_manage_comments', 'pages_show_list', 'pages_read_engagement', 'public_profile']],
        },
    },
    {
        key: 'canAnswerCommentFacebook',
        permission: {
            type: 'PAGE',
            platformKey: 'facebook',
            list: [['pages_show_list', 'pages_read_user_content', 'pages_manage_engagement', 'public_profile']],
        },
    },
    {
        key: 'canAnswerReviewFacebook',
        permission: {
            type: 'PAGE',
            platformKey: 'facebook',
            list: [['pages_show_list', 'pages_read_user_content', 'pages_manage_engagement', 'public_profile']],
        },
    },
    {
        key: 'canSearchInspirationsInstagram',
        permission: {
            type: 'USER',
            platformKey: 'instagram',
            list: [
                ['instagram_basic', 'pages_read_engagement', 'public_profile', 'instagram_manage_insights'],
                ['instagram_basic', 'pages_show_list', 'public_profile', 'instagram_manage_insights'],
            ],
        },
    },
]);

export enum IgStoryType {
    REPLY_TO = 'reply_to',
    MENTION = 'mention',
}

export const igMessageReaction = 'love';

export enum FacebookMessageType {
    MESSAGE_TAG = 'MESSAGE_TAG',
    UPDATE = 'UPDATE',
    RESPONSE = 'RESPONSE',
}

export enum FacebookThumbUp {
    ReplaceText = '__THUMB_UP__',
    FbSocialId = '369239263222822',
}

export enum TemplateType {
    REVIEW = 'review',
    COMMENT = 'comment',
    MESSAGE = 'message',
}

export enum TemplateStatus {
    ACTIVE = 'active',
    PENDING = 'pending',
}

export enum ApiKeyRole {
    BUSINESS_PARTNER = 'business_partner',
    CLIENT = 'client',
    PUBLIC = 'public',
}

export enum SimilarRestaurantCategory {
    ITALIAN_RESTAURANT = 'italian_restaurant',
    BRUNCH_BREAKFAST = 'brunch_breakfast',
    ASIAN_RESTAURANT = 'asian_restaurant',
    PIZZA_RESTAURANT = 'pizza_restaurant',
    BISTRO_PUB = 'bistro_pub',
    JAPANESE_RESTAURANT = 'japanese_restaurant',
    OCCIDENTAL_RESTAURANT = 'occidental_restaurant',
    BISTRONOMIC_RESTAURANT = 'bistronomic_restaurant',
    VEGETARIAN_VEGAN_RESTAURANT = 'vegetarian_vegan_restaurant',
    BAR_ENTERTAINMENT_VENUE = 'bar_entertainment_venue',
    COFFEE_TEA_ROOM = 'coffee_tea_room',
    GASTRONOMIC_RESTAURANT = 'gastronomic_restaurant',
    ORIENTAL_MEDITERRANEAN_RESTAURANT = 'oriental_mediterranean_restaurant',
    FISH_SEAFOOD_RESTAURANT = 'fish_seafood_restaurant',
    FAST_FOOD = 'fast_food',
    HEALTHY_FAST_FOOD = 'healthy_fast_food',
    CREPERIE = 'creperie',
    BAKERY = 'bakery',
    CATERER = 'caterer',
    GROCERY_MARKET = 'grocery_market',
    LATINO_AFRICAN_CARIBBEAN_RESTAURANT = 'latino_african_caribbean_restaurant',
    ACCOMMODATION_WELLNESS_VENUE = 'accommodation_wellness_venue',
    OTHER = 'other',
}

export const clientsFilesFormat = Object.freeze([
    {
        key: 'zenchef',
        headers: [
            'Prenom',
            'Nom',
            'Email',
            'Telephone',
            'Pays',
            'Statut destinataire',
            'Email optin market',
            'Date inscription mail',
            'SMS optin market',
            'Date inscription SMS',
            'Email optin review',
            'Date inscription avis par mail',
            'SMS review market',
            'Date inscription avis par SMS',
            'lang',
            'vip',
            'has_no_show',
            'is_new',
            'is_blacklisted',
            'retention_rate_label',
            'retention_rate',
            'has_opt_in_market_mail',
            'has_opt_in_review_mail',
            'created_at',
            'updated_at',
        ],
        required: ['Prenom', 'Nom', 'Telephone', 'Email'],
    },
    {
        key: 'pulp',
        headers: [''],
        required: [''],
    },
    {
        key: 'lafourchette',
        headers: [
            'Civilité',
            'Prénom',
            'Nom',
            'Pays',
            'Ville',
            'Code Postal',
            'Adresse',
            'Mobile Personnel',
            'Email personnel',
            'Langue',
            "Date d'inscription",
            'Newsletter',
            'Date de naissance',
            'Statut',
            'VIP',
            'Fax Personnel',
            "Complément d'adresse",
            'Prescripteur',
            'Commission négociée',
            "Nombre d'apport d'affaire total",
            "Chiffre d'affaire apporté total",
            'Entreprise',
            'Fonction',
            'Email Pro',
            'Mobile Pro',
            'Téléphone pro',
            'Pays Pro',
            'Adresse Pro',
            "Complément d'adresse Pro",
            'Ville Pro',
            'Allergie',
            'Nom Allergie',
            'Végétarien',
            'Handicap',
            'Préférence Nourriture',
            'Tables préférées',
            'Note',
            'Newsletter',
            'Restaurant de référence',
            'Date de mise à jour',
            "Date d'inscription",
            "Nombre d'annulation",
            'Nombre de no-show',
            'Total dépense',
        ],
        matchedHeaders: ['Dernière date de visite', 'Nombre de visites'],
        required: ['Prénom', 'Nom', 'Mobile Personnel', 'Email personnel'],
    },
    {
        key: 'zelty',
        headers: [
            'Nom',
            'Prénom',
            'N° de rue',
            'Ville',
            'Code postal',
            'Nom de rue',
            'N° de rue',
            'Téléphone',
            'Mail',
            "Date d'inscription",
            'Optin Mail',
            'CA actuel',
            'Optin SMS',
            'Statut',
            'Date de naissance',
            'Dernier restaurant',
            'Date de la dernière commande',
            'Nombre de commandes',
            'CA',
            'Points de fidélités',
            'Info interne',
            'Info client',
            "Complément d'adresse",
            'Batiment',
            'Porte',
            'Étage',
            'Digicode',
            'Identifiant externe',
            'Carte fidélité',
            'VIP',
            'Téléphone 2',
            'Entreprise',
            'ID',
        ],
        required: ['Nom', 'Prénom', 'Téléphone', 'Mail'],
    },
    {
        key: 'malou',
        headers: [
            'Civilité (H/F/NSP)',
            'Prénom',
            'Nom',
            'Pays',
            'Ville',
            'Code Postal',
            'Numéro de téléphone',
            'Email',
            'Langue parlée',
            'Date de la dernière réservation ou du click and collect',
            'Nombre de visites/réservations',
            'Accepte de recevoir des mails',
            'Accepte de recevoir des sms',
        ],
        required: ['Nom', 'Prénom', 'Numéro de téléphone', 'Email'],
    },
]);

export enum ClientFileError {
    EMPTY = 'empty_file',
    MISSING = 'missing_headers',
}

export enum SeoPostTopic {
    STANDARD = 'STANDARD',
    UNSPECIFIED = 'LOCAL_POST_TOPIC_TYPE_UNSPECIFIED',
    EVENT = 'EVENT',
    OFFER = 'OFFER',
    ALERT = 'ALERT',
}

export const ubereatsClientCredentials = {
    key: 'UBEREATS_CLIENT_CREDENTIALS',
    ttl: 2592000, // from ubereats doc: https://developer.uber.com/docs/eats/guides/authentication#example-response
};

export enum PrivatePlatforms {
    TOTEM = 'totem',
    CAMPAIGN = 'campaign',
}

export const hashtagCategoriesColor = [
    'rgb(219, 237, 219)',
    'rgb(227, 226, 224)',
    'rgb(238, 224, 218)',
    'rgb(250, 222, 201)',
    'rgb(253, 236, 200)',
    'rgb(211, 229, 239)',
    'rgb(232, 222, 238)',
    'rgb(245, 224, 233)',
    'rgb(255, 226, 221)',
];

export const hashtagGeneralColor = 'rgba(227, 226, 224, 0.5)';

export const emailRegex =
    // eslint-disable-next-line no-control-regex,max-len
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

export const timeRegex = /^(0[0-9]|1[0-9]|2[0-4]):[0-5][0-9]$/;

export const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,12}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;
export const urlWithLocalHostRegex =
    /https?:\/\/(((www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,12})|(localhost:[0-9]{1,5}))\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;

export const phoneRegex = /[\+]?[(]?\s?[\+]?\s?[0-9]{2,3}\s?[)]?[-\s\.]?(([0-9]{3}[-\s\.]?[0-9]{3,9})|(([0-9]{1,4}[-\s\.]?){7}))/gi;

export enum ReportType {
    DAILY_REVIEWS = 'daily_reviews',
    WEEKLY_REVIEWS = 'weekly_reviews',
    WEEKLY_PERFORMANCE = 'weekly_performance',
    MONTHLY_PERFORMANCE = 'monthly_performance',
}

export enum PostSource {
    SOCIAL = 'social',
    SEO = 'seo',
}

export const ratingTags = {
    restaurant_well_prepared: {
        fr: 'Bien préparé',
        en: 'Well prepared',
    },
    restaurant_perfectly_cooked: {
        fr: 'Cuisson parfaite',
        en: 'Perfectly cooked',
    },
    restaurant_sustainable_packaging: {
        fr: 'Conditionnement durable',
        en: 'Sustainable packaging',
    },
    restaurant_excellent_quality: {
        fr: 'Excellente qualité',
        en: 'Excellent quality',
    },
    restaurant_healthy: {
        fr: 'Healthy',
        en: 'Healthy',
    },
    restaurant_tasty: {
        fr: 'Savoureux',
        en: 'Tasty',
    },
    restaurant_fast: {
        fr: 'Rapide',
        en: 'Fast',
    },
    restaurant_accommodating: {
        fr: 'Accomodant',
        en: 'Accomodating',
    },
    restaurant_good_packaging: {
        fr: 'Bon emballage',
        en: 'Good packaging',
    },
    restaurant_excellent_value: {
        fr: 'Excellente valeur',
        en: 'Excellent value',
    },
    restaurant_not_tasty: {
        fr: 'Pas très savoureux',
        en: 'Not tasty',
    },
    restaurant_not_worth_price: {
        fr: 'Ne vaut pas le prix',
        en: 'Not worth price',
    },
    restaurant_missed_request: {
        fr: 'Instructions non respectées',
        en: 'Missed request',
    },
    restaurant_creative: {
        fr: 'Créatif',
        en: 'Creative',
    },
    restaurant_delicate: {
        fr: 'Délicat',
        en: 'Delicate',
    },
    restaurant_too_slow: {
        fr: 'Trop lent',
        en: 'Too slow',
    },
    restaurant_poor_packaging: {
        fr: 'Emballage médiocre',
        en: 'Poor packaging',
    },
    restaurant_unsustainable_packaging: {
        fr: 'Conditionnement non durable',
        en: 'Unsustainable packaging',
    },
    restaurant_crispy: {
        fr: 'Croustillant',
        en: 'Crispy',
    },
    restaurant_hot: {
        fr: 'Chaud',
        en: 'Hot',
    },
    restaurant_good_portions: {
        fr: 'Portions généreuses',
        en: 'Good portions',
    },
    restaurant_great_value: {
        fr: 'Consistant',
        en: 'Great value',
    },
    restaurant_honest: {
        fr: 'Honnête',
        en: 'Honest',
    },
    restaurant_the_best: {
        fr: 'Le meilleur',
        en: 'The best',
    },
    restaurant_professional: {
        fr: 'Professionnel',
        en: 'Professional',
    },
    restaurant_correct_order: {
        fr: 'Bonne commande',
        en: 'Correct order',
    },
};

export enum GmbNotificationType {
    GOOGLE_UPDATE = 'GOOGLE_UPDATE',
    NEW_REVIEW = 'NEW_REVIEW',
    UPDATED_REVIEW = 'UPDATED_REVIEW',
    NEW_CUSTOMER_MEDIA = 'NEW_CUSTOMER_MEDIA',
}

export enum GmbOpeningStatus {
    OPEN = 'OPEN',
    CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY',
}

export const SURFACE_UNSPECIFIED = 'SURFACE_UNSPECIFIED';

export const businessCommunications = {
    api: {
        baseUrl: 'https://businesscommunications.googleapis.com/v1/',
    },
    launchState: {
        LAUNCH_STATE_UNSPECIFIED: 'LAUNCH_STATE_UNSPECIFIED',
        LAUNCH_STATE_UNLAUNCHED: 'LAUNCH_STATE_UNLAUNCHED',
        LAUNCH_STATE_PENDING: 'LAUNCH_STATE_PENDING',
        LAUNCH_STATE_LAUNCHED: 'LAUNCH_STATE_LAUNCHED',
        LAUNCH_STATE_REJECTED: 'LAUNCH_STATE_REJECTED',
        LAUNCH_STATE_SUSPENDED: 'LAUNCH_STATE_SUSPENDED',
        LAUNCH_STATE_PENDING_UNLAUNCH: 'LAUNCH_STATE_PENDING_UNLAUNCH',
        LAUNCH_STATE_INVALID_IN_GMB: 'LAUNCH_STATE_INVALID_IN_GMB',
    },
    verificationState: {
        VERIFICATION_STATE_UNSPECIFIED: 'VERIFICATION_STATE_UNSPECIFIED',
        VERIFICATION_STATE_UNVERIFIED: 'VERIFICATION_STATE_UNVERIFIED',
        VERIFICATION_STATE_PENDING: 'VERIFICATION_STATE_PENDING',
        VERIFICATION_STATE_VERIFIED: 'VERIFICATION_STATE_VERIFIED',
        VERIFICATION_STATE_SUSPENDED_IN_GMB: 'VERIFICATION_STATE_SUSPENDED_IN_GMB',
    },
    SURFACE_UNSPECIFIED: 'SURFACE_UNSPECIFIED',
    entryPointConfigs: {
        LOCATION: 'LOCATION',
        ENTRY_POINT_UNSPECIFIED: 'ENTRY_POINT_UNSPECIFIED',
        NON_LOCAL: 'NON_LOCAL',
    },
    welcome_message: {
        fr: 'Bonjour, comment pouvons nous vous aider ?',
        en: 'Hello, how can we help you?',
    },
};
export enum CommentDisplayMode {
    POST = 'post',
    LIST = 'list',
}

export enum FeedbackMessageType {
    TEXT = 'text',
    CLOSE = 'close',
    REOPEN = 'reopen',
}

export enum FeedbackMessageVisibility {
    ADMIN = 'admin',
    BASIC = 'basic',
}

export enum FeedbackMessageParticipantType {
    AUTHOR = 'author',
    COMMENTATOR = 'commentator',
    TAGGED = 'tagged',
    OTHER = 'other',
}

export enum MentionType {
    POST = 'post',
    COMMENT = 'comment',
}

export enum TemplateTag {
    CLIENT_NAME = '[{client_name}]',
    BUSINESS_NAME = '[{business_name}]',
    MY_FIRSTNAME = '[{my_firstname}]',
    PHONE = '[{phone}]',
    ADDRESS = '[{address}]',
    MENU_LINK = '[{menu_link}]',
    WEBSITE = '[{website}]',
    REGULAR_HOURS = '[{regular_hours}]',
}

export enum MediaConvertedStatus {
    ERROR = 'error',
    COMPLETE = 'complete',
    PROGRESSING = 'progressing',
}

export const mediaConvertedStatusLabels = ['error', 'complete', 'progressing', null];

export enum DateStringFormat {
    FR = 'fr',
    EN = 'en',
}

export enum ReviewAnalysisSentiment {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    NEUTRAL = 'neutral',
}

export enum JobStatus {
    PENDING = 'pending',
    RUNNING = 'running',
    DONE = 'done',
    FAILED = 'failed',
}

export enum SemanticAnalysisProviderKey {
    OPENAI = 'openai',
}

export enum ReviewAnalysisTag {
    FOOD = 'food',
    SERVICE = 'service',
    PRICE = 'price',
    ATMOSPHERE = 'atmosphere',
    HYGIENE = 'hygiene',
    EXPEDITIOUSNESS = 'expeditiousness',
    OVERALL_EXPERIENCE = 'overall_experience',
}

export enum ReviewAnalysisStatus {
    DONE = 'done',
    PENDING = 'pending',
    FAILED = 'failed',
    REVIEW_TOO_OLD = 'review_too_old',
    UNSUPPORTED_PLATFORM = 'unsupported_platform',
    NO_RESULTS = 'no_results',
}

export enum SemanticAnalysisSentiment {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    NEUTRAL = 'neutral',
}

export enum PlatformPresenceStatus {
    NOT_FOUND = 'not_found', // means that the reviews was not found/deleted on the platform
    FOUND = 'found',
}

export enum FoundStatusOnPlatform {
    FOUND = 'found',
    NOT_FOUND = 'not_found',
}

export const adminCredentialAuthIds = ['gestionclients@malou.io', '232142364148787', '107323322263317', '122094862952058513'];

export const LANGUAGES = {
    'zh-CN': 'chinese (simplified)',
    'zh-TW': 'chinese (traditional)',
    af: 'afrikaans',
    am: 'amharic',
    ar: 'arabic',
    az: 'azerbaijani',
    be: 'belarusian',
    bg: 'bulgarian',
    bn: 'bengali',
    bs: 'bosnian',
    ca: 'catalan',
    ceb: 'cebuano',
    co: 'corsican',
    cs: 'czech',
    cy: 'welsh',
    da: 'danish',
    de: 'german',
    el: 'greek',
    en: 'english',
    eo: 'esperanto',
    es: 'spanish',
    et: 'estonian',
    eu: 'basque',
    fa: 'persian',
    fi: 'finnish',
    fr: 'french',
    fy: 'frisian',
    ga: 'irish',
    gd: 'scots gaelic',
    gl: 'galician',
    gu: 'gujarati',
    ha: 'hausa',
    haw: 'hawaiian',
    he: 'hebrew',
    hi: 'hindi',
    hmn: 'hmong',
    hr: 'croatian',
    ht: 'haitian creole',
    hu: 'hungarian',
    hy: 'armenian',
    id: 'indonesian',
    ig: 'igbo',
    is: 'icelandic',
    it: 'italian',
    iw: 'hebrew',
    ja: 'japanese',
    jw: 'javanese',
    ka: 'georgian',
    kk: 'kazakh',
    km: 'khmer',
    kn: 'kannada',
    ko: 'korean',
    ku: 'kurdish (kurmanji)',
    ky: 'kyrgyz',
    la: 'latin',
    lb: 'luxembourgish',
    lo: 'lao',
    lt: 'lithuanian',
    lv: 'latvian',
    mg: 'malagasy',
    mi: 'maori',
    mk: 'macedonian',
    ml: 'malayalam',
    mn: 'mongolian',
    mr: 'marathi',
    ms: 'malay',
    mt: 'maltese',
    my: 'myanmar (burmese)',
    ne: 'nepali',
    nl: 'dutch',
    no: 'norwegian',
    ny: 'chichewa',
    or: 'odia',
    pa: 'punjabi',
    pl: 'polish',
    ps: 'pashto',
    pt: 'portuguese',
    ro: 'romanian',
    ru: 'russian',
    sd: 'sindhi',
    si: 'sinhala',
    sk: 'slovak',
    sl: 'slovenian',
    sm: 'samoan',
    sn: 'shona',
    so: 'somali',
    sq: 'albanian',
    sr: 'serbian',
    st: 'sesotho',
    su: 'sundanese',
    sv: 'swedish',
    sw: 'swahili',
    ta: 'tamil',
    te: 'telugu',
    tg: 'tajik',
    th: 'thai',
    tl: 'filipino',
    tr: 'turkish',
    ug: 'uyghur',
    uk: 'ukrainian',
    ur: 'urdu',
    uz: 'uzbek',
    vi: 'vietnamese',
    xh: 'xhosa',
    yi: 'yiddish',
    yo: 'yoruba',
    zu: 'zulu',
};

export enum MapstrCtaButtonType {
    SEE = 'SEE',
    BOOK = 'BOOK',
    ORDER = 'ORDER',
    MENU = 'MENU',
    JOIN = 'JOIN',
    MORE = 'MORE',
}

export const AI_SOFT_LIMIT_CALL_COUNT = 1700;
export const AI_HARD_LIMIT_CALL_COUNT = 2000;

export enum NfcStar {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
}

/**
 * If you add a member to this enum you should update `malouErrorCodeToHttpStatus`.
 */
export enum MalouErrorCode {
    // GENERAL ERRORS
    BODY_VALIDATION_ERROR = 'BODY_VALIDATION_ERROR',
    QUERY_VALIDATION_ERROR = 'QUERY_VALIDATION_ERROR',
    PARAMS_VALIDATION_ERROR = 'PARAMS_VALIDATION_ERROR',
    INVALID_URL = 'INVALID_URL',

    // AGENDA
    AGENDA_DATABASE_CONNECTION_FAILED = 'AGENDA_DATABASE_CONNECTION_FAILED',

    // AI
    AI_FEATURE_NOT_ACTIVATED = 'AI_FEATURE_NOT_ACTIVATED',
    CREATE_COMPLETION_ERROR = 'CREATE_COMPLETION_ERROR',
    NOT_ENOUGH_CREDIT_TO_MAKE_AI_API_CALL = 'NOT_ENOUGH_CREDIT_TO_MAKE_AI_API_CALL',
    TEXT_TYPE_NOT_SUPPORTED = 'TEXT_TYPE_NOT_SUPPORTED',
    OPEN_AI_REQUEST_FAILED = 'OPEN_AI_REQUEST_FAILED',
    AI_REQUEST_FAILED = 'AI_REQUEST_FAILED',
    AI_INVALID_RESPONSE_FORMAT = 'AI_INVALID_RESPONSE_FORMAT',

    // API KEYS
    INVALID_API_KEY = 'INVALID_API_KEY',

    // ATTRIBUTES
    ATTRIBUTES_GMB_NOT_CONNECTED = 'ATTRIBUTES_GMB_NOT_CONNECTED',

    // AUTOMATIONS
    INCONSISTENT_RESTAURANT_ID = 'INCONSISTENT_RESTAURANT_ID',

    // CAMPAIGN
    CAMPAIGN_MISSING_QUERY_PARAM = 'CAMPAIGN_MISSING_QUERY_PARAM',
    CAMPAIGN_NOT_FOUND = 'CAMPAIGN_NOT_FOUND',
    CAMPAIGN_DUPLICATE_RECORD_ERROR = 'CAMPAIGN_DUPLICATE_RECORD_ERROR',

    // CASL
    CASL_UNKNOWN_ROLE = 'CASL_UNKNOWN_ROLE',
    CASL_USER_NOT_DEFINED = 'CASL_USER_NOT_DEFINED',

    // CLIENTS
    CLIENT_CANNOT_PARSE_FILE = 'CLIENT_CANNOT_PARSE_FILE',
    CLIENT_LANGUAGE_NOT_FOUND__REQUIRED_HEADERS = 'CLIENT_LANGUAGE_NOT_FOUND__REQUIRED_HEADERS',
    CLIENT_CANNOT_PARSE_DATA = 'CLIENT_CANNOT_PARSE_DATA',

    // COMMENTS
    COMMENT_INVALID_TYPE = 'COMMENT_INVALID_TYPE',
    COMMENT_NOT_FOUND = 'COMMENT_NOT_FOUND',

    // CREDENTIALS
    CREDENTIALS_USECASE_NOT_FOUND = 'CREDENTIAL_USECASE_NOT_FOUND',
    CREDENTIALS_PLATFORM_LOGIN_ERROR = 'CREDENTIALS_PLATFORM_LOGIN_ERROR',
    CREDENTIALS_MISSING_PARAM = 'CREDENTIALS_MISSING_PARAM',
    CREDENTIALS_INSTAGRAM_NOT_FOUND = 'CREDENTIALS_INSTAGRAM_NOT_FOUND',
    CREDENTIALS_FACEBOOK_NOT_FOUND = 'CREDENTIALS_FACEBOOK_NOT_FOUND',
    CREDENTIALS_FACEBOOK_ERROR = 'CREDENTIALS_FACEBOOK_ERROR',
    CREDENTIALS_FACEBOOK_PLATFORM_NOT_SUPPORTED = 'CREDENTIALS_FACEBOOK_PLATFORM_NOT_SUPPORTED',
    CREDENTIALS_FACEBOOK_MEDIA_NOT_SUPPORTED = 'CREDENTIALS_FACEBOOK_MEDIA_NOT_SUPPORTED',
    CREDENTIALS_FACEBOOK_CANNOT_FETCH_ACCOUNTS_AND_STORES = 'CREDENTIALS_FACEBOOK_CANNOT_FETCH_ACCOUNTS_AND_STORES',
    CREDENTIALS_GMB_REFRESH_TOKEN_EXPIRED = 'CREDENTIALS_GMB_REFRESH_TOKEN_EXPIRED',
    CREDENTIALS_GMB_CREDENTIAL_NOT_FOUND = 'CREDENTIALS_GMB_CREDENTIAL_NOT_FOUND',
    CREDENTIALS_GMB_API_ERROR = 'CREDENTIALS_GMB_API_ERROR',
    CREDENTIALS_GMB_API_DETAILS_ERROR = 'CREDENTIALS_GMB_API_DETAILS_ERROR',
    CREDENTIALS_GMB_POST_SCHEDULE_END_DATE_IN_PAST = 'CREDENTIALS_GMB_POST_SCHEDULE_END_DATE_IN_PAST',
    CREDENTIALS_GMB_API_DESCRIPTION_ERROR = 'CREDENTIALS_GMB_API_DESCRIPTION_ERROR',
    CREDENTIALS_PUPPETEER_OPENTABLE_COOKIE_ERROR = 'CREDENTIALS_PUPPETEER_OPENTABLE_COOKIE_ERROR',
    CREDENTIALS_PUPPETEER_OPENTABLE_OTUMAMIAUTH_COOKIE_NOT_FOUND = 'CREDENTIALS_PUPPETEER_OPENTABLE_OTUMAMIAUTH_COOKIE_NOT_FOUND',
    CREDENTIALS_TRIPADVISOR_COOKIE_NOT_FOUND = 'CREDENTIALS_TRIPADVISOR_COOKIE_NOT_FOUND',
    CREDENTIALS_TRIPADVISOR_UPDATE_COOKIE_FAILED = 'CREDENTIALS_TRIPADVISOR_UPDATE_COOKIE_FAILED',
    CREDENTIALS_UBEREATS_COOKIE_ERROR = 'CREDENTIALS_UBEREATS_COOKIE_ERROR',
    CREDENTIALS_UBEREATS_COOKIE_NOT_FOUND = 'CREDENTIALS_UBEREATS_COOKIE_NOT_FOUND',
    CREDENTIALS_UBEREATS_UPDATE_COOKIE_FAILED = 'CREDENTIALS_UBEREATS_UPDATE_COOKIE_FAILED',
    CREDENTIALS_ZENCHEF_AUTH_ERROR = 'CREDENTIALS_ZENCHEF_AUTH_ERROR',
    CREDENTIALS_ZENCHEF_WRONG_CREDENTIALS = 'CREDENTIALS_ZENCHEF_WRONG_CREDENTIALS',
    CREDENTIALS_ZENCHEF_NO_PARAMETERS_ACCESS = 'CREDENTIALS_ZENCHEF_NO_PARAMETERS_ACCESS',
    CREDENTIALS_ZENCHEF_ERROR = 'CREDENTIALS_ZENCHEF_ERROR',
    CREDENTIALS_ZENCHEF_CREDENTIAL_NOT_FOUND = 'CREDENTIALS_ZENCHEF_CREDENTIAL_NOT_FOUND',
    CREDENTIALS_ZENCHEF_API_ERROR = 'CREDENTIALS_ZENCHEF_API_ERROR',
    CREDENTIALS_DELIVEROO_COULD_NOT_FETCH_API_WITH_SEVERAL_AGENTS = 'CREDENTIALS_DELIVEROO_COULD_NOT_FETCH_API_WITH_SEVERAL_AGENTS',

    // EMAILS
    EMAIL_NOT_SENT = 'EMAIL_NOT_SENT',
    EMAIL_INVALID_CATEGORY = 'EMAIL_INVALID_CATEGORY',
    MAILING_NO_USERS = 'MAILING_NO_USERS',

    // DIAGNOSTICS
    DIAGNOSTIC_NOT_FOUND = 'DIAGNOSTIC_NOT_FOUND',
    DIAGNOSTIC_INSTAGRAM_PAGE_NOT_FOUND = 'DIAGNOSTIC_INSTAGRAM_PAGE_NOT_FOUND',
    ERROR_WHILE_CREATING_DIAGNOSTIC = 'ERROR_WHILE_CREATING_DIAGNOSTIC',
    DIAGNOSTIC_INVALID_CATEGORY = 'DIAGNOSTIC_INVALID_CATEGORY',
    DIAGNOSTIC_CANNOT_FETCH_PLACE_DETAILS = 'DIAGNOSTIC_CANNOT_FETCH_PLACE_DETAILS',
    DIAGNOSTIC_NO_KEYWORDS_FOUND = 'DIAGNOSTIC_NO_KEYWORDS_FOUND',

    // FILTERS
    FILTER_START_DATE_AFTER_END_DATE = 'FILTER_START_DATE_AFTER_END_DATE',
    FILTER_MISSING_PARAMS = 'FILTER_MISSING_PARAMS',
    FILTER_CANNOT_INITIATE_CLASS = 'FILTER_CANNOT_INITIATE_CLASS',
    FILTER_METHOD_NOT_IMPLEMENTED = 'FILTER_METHOD_NOT_IMPLEMENTED',
    FILTER_TIME_RANGE_TOO_SHORT = 'FILTER_TIME_RANGE_TOO_SHORT',
    FILTER_TIME_RANGE_TOO_LONG = 'FILTER_TIME_RANGE_TOO_LONG',
    FILTER_INVALID_INSIGHT_PLATFORM = 'FILTER_INVALID_INSIGHT_PLATFORM',

    // FOLDERS
    FOLDER_WITH_SAME_NAME_ALREADY_EXISTS = 'FOLDER_WITH_SAME_NAME_ALREADY_EXISTS',

    // FACEBOOK
    FACEBOOK_API_EXCEPTION = 'FACEBOOK_API_EXCEPTION',

    // FACEBOOK REEL
    FACEBOOK_REEL_UPLOAD_VIDEO_FAILED = 'FACEBOOK_REEL_UPLOAD_VIDEO_FAILED',
    FACEBOOK_REEL_BAD_UPLOAD_STATUS = 'FACEBOOK_REEL_BAD_UPLOAD_STATUS',
    FACEBOOK_REEL_PUBLISH_REEL_FAILED = 'FACEBOOK_REEL_PUBLISH_REEL_FAILED',
    FACEBOOK_REEL_GET_POST_ID_FAILED = 'FACEBOOK_REEL_GET_POST_ID_FAILED',

    // GIFT DRAW
    CLIENT_ALREADY_PLAYED = 'CLIENT_ALREADY_PLAYED',
    CLIENT_EMAIL_NOT_FOUND = 'CLIENT_EMAIL_NOT_FOUND',
    CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND',
    GIFT_DRAW_NOT_FOUND = 'GIFT_DRAW_NOT_FOUND',

    // GMB
    GMB_MESSAGES_AGENT_ALREADY_EXISTS = 'GMB_MESSAGES_AGENT_ALREADY_EXISTS',
    GMB_NOT_CONNECTED = 'GMB_NOT_CONNECTED',
    GMB_PLACE_ID_IS_NO_LONGER_VALID = 'GMB_PLACE_ID_IS_NO_LONGER_VALID',
    GMB_MISSING_PARAMS = 'GMB_MISSING_PARAMS',
    GMB_GEOLOCATION_SERVICE_ERROR = 'GMB_GEOLOCATION_SERVICE_ERROR',
    GMB_MISSING_ADDRESS = 'GMB_MISSING_ADDRESS',
    GMB_FAILED_TO_PUBLISH_POST = 'GMB_FAILED_TO_PUBLISH_POST',
    GMB_PIN_DROP_REQUIRED = 'GMB_PIN_DROP_REQUIRED',
    GMB_PLACE_ACTION_LINK_ALREADY_EXISTS_WITH_SAME_DOMAIN = 'GMB_PLACE_ACTION_LINK_ALREADY_EXISTS_WITH_SAME_DOMAIN',

    // HELPERS
    HELPERS_RETRY_PLATFORM_SCRAPPER_ERROR = 'HELPERS_RETRY_PLATFORM_SCRAPPER_ERROR',
    HELPERS_INJECTOR_NOT_IMPLEMENTED = 'HELPERS_INJECTOR_NOT_IMPLEMENTED',
    HELPERS_DOWNLOAD_FILE_MISSING_FOLDER = 'HELPERS_DOWNLOAD_FILE_MISSING_FOLDER',

    // INSIGHTS
    INSIGHTS_NOT_FOUND = 'INSIGHTS_NOT_FOUND',
    DOWNLOAD_INSIGHTS_AS_PDF_FAILED = 'DOWNLOAD_INSIGHTS_AS_PDF_FAILED',

    // JOBS
    NO_MIN_REDIRECTED_AT = 'NO_MIN_REDIRECTED_AT',

    // KEYWORD LAMBDA
    KEYWORD_LAMBDA_GENERATOR_ERROR = 'KEYWORD_LAMBDA_ERROR',

    // KEYWORDS
    KEYWORD_NOT_FOUND = 'KEYWORD_NOT_FOUND',
    KEYWORDS_GEOSAMPLE_ERROR = 'KEYWORDS_GEOSAMPLE_ERROR',
    KEYWORDS_PLATFORM_NOT_AUTHORIZED = 'KEYWORDS_PLATFORM_NOT_AUTHORIZED',
    KEYWORDS_VOLUME_API_REQUEST_LIMIT_REACHED = 'KEYWORDS_VOLUME_API_REQUEST_LIMIT_REACHED',
    FETCH_KEYWORDS_VOLUME_MONTHLY_ERROR = 'FETCH_KEYWORDS_VOLUME_MONTHLY_ERROR',
    FETCH_KEYWORDS_VOLUME_MONTHLY_SUCCESS = 'FETCH_KEYWORDS_VOLUME_MONTHLY_SUCCESS', // used for Slack alerts
    KEYWORDS_VOLUME_API_REQUEST_FAILED = 'KEYWORDS_VOLUME_API_REQUEST_FAILED',
    KEYWORDS_GENERATION_ALREADY_RUNNING = 'KEYWORDS_GENERATION_ALREADY_RUNNING',

    // MEDIAS
    WRONG_MEDIA_FORMAT = 'WRONG_MEDIA_FORMAT',
    WRONG_MEDIA_TYPE = 'WRONG_MEDIA_TYPE',
    CANNOT_DELETE_MEDIAS_FROM_OTHER_RESTAURANTS = 'CANNOT_DELETE_MEDIAS_FROM_OTHER_RESTAURANTS',
    MEDIA_THUMBNAIL_GENERATION_FAILED = 'MEDIA_THUMBNAIL_GENERATION_FAILED',

    // MESSAGES
    MISSING_AWS_SOURCE_KEY = 'MISSING_AWS_SOURCE_KEY',
    MESSAGES_PLATFORM_HAS_NO_CONVERSATION_MAPPER = 'MESSAGES_PLATFORM_HAS_NO_CONVERSATION_MAPPER',
    MESSAGES_CLIENT_AUTH_FAILED = 'MESSAGES_CLIENT_AUTH_FAILED',

    // MISC
    CREATION_ERROR = 'CREATION_ERROR',
    NO_FILTER_PROVIDED = 'NO_FILTER_PROVIDED',
    NOT_FOUND = 'NOT_FOUND',
    BAD_REQUEST = 'BAD_REQUEST',
    UNAUTHORIZED = 'UNAUTHORIZED',
    FORBIDDEN = 'FORBIDDEN',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    ABSTRACT_CLASS_ERROR = 'ABSTRACT_CLASS_ERROR',
    MISSING_PARAM = 'MISSING_PARAM',
    INVALID_DATA = 'INVALID_DATA',

    // MULTIMEDIA_STREAMS_INFORMATION
    MULTIMEDIA_STREAMS_INFORMATION_MISSING_INFORMATION = 'MULTIMEDIA_STREAMS_INFORMATION_MISSING_INFORMATION',

    // NFCS
    NFC_NOT_FOUND = 'NFC_NOT_FOUND',

    // NOT_IMPLEMENTED
    NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',

    // ORGANIZATIONS
    ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
    ORGANIZATION_IS_LINKED_TO_A_RESTAURANT = 'ORGANIZATION_IS_LINKED_TO_A_RESTAURANT',
    ORGANIZATION_LIMIT_REACHED = 'ORGANIZATION_LIMIT_REACHED',

    // PLATFORMS
    PLATFORM_NOT_FOUND = 'PLATFORM_NOT_FOUND',
    RETRIEVE_OVERVIEW_DATA_ERROR = 'RETRIEVE_OVERVIEW_DATA_ERROR',
    PLATFORM_CREDENTIAL_NOT_FOUND = 'PLATFORM_CREDENTIAL_NOT_FOUND',
    PLATFORM_DATA_UNDEFINED_OR_EMPTY = 'PLATFORM_DATA_UNDEFINED_OR_EMPTY',
    PLATFORM_DATA_CRAWLING_ERROR = 'PLATFORM_DATA_CRAWLING_ERROR',
    PLATFORM_SEARCH_RESULT_NOT_ARRAY = 'PLATFORM_SEARCH_RESULT_NOT_ARRAY',
    PLATFORM_CATEGORY_NOT_FOUND = 'PLATFORM_CATEGORY_NOT_FOUND',
    PLATFORM_FB_NOT_CONNECTED = 'PLATFORM_FB_NOT_CONNECTED',
    PLATFORM_IG_NOT_CONNECTED = 'PLATFORM_IG_NOT_CONNECTED',
    PLATFORM_NO_DATA_IN_RESPONSE = 'PLATFORM_NO_DATA_IN_RESPONSE',
    PLATFORM_MAPPER_METHOD_NOT_IMPLEMENTED = 'PLATFORM_MAPPER_METHOD_NOT_IMPLEMENTED',
    PLATFORM_FOURSQUARE_NO_VENUES = 'PLATFORM_FOURSQUARE_NO_VENUES',
    PLATFORM_UBEREATS_REVIEWS_FETCH_ERROR = 'PLATFORM_UBEREATS_REVIEWS_FETCH_ERROR',
    PLATFORM_ZENCHEF_FETCH_RESTAURANTS_UNAUTHORIZED_ERROR = 'PLATFORM_ZENCHEF_FETCH_RESTAURANTS_UNAUTHORIZED_ERROR',
    PLATFORM_SCRAPPER_ERROR = 'PLATFORM_SCRAPPER_ERROR',
    PLATFORM_NOT_CONNECTED = 'PLATFORM_NOT_CONNECTED',
    PLATFORM_MAPPER_FAILED_FIELD_PUBLISH = 'PLATFORM_MAPPER_FAILED_FIELD_PUBLISH',
    PLATFORM_MAPPER_CANNOT_PARSE_DATA = 'PLATFORM_MAPPER_CANNOT_PARSE_DATA',
    PLATFORM_MAPPER_MISSING_PARAM = 'PLATFORM_MAPPER_MISSING_PARAM',
    PLATFORM_MAPPER_DATA_ERROR = 'PLATFORM_MAPPER_DATA_ERROR',
    PLATFORM_SERVICE_ERROR = 'PLATFORM_SERVICE_ERROR',
    PLATFORM_SERVICE_NOT_IMPLEMENTED = 'PLATFORM_SERVICE_NOT_IMPLEMENTED',
    PLATFORM_API_ENDPOINT_NOT_FOUND = 'PLATFORM_API_ENDPOINT_NOT_FOUND',
    PLATFORM_INVALID_ENDPOINT = 'PLATFORM_INVALID_ENDPOINT',
    PLATFORM_INVALID_KEY = 'PLATFORM_INVALID_KEY',
    PLATFORM_THE_FORK_REVIEWS_MALFORMED_RESPONSE = 'PLATFORM_THE_FORK_REVIEWS_MALFORMED_RESPONSE',
    PLATFORM_REQUIRED = 'PLATFORM_REQUIRED',
    PLATFORM_MISSING_SOCIAL_ID = 'PLATFORM_MISSING_SOCIAL_ID',
    PLATFORM_MISSING_LOCATION_ID = 'PLATFORM_MISSING_LOCATION_ID',
    PLATFORM_MISSING_PERMISSIONS = 'PLATFORM_MISSING_PERMISSIONS',
    PLATFORM_SCRAP_ERROR = 'PLATFORM_SCRAP_ERROR',
    PLATFORM_DELETE_ERROR = 'PLATFORM_DELETE_ERROR',
    PLATFORM_PULL_OVERVIEW_ERROR = 'PLATFORM_PULL_OVERVIEW_ERROR',
    PLATFORM_UPSERT_ERROR = 'PLATFORM_UPSERT_ERROR',
    PLATFORM_PUBLISH_ERROR = 'PLATFORM_PUBLISH_ERROR',
    PLATFORM_INVALID_PASSWORD = 'PLATFORM_INVALID_PASSWORD',

    // PLATFORM INSIGHTS
    PLATFORM_INSIGHTS_ERROR = 'PLATFORM_INSIGHTS_ERROR',

    // POSTS
    COMPLETE_PUBLISH_POST_ERROR = 'COMPLETE_PUBLISH_POST_ERROR',
    COMPLETE_PUBLISH_POST_TIMEOUT_ERROR = 'COMPLETE_PUBLISH_POST_TIMEOUT_ERROR',
    MAPSTR_MAPPING_ERROR = 'MAPSTR_MAPPING_ERROR',
    MEDIA_NOT_READY_MAX_RETRIES_REACHED = 'MEDIA_NOT_READY_MAX_RETRIES_REACHED',
    MEDIA_DELETED_FOR_POST = 'MEDIA_DELETED_FOR_POST',
    POST_NOT_FOUND = 'POST_NOT_FOUND',
    UNKNOWN_FB_POST_TYPE = 'UNKNOWN_FB_POST_TYPE',
    FAILED_VIDEO_RESIZE = 'FAILED_VIDEO_RESIZE',
    MEDIA_REQUIRED_BECAUSE_THE_STORY_IS_PLANNED_FOR_PUBLICATION = 'MEDIA_REQUIRED_BECAUSE_THE_STORY_IS_PLANNED_FOR_PUBLICATION',
    STORY_ALREADY_PUBLISHED = 'STORY_ALREADY_PUBLISHED',
    POSTS_MUST_BE_FROM_SAME_RESTAURANT = 'POSTS_MUST_BE_FROM_SAME_RESTAURANT',
    PUBLISH_POST_ERROR = 'PUBLISH_POST_ERROR',
    POST_MUST_HAVE_MEDIA = 'POST_MUST_HAVE_MEDIA',
    COMPLETE_PUBLISH_STORY_ERROR = 'COMPLETE_PUBLISH_STORY_ERROR',
    REEL_WITHOUT_VIDEO = 'REEL_WITHOUT_VIDEO',

    // PROVIDERS
    PROVIDER_MAPSTR_NO_DATA = 'PROVIDER_MAPSTR_NO_DATA',
    PROVIDER_INVALID_PLATFORM_KEY = 'PROVIDER_INVALID_PLATFORM_KEY',

    // SERVICES
    SERVICE_INVALID_CONFIGURATION = 'SERVICE_INVALID_CONFIGURATION',
    SERVICE_INVALID_RESPONSE = 'SERVICE_INVALID_RESPONSE',

    // PUBSUB
    NO_SUBSCRIPTION_NAME_OR_ID = 'NO_SUBSCRIPTION_NAME_OR_ID',

    // REPORTS
    INVALID_UNSUBSCRIBE_HASH = 'INVALID_UNSUBSCRIBE_HASH',

    // RESTAURANTS
    RESTAURANT_HAS_NO_ORGANIZATION = 'RESTAURANT_HAS_NO_ORGANIZATION',
    RESTAURANT_MISSING_LATLNG = 'RESTAURANT_MISSING_LATLNG',
    RESTAURANT_API_LOCATION_ID_NOT_FOUND = 'RESTAURANT_API_LOCATION_ID_NOT_FOUND',
    RESTAURANT_NOT_FOUND = 'RESTAURANT_NOT_FOUND',
    RESTAURANT_BRICKS_NOT_FOUND = 'RESTAURANT_BRICKS_NOT_FOUND',
    ABOVE_RESTAURANTS_LIMITS = 'ABOVE_RESTAURANTS_LIMITS',
    RESTAURANT_INACTIVE = 'RESTAURANT_INACTIVE',
    INVALID_SOCIAL_NETWORK_URL = 'INVALID_SOCIAL_NETWORK_URL',
    RESTAURANT_CLOSED_PERMANENTLY = 'RESTAURANT_CLOSED_PERMANENTLY',

    // RESTAURANT AI SETTINGS
    RESTAURANT_AI_SETTINGS_NOT_FOUND = 'RESTAURANT_AI_SETTINGS_NOT_FOUND',

    // RESTAURANT KEYWORDS
    RESTAURANT_KEYWORD_NOT_FOUND = 'RESTAURANT_KEYWORD_NOT_FOUND',
    RESTAURANT_KEYWORD_RANKING_REFRESH_NOT_ALLOWED = 'RESTAURANT_KEYWORD_RANKING_REFRESH_NOT_ALLOWED',

    // REVIEWS
    PUBLISH_REVIEW_ERROR = 'PUBLISH_REVIEW_ERROR',
    RETRIEVE_REVIEWS_ERROR = 'RETRIEVE_REVIEWS_ERROR',
    REVIEWS_UPDATE_ERROR = 'REVIEWS_UPDATE_ERROR',
    REVIEW_NOT_FOUND = 'REVIEW_NOT_FOUND',
    REVIEW_TOO_OLD = 'REVIEW_TOO_OLD',
    REVIEW_NOT_IN_RESULTS = 'REVIEW_NOT_IN_RESULTS',
    REVIEW_INCORRECT_SOCIAL_LINK = 'REVIEW_INCORRECT_SOCIAL_LINK',
    REVIEW_INCORRECT_SOCIAL_ID = 'REVIEW_INCORRECT_SOCIAL_ID',
    DOWNLOAD_REVIEWS_AS_PDF_FAILED = 'DOWNLOAD_REVIEWS_AS_PDF_FAILED',

    // ROUTING_ERROR
    ROUTING_ERROR = 'ROUTING_ERROR',

    // SCRAPPER
    SCRAPPER_TOO_MANY_TRIES = 'SCRAPPER_TOO_MANY_TRIES',

    // STICKERS
    STICKER_NOT_FOUND = 'STICKER_NOT_FOUND',

    // TEMPLATES
    DEFAULT_TEMPLATES_GENERATION_FAILED = 'DEFAULT_TEMPLATES_GENERATION_FAILED',

    // USERS
    USER_HAS_NO_ORGANIZATION = 'USER_HAS_NO_ORGANIZATION',
    USER_UNKNOWN_ROLE = 'USER_UNKNOWN_ROLE',
    USER_CANNOT_UPDATE_RESTAURANT = 'USER_CANNOT_UPDATE_RESTAURANT',
    USER_CANNOT_CREATE_ADMIN = 'USER_CANNOT_CREATE_ADMIN',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
    USER_WRONG_PASSWORD = 'USER_WRONG_PASSWORD',
    USER_CANNOT_DELETE_ADMIN = 'USER_CANNOT_DELETE_ADMIN',
    USER_CANNOT_DELETE_USER = 'USER_CANNOT_DELETE_USER',
    USER_HAVE_NO_ACCESS_TO_ACCOUNT = 'USER_HAVE_NO_ACCESS_TO_ACCOUNT',
    USER_CANNOT_SEE_RESTAURANTS = 'USER_CANNOT_SEE_RESTAURANTS',
    USER_CANNOT_DOWNGRADE_LAST_OWNER = 'USER_CANNOT_DOWNGRADE_LAST_OWNER',

    // WHEEL OF FORTUNE
    GIFT_NAME_TOO_LONG = 'GIFT_NAME_TOO_LONG',
    NO_AVAILABLE_GIFTS = 'NO_AVAILABLE_GIFTS',
    RESTAURANT_HAS_AN_ACTIVE_OR_PROGRAMMED_WHEEL_OF_FORTUNE = 'RESTAURANT_HAS_AN_ACTIVE_OR_PROGRAMMED_WHEEL_OF_FORTUNE',
    RESTAURANT_NOT_MANAGED_MISSING_IN_WHEEL_OF_FORTUNE = 'RESTAURANT_NOT_MANAGED_MISSING_IN_WHEEL_OF_FORTUNE',
    RESTAURANT_WHEEL_OF_FORTUNE_MULTIPLE_RESTAURANTS = 'RESTAURANT_WHEEL_OF_FORTUNE_MULTIPLE_RESTAURANTS',
    WHEEL_OF_FORTUNE_NOT_FOUND = 'WHEEL_OF_FORTUNE_NOT_FOUND',
    WHEEL_OF_FORTUNE_NOT_STARTED = 'WHEEL_OF_FORTUNE_NOT_STARTED',
    WRONG_RETRIEVAL_DATES = 'WRONG_RETRIEVAL_DATES',

    // LAMBDA
    LAMBDA_ERROR_GENERATING_PDF = 'LAMBDA_ERROR_GENERATING_PDF',
    LAMBDA_MAXIMUM_CALL_STACK_SIZE_EXCEEDED = 'LAMBDA_MAXIMUM_CALL_STACK_SIZE_EXCEEDED',

    // YEXT
    YEXT_ACCOUNT_NOT_FOUND = 'YEXT_ACCOUNT_NOT_FOUND',
    YEXT_LOCATION_NOT_FOUND = 'YEXT_LOCATION_NOT_FOUND',
    YEXT_LOCATION_ALREADY_EXISTS = 'YEXT_LOCATION_ALREADY_EXISTS',
    YEXT_LOCATION_STATUS_IS_NOT_PROCESSED = 'YEXT_LOCATION_STATUS_IS_NOT_PROCESSED',
    YEXT_LOCATION_HAS_ACTIVE_SERVICES = 'YEXT_LOCATION_HAS_ACTIVE_SERVICES',
    YEXT_CANT_DELETE_BECAUSE_OF_ADD_REQUEST_STATUS = 'YEXT_CANT_DELETE_BECAUSE_OF_ADD_REQUEST_STATUS',
    YEXT_NOT_SUPPORTED = 'YEXT_NOT_SUPPORTED',
    CANNOT_MAP_RESTAURANT_TO_YEXT_ENTITY = 'CANNOT_MAP_RESTAURANT_TO_YEXT_ENTITY',
    CANNOT_ADD_LOCATION_FOR_BRAND_RESTAURANT = 'CANNOT_ADD_LOCATION_FOR_BRAND_RESTAURANT',
    UPDATE_YEXT_ADD_REQUEST_STATUS_MAX_RETRY = 'UPDATE_YEXT_ADD_REQUEST_STATUS_MAX_RETRY',
    YEXT_UPDATE_LOCATION_FAILED = 'YEXT_UPDATE_LOCATION_FAILED',
    YEXT_DELETE_LOCATION_MAX_RETRY = 'YEXT_DELETE_LOCATION_MAX_RETRY',
    YEXT_LOCATION_DELETION_FAILED = 'YEXT_LOCATION_DELETION_FAILED',
    UNHANDLED_YEXT_PUBLISHERS = 'UNHANDLED_YEXT_PUBLISHERS',

    // SQS
    SQS_MESSAGE_NOT_FOUND = 'SQS_MESSAGE_NOT_FOUND',
    SQS_INVALID_MESSAGE = 'SQS_INVALID_MESSAGE',

    // ROI
    ROI_ACTIVATION_MESSAGE = 'ROI_ACTIVATION_MESSAGE',
    MONTHLY_SAVE_ROI_INSIGHTS_ERROR = 'MONTHLY_SAVE_ROI_INSIGHTS_ERROR',

    // HUBSPOT
    HUBSPOT_CANNOT_SUBMIT_FORM = 'HUBSPOT_CANNOT_SUBMIT_FORM',

    // NOTIFICATIONS
    REVIEWS_FROM_DIFFERENT_RESTAURANTS = 'REVIEWS_FROM_DIFFERENT_RESTAURANTS',
    NOTIFICATION_NOT_FOUND = 'NOTIFICATION_NOT_FOUND',
}

export enum AiTextToOptimizeType {
    REVIEW_ANSWER = 'REVIEW_ANSWER',
    SOCIAL_NETWORK_POST = 'SOCIAL_NETWORK_POST',
    SEO_POST = 'SEO_POST',
}

export enum AiInteractionRelatedEntityCollection {
    POSTS = 'POSTS',
    REVIEWS = 'REVIEWS',
    RESTAURANTS = 'RESTAURANTS',
    REPORTS = 'REPORTS',
    KEYWORDS = 'KEYWORDS',
    HASHTAGS = 'HASHTAGS',
    SIMILAR_RESTAURANTS = 'SIMILAR_RESTAURANTS',
    RESTAURANT_AI_SETTINGS = 'RESTAURANT_AI_SETTINGS',
    DIAGNOSTICS = 'DIAGNOSTICS',
    MEDIA = 'MEDIA',
}

export enum InvalidPlatformReason {
    IG_NOT_FOUND = 'ig_not_found',
    IG_NOT_BUSINESS = 'ig_not_business',
    GMB_DISCONNECTED = 'gmb_disconnected',
    GMB_DISABLED = 'gmb_disabled',
    GMB_SUSPENDED = 'gmb_suspended',
    GMB_NOT_VERIFIED = 'gmb_not_verified',
    GMB_PENDING_VERIFICATION = 'gmb_pending_verification',
}

export enum AiInteractionType {
    ANSWER_REVIEW_ADVANCED_SETTINGS = 'answer_review_advanced_settings',
    GENERATE_SOCIAL_NETWORK_POST = 'generate_social_network_post',
    GENERATE_SOCIAL_NETWORK_POST_ADVANCED_SETTINGS = 'generate_social_network_post_advanced_settings',
    GENERATE_SEO_POST = 'generate_seo_post',
    GENERATE_SEO_POST_ADVANCED_SETTINGS = 'generate_seo_post_advanced_settings',
    CHOOSE_POST_HASHTAGS = 'choose_post_hashtags',
    OPTIMIZE_SOCIAL_NETWORK_POST = 'optimize_social_network_post',
    OPTIMIZE_SOCIAL_NETWORK_POST_ADVANCED_SETTINGS = 'optimize_social_network_post_advanced_settings',
    OPTIMIZE_SEO_POST = 'optimize_seo_post',
    OPTIMIZE_SEO_POST_ADVANCED_SETTINGS = 'optimize_seo_post_advanced_settings',
    OPTIMIZE_REVIEW_ANSWER_ADVANCED_SETTINGS = 'optimize_review_answer_advanced_settings',
    BREAKDOWN_AND_CLASSIFY_KEYWORDS = 'breakdown_and_classify_keywords',
    GENERATE_SEMANTIC_ANALYSIS_OVERVIEW = 'generate_semantic_analysis_overview',
    FRENCH_KEYWORDS_GENERATION = 'french_keywords_generation',
    ENGLISH_KEYWORDS_GENERATION = 'english_keywords_generation',
    ITALIAN_KEYWORDS_GENERATION = 'italian_keywords_generation',
    SPANISH_KEYWORDS_GENERATION = 'spanish_keywords_generation',
    CATEGORIZE_RESTAURANT = 'categorize_restaurant',
    IDENTIFY_SIMILAR_LOCALITIES = 'identify_similar_localities',
    IDENTIFY_SIMILAR_LOCALITIES_FOR_DIAGNOSTIC = 'identify_similar_localities_for_diagnostic',
    REVIEW_TRANSLATION = 'review_translation',
    POST_TRANSLATION = 'post_translation',
    REVIEW_ANSWER_TRANSLATION = 'review_answer_translation',
    INTRODUCTIVE_TRANSLATION = 'introductive_translation',
    SIGNATURE_TRANSLATION = 'signature_translation',
    GENERATE_IMAGE_DESCRIPTION = 'generate_image_description',
    TRANSLATE_TAGS = 'translate_tags',
    GENERATE_SEO_DUPLICATION = 'generate_seo_duplication',

    // Deprecated : new interactions from sept 2024 should not use/have these types
    TRANSLATE = 'translate',
    ANSWER_REVIEW = 'answer_review',
    OPTIMIZE_REVIEW_ANSWER = 'optimize_review_answer',
}

export enum AiPostGenerationEmojiStatus {
    NO_EMOJI = 'no',
    SOME_EMOJI = 'some',
}

export enum PostError {
    POST_ALREADY_PUBLISHED = 'post_already_published',
    POST_STUCK_PROCESSING = 'post_stuck_processing',
    STORY_NOT_PUBLISHED_BECAUSE_PREVIOUS_ONE_WAS_NOT_PUBLISHED = 'story_not_published_because_previous_one_was_not_published',
}

export enum SizeInBytes {
    KILO_BYTES = 2 ** 10,
    MEGA_BYTES = 2 ** 20,
    GIGA_BYTES = 2 ** 30,
}

export enum InformationUpdateStatus {
    PENDING = 'PENDING',
    VALIDATED = 'VALIDATED',
}

export enum InformationUpdatePlatformStateStatus {
    TO_BE_SENT = 'TO_BE_SENT',
    ERROR = 'ERROR',
    PENDING = 'PENDING',
    DONE = 'DONE',
}

export enum InformationUpdateAttributeValue {
    YES = 'yes',
    NO = 'no',
}

export enum OpenaiModelVersion {
    DEFAULT_MODEL = 'gpt-3.5-turbo-instruct',
    GPT_3_5_TURBO_1106 = 'gpt-3.5-turbo-1106',
}

export enum RestaurantAttributeValue {
    NOT_CONCERNED = 'notConcerned',
    YES = 'yes',
    NO = 'no',
}

export enum BrickType {
    CATEGORY_TO_SPECIAL = 'category_to_special',
    CATEGORY_TO_TYPE = 'category_to_type',
    POSTAL_CODE_TO_TOURISTIC_AREA = 'postal_code_to_touristic_area',
}

export const USER_AGENTS = [
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36',
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/85.0.4183.83 Safari/537.36',
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.87 Safari/537.36',
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.3 Safari/605.1.15',
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 11_1_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.192 Safari/537.36',
    'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36',
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.246',
    'Mozilla/5.0 (X11; CrOS x86_64 8172.45.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.64 Safari/537.36',
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_2) AppleWebKit/601.3.9 (KHTML, like Gecko) Version/9.0.2 Safari/601.3.9',
    'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/47.0.2526.111 Safari/537.36',
    'Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:15.0) Gecko/20100101 Firefox/15.0.1',
];

export enum FileType {
    CSV = 'csv',
    XLS = 'xls',
    XLSX = 'xlsx',
    DOC = 'doc',
    DOCX = 'docx',
    PDF = 'pdf',
    TXT = 'txt',
    RTF = 'rtf',
    ODT = 'odt',
}

export enum MediaFileType {
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
    DOCUMENT = 'document',
}

// ############################################

export const langDomaineMapping = Object.freeze({
    fr: { tld: 'fr' },
    en: { tld: 'com' },
    pt: { tld: 'pt' },
    de: { tld: 'de' },
    zhTW: { tld: 'cn' },
    es: { tld: 'es' },
    it: { tld: 'it' },
    no: { tld: 'no' },
    ja: { tld: 'jp' },
    ru: { tld: 'ru' },
    da: { tld: 'dk' },
    tr: { tld: 'tr' },
    fi: { tld: 'fi' },
    sv: { tld: 'se' },
    ko: { tld: 'kr' },
    in: { tld: 'id' },
    el: { tld: 'gr' },
    cs: { tld: 'cz' },
    vi: { tld: 'vn' },
    th: { tld: 'th' },
    pl: { tld: 'pl' },
    nl: { tld: 'nl' },
    sk: { tld: 'sk' },
});

export enum ChatCompletionRole {
    SYSTEM = 'system',
    USER = 'user',
}

export interface ILatlng {
    lat: number;
    lng: number;
}

export enum InsightsTab {
    SEO = 'seo',
    E_REPUTATION = 'e_reputation',
    SOCIAL_NETWORKS = 'social_networks',
    BOOSTERS = 'boosters',
    AGGREGATED_SEO = 'aggregated_seo',
    AGGREGATED_E_REPUTATION = 'aggregated_e_reputation',
    AGGREGATED_SOCIAL_NETWORKS = 'aggregated_social_networks',
    AGGREGATED_BOOSTERS = 'aggregated_boosters',
}

export const aggregatedInsightsTabs = [
    InsightsTab.AGGREGATED_SEO,
    InsightsTab.AGGREGATED_E_REPUTATION,
    InsightsTab.AGGREGATED_SOCIAL_NETWORKS,
    InsightsTab.AGGREGATED_BOOSTERS,
];

export enum InsightsChart {
    // seo
    ACTIONS = 'actions',
    APPARITIONS = 'apparitions',
    KEYWORDS = 'keywords',
    // e-reputation
    REVIEW_RATINGS_KPIS = 'review_ratings_kpis',
    REVIEW_KPIS = 'review_kpis',
    REVIEW_RATING_EVOLUTION = 'review_rating_evolution',
    REVIEW_RATING_TOTAL = 'review_rating_total',
    REVIEW_ANALYSES_TAG_CHARTS = 'review_analyses_tag_charts',
    REVIEW_ANALYSES_TAG_EVOLUTION = 'review_analyses_tag_evolution',
    // social network
    COMMUNITY = 'community',
    ENGAGEMENT = 'engagement',
    POST_INSIGHTS = 'post_insights',
    REEL_INSIGHTS = 'reel_insights',
    STORY_INSIGHTS = 'story_insights',
    // boosters
    BOOSTERS_PRIVATE_REVIEWS_COUNT = 'boosters_private_reviews_count',
    BOOSTERS_SCAN_COUNT = 'boosters_scan_count',
    BOOSTERS_TOTEMS_ESTIMATED_REVIEWS_COUNT = 'boosters_totems_estimated_reviews_count',
    BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION = 'boosters_wheel_of_fortune_gifts_distribution',
    // aggregated seo
    AGGREGATED_RANKINGS = 'aggregated_rankings',
    AGGREGATED_ACTIONS = 'aggregated_actions',
    AGGREGATED_APPARITIONS = 'aggregated_apparitions',
    // aggregated e-reputation
    AGGREGATED_REVIEW_RATINGS_KPIS = 'aggregated_review_ratings_kpis',
    AGGREGATED_REVIEWS_COUNT = 'aggregated_reviews_count',
    AGGREGATED_REVIEWS_RATING_AVERAGE = 'aggregated_reviews_rating_average',
    AGGREGATED_REVIEW_ANALYSES_TAG_CHARTS = 'aggregated_review_analyses_tag_charts',
    AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION = 'aggregated_review_analyses_tag_evolution',
    // social network
    AGGREGATED_TOP_POSTS_CARDS = 'aggregated_top_posts_cards',
    AGGREGATED_PUBLICATIONS_TABLE = 'aggregated_publications_table',
    // aggregated boosters
    AGGREGATED_BOOSTERS_SCAN_COUNT = 'aggregated_boosters_scan_count',
    AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION = 'aggregate_boosters_wheel_of_fortune_gifts_distribution',
    AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_ESTIMATED_REVIEWS_COUNT = 'aggregated_boosters_wheel_of_fortune_estimated_reviews_count',
}

export enum CsvInsightChart {
    // seo
    KEYWORDS = 'keywords',
    GMB_VISIBILITY = 'gmb_visibility',
    // e-reputation
    REVIEWS_RATINGS_EVOLUTION = 'reviews_ratings_evolution',
    REVIEWS_RATINGS_TOTAL = 'reviews_ratings_total',
    PLATFORMS_RATINGS = 'platforms_ratings',
    // e-reputation
    AGGREGATED_PLATFORMS_RATINGS = 'aggregated_platforms_ratings',
    // aggregated seo
    AGGREGATED_GMB_VISIBILITY = 'aggregated_gmb_visibility',
    // social network
    PUBLICATIONS = 'publications',
    STORIES = 'stories',
    ALL_FOLLOWERS = 'all_followers',
    FB_FOLLOWERS = 'fb_followers',
    IG_FOLLOWERS = 'ig_followers',
    // boosters
    BOOSTERS_SCAN_COUNT = 'boosters_scan_count',
    BOOSTERS_REVIEWS_COUNT = 'boosters_reviews_count',
    BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION = 'boosters_wheel_of_fortune_gifts_distribution',
    // aggregated social network
    AGGREGATED_ALL_FOLLOWERS = 'aggregated_all_followers',
    AGGREGATED_FB_FOLLOWERS = 'aggregated_fb_followers',
    AGGREGATED_IG_FOLLOWERS = 'aggregated_ig_followers',
    AGGREGATED_PUBLICATIONS = 'aggregated_publications',
    AGGREGATED_STORIES = 'aggregated_stories',
    AGGREGATED_REVIEW_COUNT = 'aggregated_review_count',
    AGGREGATED_AVERAGE_REVIEWS_RATINGS = 'aggregated_average_reviews_ratings',
    // aggregated boosters
    AGGREGATED_BOOSTERS_SCAN_COUNT = 'aggregated_boosters_scan_count',
    AGGREGATED_BOOSTERS_REVIEWS_COUNT = 'aggregated_boosters_reviews_count',
    AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION = 'aggregated_boosters_wheel_of_fortune_gifts_distribution',
}

export const openTableCookieKey = 'opentable-cookie-otumamiauth';

export enum AppEntity {
    RESTAURANTS = 'restaurants',
    USERS = 'users',
}

export enum PdfFileFormat {
    /** 216 x 279 mm */
    LETTER = 'Letter',
    /** 216 x 356 mm */
    LEGAL = 'Legal',
    /** 279 x 432 mm */
    TABLOID = 'Tabloid',
    /** 210 x 297 mm */
    LEDGER = 'Ledger',
    /** 841 x 1189 mm */
    A0 = 'A0',
    /** 594 x 841 mm */
    A1 = 'A1',
    /** 420 x 594 mm  */
    A2 = 'A2',
    /** 297 x 420 mm */
    A3 = 'A3',
    /** 210 x 297 mm */
    A4 = 'A4',
    /** 148 x 210 mm */
    A5 = 'A5',
    /** 105 x 148 mm */
    A6 = 'A6',
}

export enum Currency {
    EUR = 'EUR', // euro
    USD = 'USD', // dollar
    GBP = 'GBP', // pound
}

export enum HashtagType {
    RESTAURANT = 'restaurant',
    DESCRIPTION = 'description',
    UNKNOWN = 'unknown',
}

export enum WatcherStatus {
    RUNNING = 'running',
    FINISHED = 'finished',
    FAILED = 'failed',
}

export enum PlatformDataFetchedStatus {
    PENDING = 'pending',
    ERROR = 'error',
    SUCCESS = 'success',
    ASYNC = 'async',
}

export enum MediaTagCategory {
    FOOD = 'food',
    DRINKS = 'drinks',
    LOCATION = 'location',
    SPECIAL_EVENT = 'specialEvent',
    PEOPLE = 'people',
    OTHER = 'other',
}

export enum MediaTagSubcategory {
    MAIN = 'main',
    DESSERT = 'dessert',
    BACKGROUND_TYPE = 'backgroundType',
    LANDMARK = 'landmark',
}

export enum TooltipPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
}
