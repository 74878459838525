import { uniq } from 'lodash';

import { InformationUpdatePlatformStateStatus, PlatformAccessStatus, PlatformKey } from '../../constants';
import { YEXT_PLATFORM_KEY, YextPublisherId } from '../yext';

export type InformationUpdatePlatformKey = PlatformKey | typeof YEXT_PLATFORM_KEY;

export const InformationUpdatesStatus = { ...InformationUpdatePlatformStateStatus, ...PlatformAccessStatus } as const;
export type InformationUpdatesStatus = (typeof InformationUpdatesStatus)[keyof typeof InformationUpdatesStatus];

export type AggregatedInformationUpdateProviderKey = YextPublisherId | PlatformKey;

export const InformationUpdateProvider = {
    YEXT: 'YEXT',
    MALOU: 'MALOU',
} as const;

export type InformationUpdateProvider = (typeof InformationUpdateProvider)[keyof typeof InformationUpdateProvider];
/**
 * This is a mapping of the supported keys for the information updates.
 * The keys are the aggregation of the YextPublisherId (Yext) and the PlatformKey (MalouApp).
 * The field supported is used to know if the platform is supported by the information updates.
 *
 * for example, PlatformKey.PRIVATE has nothing to do with information updates, so it is not supported.
 */
export const configInformationUpdateSupportedKeys = {
    [YextPublisherId['118_000_FR']]: { name: '118000fr', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId['192_COM']]: { name: '192com', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId['8_COUPONS']]: { name: '8coupons', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.ACOMPIO_DE]: { name: 'acompio', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.ACOMPIO_FR]: { name: 'acompiofr', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.ACOMPIO_UK]: { name: 'acompiouk', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.AMAZON_ALEXA]: { name: 'alexa', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.AMERICAN_EXPRESS]: { name: 'americanexpress', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.AMERICAN_TOWNS]: { name: 'americantownscom', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.APPLE]: { name: 'apple', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.AROUND_ME]: { name: 'aroundme', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.AUSKUNFT]: { name: 'auskunft', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.BETTER_BUSINESS_BUREAU]: {
        name: 'betterbusinessbureau',
        supported: true,
        providers: [InformationUpdateProvider.YEXT],
    },
    [YextPublisherId.BING]: { name: 'bing', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.BRANCHENBUCH]: { name: 'branchenbuch', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.BRAVE]: { name: 'brave', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.BROWN_BOOK_NET]: { name: 'brownbooknet', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.BRUNCH_LUNCH_DINNER]: { name: 'brunchlunchdinner', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.BUNDES_TELEFONBUCH]: { name: 'bundestelefonbuch', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.CENTRAL_INDEX]: { name: 'centralindex', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.CENTRAL_INDEX_IE]: { name: 'centralindexie', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.CENTRAL_INDEX_US]: { name: 'centralindexus', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.CHAMBER_OF_COMMERCE]: { name: 'chamberofcommercecom', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.CITY_SEARCH]: { name: 'citysearch', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.CITY_SQUARES]: { name: 'citysquares', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.CYLEX]: { name: 'cylex', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.DAS_OERTLICHE]: { name: 'dasoertliche', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.DIALO]: { name: 'dialo', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.DOORDASH]: { name: 'doordash', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.DUN_AND_BRADSTREET]: { name: 'dunandbradstreet', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.E_LOCAL]: { name: 'elocal', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.ENIRO]: { name: 'eniro', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.EZ_LOCAL]: { name: 'ezlocal', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.FINDERR]: { name: 'finderr', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.FOURSQUARE]: { name: 'foursquare', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.GELBE_SEITEN]: { name: 'gelbeseiten', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.GO_LOCAL]: { name: 'golocal', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.GO_LOCAL_247]: { name: 'golocal247', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.GO_YELLOW]: { name: 'goyellow', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.GOLDEN_PAGES_IE]: { name: 'goldenpagesie', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.GOUDENGIDS]: { name: 'goudengids', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.GOUDENGIDS_NL]: { name: 'goudengidsnl', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.GULE_SIDER]: { name: 'gulesider', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.HELP_CH]: { name: 'helpch', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.HERE]: { name: 'here', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.HEROLD]: { name: 'herold', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.HITTA]: { name: 'hitta', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.HOODSPOT]: { name: 'laposte', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.HORAIRE]: { name: 'horaire', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.HORAIRES_D_OUVERTURE_24]: {
        name: 'horairesdouverture24',
        supported: true,
        providers: [InformationUpdateProvider.YEXT],
    },
    [YextPublisherId.HOST_ME]: { name: 'hostme', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.HOT_FROG]: { name: 'hotfrog', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.HOURS_COM]: { name: 'hourscom', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.I_BEGIN]: { name: 'ibegin', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.I_GLOBAL]: { name: 'iglobal', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.IMONES_LT]: { name: 'imoneslt', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.IN_YOUR_AREA]: { name: 'inyourarea', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.INFO_IS_INFO]: { name: 'infoisinfo', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.INFOBEL]: { name: 'infobel', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.INSIDER_PAGES]: { name: 'insiderpages', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.INSTACART]: { name: 'instacart', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.JUSTACOTE]: { name: 'justacote', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.KOOMIO]: { name: 'koomio', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.KRAK]: { name: 'krak', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.LES_HORAIRES]: { name: 'leshoraires', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.LOCAL_COM]: { name: 'localcom', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.LOCAL_STACK]: { name: 'localstack', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.MAP_QUEST]: { name: 'mapquest', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.MAPSTR]: { name: 'mapstr', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.MARKTPLATZ_MITTELSTAND]: {
        name: 'marktplatzmittelstand',
        supported: true,
        providers: [InformationUpdateProvider.YEXT],
    },
    [YextPublisherId.MEINESTADT]: { name: 'meinestadt', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.MERCHANT_CIRCLE]: { name: 'merchantcircle', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.MY_LOCAL_SERVICES]: { name: 'mylocalservices', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.MY_LOCAL_SERVICES_UK]: { name: 'mylocalservicesuk', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.MY_TOWN_IE]: { name: 'mytownie', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.N_49]: { name: 'n49ca', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.NAJISTO_CZ]: { name: 'najistocz', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.NAVMII]: { name: 'navmii', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.NEXTDOOR]: { name: 'nextdoor', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.NOCHOFFEN]: { name: 'nochoffen', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OEFFNUNGSZEITEN_BUCH]: { name: 'oeffnungszeitenbuch', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OEFFNUNGSZEITEN_COM]: { name: 'oeffnungszeitencom', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI]: { name: 'opendi', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENAI]: { name: 'openai', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_BE]: { name: 'opendibe', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_DK]: { name: 'opendidk', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_FR]: { name: 'opendifr', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_LU]: { name: 'opendilu', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_NL]: { name: 'opendinl', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_NO]: { name: 'opendino', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_PL]: { name: 'opendipl', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_PT]: { name: 'opendipt', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_RO]: { name: 'opendiro', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_SE]: { name: 'opendise', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_SI]: { name: 'opendisi', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENDI_UK]: { name: 'opendiuk', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENING_STIJDEN_NL]: { name: 'openingstijdennl', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENING_TIMES_CO_UK]: { name: 'openingtimescouk', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENTABLE]: { name: 'opentable', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.OPENUREN]: { name: 'openuren', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.ORTSDIENST_DE]: { name: 'ortsdienstde', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.PAGES_24_CH]: { name: 'pages24ch', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.PAGES_24_FR]: { name: 'pages24fr', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.PAGES_24_NL]: { name: 'pages24nl', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.PETIT_FUTE]: { name: 'petitfute', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.PHONE_BOOK_IE]: { name: 'eirphonebookie', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.PITNEY_BOWES]: { name: 'pitneybowes', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.PROPERTY_CAPSULE]: { name: 'propertycapsule', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.SAFE_GRAPH]: { name: 'safegraph', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.SEZNAM]: { name: 'seznam', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.SHOW_ME_LOCAL]: { name: 'showmelocal', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.SNAPCHAT]: { name: 'snapchat', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.SOLEO]: { name: 'soleo', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.STADTBRANCHENBUCH]: { name: 'stadtbranchenbuch', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.STADTBRANCHENBUCH_CH]: { name: 'stadtbranchenbuchch', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.T_ONLINE]: { name: 'tonline', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.TELEPHONE_CITY]: { name: 'telephonecity', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.TELLOWS]: { name: 'tellows', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.TIENDEO]: { name: 'tiendeo', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.TOMTOM]: { name: 'tomtom', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.TOP_RATED_LOCAL]: { name: 'topratedlocal', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.TRIPADVISOR]: { name: 'tripadvisor', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.TRIVAGO]: { name: 'trivago', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.TRUSTPILOT]: { name: 'trustpilot', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.TUPALO]: { name: 'tupalo', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.UBER]: { name: 'uber', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.UBEREATS]: { name: 'ubereats', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.US_CITY_NET]: { name: 'uscitynet', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.VISA_LIETUVA_LT]: { name: 'visalietuvalt', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.WEMAP]: { name: 'wemap', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.WESHOPLOCAL]: { name: 'weshoplocal', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.WHERE_TO]: { name: 'whereto', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.WOGIBTSWAS_AT]: { name: 'wogibtswasat', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.WOGIBTSWAS_DE]: { name: 'wogibtswasde', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.YAHOO]: { name: 'yahoo', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.YALWA]: { name: 'yalwa', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.YANDEX]: { name: 'yandex', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.YELL]: { name: 'yell', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.YELLOW_MOXIE]: { name: 'yellowmoxie', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.YELLOW_PAGE_CITY]: { name: 'yellowpagecitycom', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.YELLOW_PAGES_DIRECTORY]: {
        name: 'yellowpagesgoesgreen',
        supported: true,
        providers: [InformationUpdateProvider.YEXT],
    },
    [YextPublisherId.YELLOWMAP]: { name: 'yellowmap', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.YELP]: { name: 'yelp', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.YP_COM]: { name: 'ypcom', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.ZLATE_STRANKY_CZ]: { name: 'zlatestrankycz', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [YextPublisherId.ZLATE_STRANKY_SK]: { name: 'zlatestrankysk', supported: true, providers: [InformationUpdateProvider.YEXT] },
    [PlatformKey.ABC]: { name: 'abc', supported: false, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.DELIVEROO]: { name: 'deliveroo', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.FACEBOOK]: { name: 'facebook', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.FOURSQUARE]: { name: 'foursquare', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.GMB]: { name: 'gmb', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.INSTAGRAM]: { name: 'instagram', supported: false, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.LAFOURCHETTE]: { name: 'lafourchette', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.MAPSTR]: { name: 'mapstr', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.OPENTABLE]: { name: 'opentable', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.PAGESJAUNES]: { name: 'pagesjaunes', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.PRIVATE]: { name: 'malouPrivate', supported: false, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.RESY]: { name: 'resy', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.TRIPADVISOR]: { name: 'tripadvisor', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.UBEREATS]: { name: 'ubereats', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.WEBSITE]: { name: 'website', supported: false, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.YELP]: { name: 'yelp', supported: true, providers: [InformationUpdateProvider.MALOU] },
    [PlatformKey.ZENCHEF]: { name: 'zenchef', supported: false, providers: [InformationUpdateProvider.MALOU] },
} as const satisfies Record<
    AggregatedInformationUpdateProviderKey,
    { name: string; supported: boolean; providers: InformationUpdateProvider[] }
>;

/**
 * This is a list of the supported keys for the information updates.
 *
 * example:
 * ['pagesjaunes', 'tripadvisor', '118000fr', '192com', ...]
 */
export const informationUpdateSupportedKey = Object.freeze(
    uniq(
        Object.values(configInformationUpdateSupportedKeys)
            .filter((key) => key.supported)
            .map((key) => key.name)
    )
);

/**
 * This is a mapping of the supported keys for the information updates.
 *
 * example:
 * {
 *    'pagesjaunes': 'pagesjaunes',
 *   'tripadvisor': 'tripadvisor',
 *   '118000fr': '118000fr',
 *   '192com': '192com',
 *   ...
 * }
 */
export const informationUpdateSupportedKeyMap = informationUpdateSupportedKey.reduce(
    (acc, key) => ({ ...acc, [key]: key }),
    {} as Record<InformationUpdateSupportedPlatformKey, InformationUpdateSupportedPlatformKey>
);

export type InformationUpdateSupportedPlatformKey = (typeof informationUpdateSupportedKey)[number];

export const yextPublisherAsArray = Object.values(YextPublisherId);

/**
 * This is a mapping of the supported keys to original provider key grouped by provider.
 *
 * example:
 * {
 *   'MALOU': {
 *    'pagesjaunes': 'pagesjaunes',
 *    'tripadvisor': 'tripadvisor',
 *   },
 *   'YEXT': {
 *    '118000fr': '118_000_FR',
 *    '192com': '192_COM',
 *    'tripadvisor': 'TRIPADVISORREVIEWS',
 *   }
 * }
 */
export const platformKeyToProvider = Object.freeze(
    Object.entries(configInformationUpdateSupportedKeys).reduce((acc, [key, value]) => {
        const { name, providers } = value;
        for (const provider of providers) {
            acc[provider] = { ...acc[provider], [name]: key };
        }
        return acc;
    }, {} as Record<InformationUpdateProvider, Record<InformationUpdateSupportedPlatformKey, AggregatedInformationUpdateProviderKey>>)
);

export function isYext(providerAndKey: {
    provider: InformationUpdateProvider;
    key: AggregatedInformationUpdateProviderKey;
}): providerAndKey is { provider: typeof InformationUpdateProvider.YEXT; key: YextPublisherId } {
    return providerAndKey.provider === InformationUpdateProvider.YEXT;
}

export function isMalou(providerAndKey: {
    provider: InformationUpdateProvider;
    key: AggregatedInformationUpdateProviderKey;
}): providerAndKey is { provider: typeof InformationUpdateProvider.MALOU; key: PlatformKey } {
    return providerAndKey.provider === InformationUpdateProvider.MALOU;
}
