<ng-container *ngTemplateOutlet="hasFetchedMedia() ? page : loadingPageTemplate"></ng-container>

<ng-template #page>
    @if (restaurant(); as restaurant) {
        <div class="flex h-full flex-col py-4">
            <div class="mx-[30px] md:mx-5">
                <app-gallery-actions-header
                    [hasSelectedMedias]="(hasSelectedMedias$ | async) ?? false"
                    [hasSelectedFolders]="(hasSelectedFolders$ | async) ?? false"
                    [currentFolder]="currentFolder()"
                    [restaurantId]="restaurant._id"
                    (uploadFile)="openFilePicker()"
                    (downloadSelected)="downloadMediasAndFoldersAsZip()"
                    (moveSelected)="onMoveSelectedMedia()"
                    (duplicateSelected)="duplicateMultipleMediasAndFolders($event)"
                    (deleteSelected)="deleteSelectedMediaAndFolders()"
                    (createFolder)="onCreateFolder($event)"></app-gallery-actions-header>

                <!-- https://experimentation.malou.io:3001/features/import-media-v2 -->
                @if (experimentationService.isFeatureEnabled('import-media-v2')) {
                    <div class="malou-text-12 -mt-4 flex flex-row-reverse items-end gap-2">
                        <app-slide-toggle [checked]="isImportV2Enabled()" (onToggle)="toggleImportV2($event)"></app-slide-toggle>
                        <span
                            >Import V2
                            <a class="malou-color-primary" href="https://experimentation.malou.io:3001/features/import-media-v2"
                                >[GrowthBook]</a
                            ></span
                        >
                    </div>
                }

                @if (filesErrors() && (filesErrors()?.length ?? 0) > 0) {
                    <ng-container *ngTemplateOutlet="errorBox"></ng-container>
                }
            </div>
            <dts-select-container
                class="container-padding gallery-grid-template-columns !grid min-h-0 grow gap-5 overflow-y-auto !pt-0 md:!grid-cols-2 md:gap-3 md:p-5"
                id="gallery-list-infinite-scroll-container"
                infinite-scroll
                infiniteScrollContainer="#gallery-list-infinite-scroll-container"
                [infiniteScrollDistance]="2"
                [dragOverItems]="true"
                [fromRoot]="true"
                [selectOnClick]="false"
                (selectionEnded)="onDragAndDropEnd($event)"
                (scrolled)="onScrollDown()"
                (pointerdown)="onBackgroundContainerPointerDown()">
                <!-- [dtsSelectItem] elements must be the direct children of the dts-select-container in order to make it work properly -->
                @if (currentFolder()) {
                    <div class="col-span-full mb-2 flex items-center">
                        <span class="malou-text-14 cursor-pointer italic text-malou-color-text-2" (click)="onOpenFolder()">
                            {{ 'gallery.folders.gallery' | translate }}
                        </span>
                        <mat-icon class="mx-1 !h-4" color="primary" [svgIcon]="SvgIcon.CHEVRON_RIGHT"></mat-icon>
                        <span class="malou-text-14--semibold text-malou-color-text-1">{{ currentFolder()?.name }}</span>
                    </div>
                }
                @if (folders().length > 0) {
                    <div class="col-span-full">
                        <mat-checkbox
                            class="malou-text-18--semibold text-malou-color-text-1"
                            color="primary"
                            [checked]="folders().length === (folderSelectionModel.getCount$() | async)"
                            (change)="onToggleSelectAllFolders($event.checked)"
                            (pointerdown)="$event.stopPropagation()"
                            (click)="$event.stopPropagation()">
                            {{ 'gallery.folders.folders' | translate }}
                        </mat-checkbox>
                    </div>
                }
                @for (folder of folders(); track trackByIdFn($index, folder)) {
                    <app-gallery-folder
                        [folder]="folder"
                        [isSelected]="
                            isFolderSelected | applyPure: folder : (folderSelectionModel | applySelfPure: 'getSelection$' | async)
                        "
                        [dtsSelectItem]="folder"
                        (toggleFolderSelection)="onToggleFolderSelection($event)"
                        (toggleSelectAllFolders)="onToggleSelectAllFolders($any($event))"
                        (deleteFolder)="onDeleteFolder($event)"
                        (folderRenamed)="onFolderRenamed($event)"
                        (downloadFolder)="onDownloadFolder($event)"
                        (duplicateFolder)="onDuplicateFolder($event)"
                        (openFolder)="onOpenFolder($event)">
                    </app-gallery-folder>
                }
                @if (medias().length > 0) {
                    <div class="col-span-full" [ngClass]="{ 'mt-4': folders().length > 0 }">
                        <mat-checkbox
                            class="malou-text-18--semibold text-malou-color-text-1"
                            color="primary"
                            [checked]="medias().length === (mediaSelectionModel.getCount$() | async)"
                            (change)="onToggleSelectAllMedia($event.checked)"
                            (click)="$event.stopPropagation()"
                            (pointerdown)="$event.stopPropagation()">
                            {{ currentFolder() ? ('gallery.media.media' | translate) : ('gallery.media.media_without_folder' | translate) }}
                        </mat-checkbox>
                    </div>
                } @else {
                    <div class="no-media-height col-span-full grid min-h-[50vh] place-items-center">
                        <div class="flex flex-col items-center gap-y-3">
                            <img
                                class="w-[127px]"
                                alt="empty"
                                [lazyLoad]="
                                    (isThereATitleFilter() ? illustration.Cook : illustration.Goggles) | illustrationPathResolver
                                " />
                            <div class="malou-text-14--bold">
                                {{ isThereATitleFilter() ? ('gallery.no_media_search' | translate) : ('gallery.no_media' | translate) }}
                            </div>
                            <div class="malou-text-10--regular">
                                {{
                                    isThereATitleFilter()
                                        ? ('gallery.no_media_search_caption' | translate)
                                        : ('gallery.no_media_caption' | translate)
                                }}
                            </div>
                            @if (!isThereATitleFilter()) {
                                <button class="malou-btn-raised--primary" mat-raised-button (click)="openFilePicker()">
                                    {{ 'gallery.upload_media' | translate }}
                                </button>
                            }
                        </div>
                    </div>
                }
                @for (media of medias(); track trackByIdFn($index, media)) {
                    <app-gallery-media
                        [media]="media"
                        [medias]="medias"
                        [restaurant]="restaurant"
                        [isSelected]="isMediaSelected | applyPure: media : (mediaSelectionModel | applySelfPure: 'getSelection$' | async)"
                        [dtsSelectItem]="media"
                        (mediaSelected)="onMediaSelected($event)"
                        (mediaContainerClick)="onMediaContainerClick($event)"
                        (deleteSingleMedia)="onDeleteMedia($event)"
                        (duplicateSingleMedia)="onDuplicateMedia($event)"
                        (editedMedia)="onEditedMedia($event)"
                        (moveMedia)="onMoveMedia([$event])"
                        (mediaRenamed)="onMediaRenamed($event)"></app-gallery-media>
                }
                <div class="col-span-full row-start-auto row-end-13"></div>
                <app-scroll-to-top class="fixed bottom-8 right-7.5" container="#gallery-list-infinite-scroll-container">
                </app-scroll-to-top>
            </dts-select-container>
        </div>
    }
</ng-template>

<ng-template #loadingPageTemplate>
    <div class="h-full w-full overflow-y-auto px-[30px] py-4 pb-[30px]">
        <div class="w-full" id="filters-skeleton">
            <app-skeleton skeletonClass="w-full h-[50px] secondary-bg"></app-skeleton>
        </div>

        <div class="mt-5 grid h-full grid-cols-4 gap-5 md:grid-cols-2" id="media-list-skeleton">
            @for (item of [0, 1, 2, 3, 4, 5, 6, 7]; track item) {
                <app-skeleton skeletonClass="h-[430px] md:h-[330px] w-full secondary-bg p-6" [animate]="false">
                    <ng-template #nestedSkeletonTemplate>
                        <div class="flex items-center justify-between">
                            <div class="title-subtitle grow">
                                <app-skeleton skeletonClass="w-full h-[18px] mb-2"></app-skeleton>
                                <app-skeleton skeletonClass="w-full h-[18px]"></app-skeleton>
                            </div>
                            <div class="ml-2">
                                <app-skeleton skeletonClass="h-[50px] w-[50px]"></app-skeleton>
                            </div>
                        </div>
                        <app-skeleton skeletonClass="w-full h-[250px] md:h-[150px] mt-5"></app-skeleton>
                        <app-skeleton skeletonClass="w-[50%] md:w-full h-[35px] mt-5"></app-skeleton>
                    </ng-template>
                </app-skeleton>
            }
        </div>
    </div>
</ng-template>

<ng-template #errorBox>
    <div class="malou-bg-error malou-color-state-error relative mb-4 h-fit w-full rounded-[10px] px-4 py-3">
        <div class="absolute right-4 top-3">
            <mat-icon class="!h-[16px] !w-[16px] cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="setEmptyFilesErrors()"></mat-icon>
        </div>
        @for (fileError of filesErrors(); track fileError) {
            <div class="malou-color-state-error malou-text-10--regular">
                {{ fileError }}
                <br />
            </div>
        }
    </div>
</ng-template>
